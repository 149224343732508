"use client";
import { FocusEventHandler, useEffect } from "react";
import {
  useController,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { StyleProp, ViewStyle } from "react-native";
import { NumberFormatValues, NumericFormat } from "react-number-format";

import { getDisplayCurrencyByLocale, getLocale, Locale } from "../../../core";
import { resolveThousandSeparatorDelimiter } from "../../../utils";
import InputField, { InputFieldProps } from "../InputField/InputField";
import { FieldProps } from "../types";

type Props = FieldProps<string> & {
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  inputStyle?: InputFieldProps["inputStyle"];
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: string;
  error?: string;
  locale?: Locale;
  testID?: string;
  placeholder?: string;
  leftHelper?: string;
  prefix?: string;
  suffix?: string;
  disabled?: boolean;
  rules?: UseControllerProps["rules"];
  resetError?: boolean;
};

export default function ThousandSeparatorInputRHF(props: Props) {
  const {
    name,
    locale = getLocale() ?? Locale.ENID,
    prefix: propsPrefix,
    suffix: propsSuffix,
    rules,
    resetError,
    ...rest
  } = props;

  const { control, clearErrors } = useFormContext();
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({ control, name, rules });

  const separator = resolveThousandSeparatorDelimiter(locale);
  const { prefix, suffix } = checkPrefixSuffix(
    locale,
    propsPrefix,
    propsSuffix
  );

  function onValueChange(val: NumberFormatValues) {
    const { value } = val;
    if (value.length === 1) {
      onChange?.(val.value);
      return;
    }

    const zeroRegex = /^0+/;
    const usedValue = value.replace(zeroRegex, "");
    onChange?.(usedValue);
  }

  useEffect(() => {
    if (resetError) clearErrors(name);
  }, [clearErrors, name, resetError]);

  return (
    <NumericFormat
      getInputRef={ref}
      customInput={InputField}
      value={value}
      defaultValue={value}
      onValueChange={onValueChange}
      onBlur={onBlur}
      error={error?.message}
      thousandSeparator={separator.thousand}
      decimalSeparator={separator.decimal}
      prefix={prefix}
      suffix={suffix}
      {...rest}
      inputStyle={rest.inputStyle}
    />
  );
}

function addPrefixSuffix(value: string, prefix?: string, suffix?: string) {
  let formattedValue = value;

  if (prefix) {
    formattedValue = prefix + formattedValue;
  }

  if (suffix) {
    formattedValue = formattedValue + suffix;
  }

  return formattedValue;
}

function checkPrefixSuffix(
  locale: Locale,
  propsPrefix?: string,
  propsSuffix?: string
) {
  const displayCurrency = getDisplayCurrencyByLocale(locale);

  let obj: Record<string, string>;
  if (displayCurrency.position === "left") {
    obj = {
      prefix: `${displayCurrency.symbol} `,
    };
  } else {
    obj = {
      suffix: ` ${displayCurrency.symbol}`,
    };
  }

  if (propsPrefix !== undefined) {
    obj.prefix = propsPrefix;
  }

  if (propsSuffix !== undefined) {
    obj.suffix = propsSuffix;
  }

  return obj;
}
