import countryInfo from "../../../constants/country-info";
import { InputDropdownProps } from "../InputDropdown";
import InputDropdownRHF from "../InputDropdown/InputDropdownRHF";
import { InputDropdownItemValue } from "../InputDropdown/types";
import { FieldProps } from "../types";

type Props = FieldProps<string> &
  Omit<InputDropdownProps<InputDropdownItemValue>, "items">;

export default function CountryField(props: Props) {
  return (
    <InputDropdownRHF
      {...props}
      items={countryList}
      editable
      searchable
      dropdownOffset={64}
    />
  );
}

const countryList = countryInfo.map((info) => ({
  label: info.countryName,
  value: info.countryId,
}));
