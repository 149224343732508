export default function intersperse<T>(
  array: T[],
  callback: (index: number) => T
): T[] {
  const lastIndex = array.length - 1;

  return array.reduce((arr, value, index) => {
    arr.push(value);

    if (index !== lastIndex) {
      arr.push(callback(index));
    }

    return arr;
  }, [] as T[]);
}
