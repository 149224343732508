import { ApiError as ApiErrorType } from "./types";

type ErrorType = ApiErrorType["type"];

export default class ApiError extends Error implements ApiErrorType {
  public type: ErrorType;

  constructor(type: ErrorType, message: string) {
    super(message);

    this.type = type;
  }
}
