"use client";
import { useMemo, useRef, useState } from "react";
import {
  Animated,
  Easing,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";

import PlusIcon from "@traveloka/icon-kit-web/react/IcSymbolAddPlus12";
import PlusIconBig from "@traveloka/icon-kit-web/react/IcSymbolAddPlus16";
import ChevronLeft from "@traveloka/icon-kit-web/react/IcSystemChevronLeft16";
import ChevronRight from "@traveloka/icon-kit-web/react/IcSystemChevronRight16";

import useLayout from "../../hooks/useLayout";
import Image from "../Image/Image";
import Text from "../Text/Text";
import Token from "../Token/Token";

import cssStyles from "./PhotoNavigation.module.css";

type Props = {
  activeIndex: number;
  thumbnailSize?: number;
  items: Array<{
    fileName?: string;
    imageUrl: string;
    /** Useful to input smaller sized image as thumbnail */
    thumbnailUrl?: string;
    likedCount?: number;
    isLiked?: boolean;
  }>;
  isPaginated?: boolean;
  showAddItem?: boolean;
  onAddItem?: () => void;
  showRemoveButton?: boolean;
  onRemoveItem?: (index: number) => void;
  onItemClick?: (index: number) => void;
};

export default function PhotoNavigation(props: Props) {
  const {
    thumbnailSize = 40,
    items,
    activeIndex,
    onItemClick,
    isPaginated,
    showRemoveButton,
    onRemoveItem,
    showAddItem,
    onAddItem,
  } = props;
  const [layout, events] = useLayout();
  const [page, setPage] = useState(0);
  const totalLength = items.length * (thumbnailSize + 6) + 22;
  const maxShown = layout.width - 140;
  const totalPage = Math.ceil(totalLength / maxShown);
  const offset = useRef(new Animated.Value(0)).current;

  const interpolationRange = useMemo(() => {
    if (totalPage <= 1) {
      return {
        inputRange: [0, 0],
        outputRange: [0, 0],
      };
    }

    return {
      inputRange: Array(totalPage)
        .fill(0)
        .map((v, i) => i),
      outputRange: Array(totalPage)
        .fill(0)
        .map((v, i) => -1 * i * maxShown),
    };
  }, [totalLength, maxShown]);

  function handleNavButton(nextOffset: -1 | 1) {
    if (
      (nextOffset === 1 && page < totalPage - 1) ||
      (nextOffset === -1 && page > 0)
    ) {
      Animated.timing(offset, {
        easing: Easing.out(Easing.ease),
        toValue: page + nextOffset,
        duration: Token.timing.normal,
        useNativeDriver: false,
      }).start();
      setPage(page + nextOffset);
    }
  }

  function renderPhotos(index: number, imageUrl: string, imageId?: string) {
    return (
      <TouchableOpacity
        key={`${index}-${imageUrl}`}
        activeOpacity={0.8}
        onPress={() => onItemClick?.(index)}
        style={[
          styles.imgThumbnail,
          {
            borderColor:
              activeIndex === index
                ? Token.color.bluePrimary
                : Token.color.lightStain,
            height: thumbnailSize * 1.8,
          },
        ]}
        testID={`expand-${imageId}`}
      >
        {showRemoveButton && (
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={() => onRemoveItem?.(index)}
            style={[
              styles.badgeButton,
              styles.closeButton,
              styles.removeButton,
            ]}
            testID={`remove-${imageId}`}
          >
            <PlusIcon color={Token.color.lightPrimary} />
          </TouchableOpacity>
        )}
        <Image
          className={cssStyles.image}
          src={imageUrl}
          height={thumbnailSize * 1.8}
          width={activeIndex === index ? thumbnailSize * 1.6 : thumbnailSize}
          alt={`image-${imageId}`}
        />
      </TouchableOpacity>
    );
  }

  return (
    <View>
      <View style={styles.footerWrapper}>
        <Text variant="caption-tiny" ink="black-secondary" align="center">
          {activeIndex + 1} / {items.length}
        </Text>
        {isPaginated ? (
          <View style={styles.listWrapper} {...events}>
            <Animated.View
              style={[
                styles.listContainer,
                {
                  transform: [
                    {
                      translateX: offset.interpolate({
                        inputRange: interpolationRange.inputRange,
                        outputRange: interpolationRange.outputRange,
                      }),
                    },
                  ],
                },
              ]}
            >
              {items.map((e: any, i: number) =>
                renderPhotos(i, e.thumbnailUrl || e.imageUrl, e.fileName)
              )}
              {showAddItem && (
                <TouchableOpacity
                  style={[
                    styles.imgThumbnail,
                    styles.addThumbnail,
                    { width: thumbnailSize },
                  ]}
                  onPress={onAddItem}
                  testID="addPhotoButton"
                >
                  <PlusIconBig color={Token.color.darkPrimary} />
                </TouchableOpacity>
              )}
            </Animated.View>
            {totalPage > 1 && page !== 0 && (
              <TouchableOpacity
                onPress={() => handleNavButton(-1)}
                activeOpacity={1}
                style={[styles.photoNav, styles.chevronLeft]}
              >
                <ChevronLeft color={Token.color.bluePrimary} width={16} />
              </TouchableOpacity>
            )}
            {totalPage > 1 && page !== totalPage - 1 && (
              <TouchableOpacity
                onPress={() => handleNavButton(1)}
                activeOpacity={1}
                style={[styles.photoNav, styles.chevronRight]}
              >
                <ChevronRight color={Token.color.bluePrimary} width={16} />
              </TouchableOpacity>
            )}
          </View>
        ) : (
          <View style={styles.listWrapper}>
            <ScrollView
              style={[
                styles.listContainer,
                { paddingLeft: isPaginated ? 40 : Token.spacing.xxs },
              ]}
              horizontal={true}
              showsHorizontalScrollIndicator={false}
            >
              {items.map((e: any, i: number) =>
                renderPhotos(i, e.thumbnailUrl || e.imageUrl, e.fileName)
              )}
            </ScrollView>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  footerWrapper: {
    paddingTop: 2,
    backgroundColor: Token.color.lightPrimary,
  },
  listContainer: {
    flexDirection: "row",
    padding: Token.spacing.xxs,
  },
  listWrapper: {
    borderColor: Token.color.lightSecondary,
    borderStyle: "solid",
    borderTopWidth: 1,
    backgroundColor: Token.color.lightStain,
    overflow: "hidden",
  },
  imgThumbnail: {
    marginRight: 2,
    backgroundColor: Token.color.lightSecondary,
    borderRadius: Token.borderRadius.normal,
    borderStyle: "solid",
    borderWidth: 2,
    overflow: "hidden",
    height: 86,
  },
  addThumbnail: {
    backgroundColor: Token.color.lightStain,
    borderColor: Token.color.lightSecondary,
    borderWidth: 1,
    margin: 1,
    justifyContent: "center",
  },
  photoNav: {
    width: 32,
    position: "absolute",
    top: 0,
    height: "100%",
    backgroundColor: Token.color.lightNeutral,
    borderColor: Token.color.lightSecondary,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
  },
  badgeButton: {
    width: 20,
    height: 20,
    backgroundColor: "rgba(3, 18, 26, .5)",
    borderRadius: Token.borderRadius.rounded,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    transform: [{ rotate: "45Deg" }],
  },
  removeButton: {
    top: 4,
    right: 4,
    position: "absolute",
  },
  chevronLeft: {
    borderRightWidth: 1,
    left: 0,
  },
  chevronRight: {
    borderLeftWidth: 1,
    right: 0,
  },
});
