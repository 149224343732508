"use client";
import { useEffect, useRef, useState } from "react";
import { Animated, Easing, EasingFunction } from "react-native";

import { useHoverable } from "./useHoverable";

type TransitionConfig = {
  onFinishTransitioning?: () => void;
  easing: EasingFunction;
};

export default function useTransition<Behavior, BehaviorValueMap>(
  defaultBehavior: Behavior,
  duration: number,
  {
    onFinishTransitioning,
    easing = Easing.inOut(Easing.linear),
  }: TransitionConfig = {} as TransitionConfig
) {
  const [currentBehavior, setCurrentBehavior] =
    useState<Behavior>(defaultBehavior);
  const animatedValue = useRef(new Animated.Value(0));
  const prevValue = useRef(0);
  const prevBehavior = useRef<Behavior>(defaultBehavior);

  useEffect(() => {
    const animation = Animated.timing(animatedValue.current, {
      toValue: prevValue.current === 0 ? 1 : 0,
      duration,
      easing,
      useNativeDriver: false,
    });

    const rafId = requestAnimationFrame(() =>
      animation.start(() => {
        typeof onFinishTransitioning === "function" && onFinishTransitioning();
      })
    );

    prevBehavior.current = currentBehavior;
    prevValue.current = prevValue.current === 0 ? 1 : 0;

    return () => cancelAnimationFrame(rafId);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- animation should only run when currentBehavior changes
  }, [currentBehavior]);

  const interpolate = (behaviorValueMap: BehaviorValueMap) =>
    getInterpolation(
      animatedValue.current,
      currentBehavior,
      prevBehavior.current,
      prevValue.current === 0,
      behaviorValueMap
    );

  return { currentBehavior, setCurrentBehavior, interpolate };
}

function getInterpolation(
  animatedValue: Animated.Value,
  currentBehavior: any,
  prevBehavior: any,
  isDirectionFlipped: boolean,
  behaviorValueMap: any
) {
  const fromPointer = isDirectionFlipped ? prevBehavior : currentBehavior;
  const toPointer = isDirectionFlipped ? currentBehavior : prevBehavior;

  return animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [behaviorValueMap[fromPointer], behaviorValueMap[toPointer]],
  });
}

export type HoverFocusBehavior = "normal" | "hovered" | "focused";

export type HoverFocusBehaviorValueMap = { [key in HoverFocusBehavior]: any };

type HoverFocusEventHandlers = {
  onMouseEnter?: any;
  onMouseOut?: any;
  onFocus?: any;
  onBlur?: any;
};

export function useHoverFocusTransition(
  duration: number,
  customHandlers: HoverFocusEventHandlers = {}
) {
  const { currentBehavior, setCurrentBehavior, interpolate } = useTransition<
    HoverFocusBehavior,
    HoverFocusBehaviorValueMap
  >("normal", duration);

  const { onMouseEnter, onMouseOut, onFocus, onBlur } = customHandlers;

  const [_isHovered, hoverEvents] = useHoverable({
    onMouseEnter: () =>
      onMouseEnterHandler(currentBehavior, setCurrentBehavior, onMouseEnter),
    onMouseOut: () =>
      onMouseOutHandler(currentBehavior, setCurrentBehavior, onMouseOut),
  });

  const hoverFocusEventHandlers = {
    hoverEvents,
    onFocus: (e: any) => onFocusHandler(e, setCurrentBehavior, onFocus),
    onBlur: (e: any) => onBlurHandler(e, setCurrentBehavior, onBlur),
  };

  return {
    hoverFocusEventHandlers,
    interpolate,
    isHovered: currentBehavior === "hovered",
    isFocused: currentBehavior === "focused",
  };
}

function onMouseEnterHandler(
  currentBehavior: HoverFocusBehavior,
  setCurrentBehavior: any,
  onMouseEnter?: any
) {
  if (onMouseEnter) {
    onMouseEnter();
  }

  if (currentBehavior === "normal") {
    setCurrentBehavior("hovered");
  }
}

function onMouseOutHandler(
  currentBehavior: HoverFocusBehavior,
  setCurrentBehavior: any,
  onMouseOut?: any
) {
  if (onMouseOut) {
    onMouseOut();
  }

  if (currentBehavior === "hovered") {
    setCurrentBehavior("normal");
  }
}

function onFocusHandler(e: any, setCurrentBehavior: any, onFocus?: any) {
  if (onFocus) {
    onFocus(e);
  }

  setCurrentBehavior("focused");
}

function onBlurHandler(e: any, setCurrentBehavior: any, onBlur?: any) {
  if (onBlur) {
    onBlur(e);
  }

  setCurrentBehavior("normal");
}
