import { StyleSheet } from "react-native";

import Token from "../Token/Token";
import View from "../View/View";

type Props = {
  current: number;
  total: number;
  width?: number;
};
export default function PageBar(props: Props) {
  const { current, total, width } = props;

  return (
    <View style={{ width }} row spacing="xs">
      {Array.from({ length: total }, (_, i) => (
        <View
          key={i}
          style={[styles.bar, i <= current && styles.activeBar]}
        ></View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  bar: {
    flex: 1,
    height: 6,
    borderRadius: Token.borderRadius.rounded,
    backgroundColor: Token.color.lightSecondary,
    transition: `${Token.timing.instant}ms background-color ease-out`,
  },
  activeBar: {
    backgroundColor: Token.color.bluePrimary,
  },
});
