"use client";
import {
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { Animated, Easing, StyleSheet } from "react-native";

import convertCssUnit from "../../utils/convertCssUnit";
import Portal from "../Portal";
import Token from "../Token";
import View from "../View";
import { useOnClickOutside } from "../hooks";

export type DrawerProps = PropsWithChildren<{
  trigger: boolean;
  onClose?: () => void;
  width?: number | string;
  offsetHeight?: number;
}>;

export type DrawerFeatureRef = { handleClose: () => void };

function Drawer(props: DrawerProps, featRef: ForwardedRef<DrawerFeatureRef>) {
  const {
    trigger,
    onClose,
    width = "40vw",
    offsetHeight = 0,
    children,
  } = props;

  const [isDestroyed, setIsDestroyed] = useState(!trigger);

  const ref = useRef(null);
  const rightRef = useRef(new Animated.Value(-convertCssUnit(width) - 100));
  const hasShown = useRef(false);

  const { ref: inViewRef, inView } = useInView();

  function handleClose() {
    Animated.timing(rightRef.current, {
      toValue: -convertCssUnit(width) - 100,
      duration: Token.timing.normal,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  }

  useOnClickOutside(ref, handleClose);
  useImperativeHandle(featRef, () => ({ handleClose }));

  useEffect(() => {
    if (trigger) {
      setIsDestroyed(false);

      Animated.timing(rightRef.current, {
        toValue: 0,
        duration: Token.timing.normal,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start();
    }
  }, [trigger]);

  useEffect(() => {
    if (!hasShown.current) {
      hasShown.current = inView;
    }

    if (!inView && hasShown.current) {
      onClose?.();
      setIsDestroyed(true);
      hasShown.current = false;
    }
  }, [inView]);

  if (isDestroyed) {
    return null;
  }

  const containerStyle: Record<string, any> = {
    width,
    top: offsetHeight,
    height: window.innerHeight - offsetHeight,
    right: rightRef.current,
  };

  return (
    <Portal>
      <View style={styles.overlay} />
      <Animated.View ref={ref} style={[styles.container, containerStyle]}>
        <div ref={inViewRef} style={inViewStyle} />
        {children}
      </Animated.View>
    </Portal>
  );
}

export default forwardRef<DrawerFeatureRef, DrawerProps>(Drawer);

const styles = StyleSheet.create({
  overlay: {
    // @ts-expect-error
    position: "fixed",
    top: 0,
    left: 0,
    // @ts-expect-error
    width: "100vw",
    // @ts-expect-error
    height: "100vh",
  },
  container: {
    // @ts-ignore
    position: "fixed",
    transition: `${Token.timing.slow}ms all`,
  },
});

const inViewStyle = {
  width: "100%",
  height: "100%",
  position: "absolute" as any,
};
