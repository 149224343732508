"use client";
import { ReactNode, useEffect, useRef, useState } from "react";
import Token from "../Token";

type TransitionState = "enter" | "exit";

type Duration = number | { enter: number; exit: number };

export type TransitionProps = {
  /**
   * The duration of the transition, in milliseconds.
   * You may specify a single timeout for all transitions
   *
   * @default 200
   */
  duration?: Duration;
  /**
   * Callback fired after `enter` status is applied
   */
  onEnter?(): void;
  /**
   * Callback fired after `enter` transition is finished
   */
  onEntered?(): void;
  /**
   * Callback fired after `exit` status is applied
   */
  onExit?(): void;
  /**
   * Callback fired after `exit` transition is finished
   */
  onExited?(): void;
  /**
   * A `function` child can be used instead of a React element. This function is
   * called with the current transition state (`'entering'`, `'entered'`,
   * `'exiting'`, `'exited'`), which can be used to apply context
   * specific props to a component.
   *
   * ```jsx
   * <Transition
   *   in={this.state.in}
   *   timeout={150}
   *   render={state => (
   *     <MyComponent style={styles[state]} />
   *   )}
   * />
   * ```
   */
  render(state: TransitionState): ReactNode;
  /**
   * Show the component; triggers the enter or exit states
   */
  visible: boolean;
};

export default function Transition(props: TransitionProps) {
  const {
    render,
    duration = Token.timing.instant,
    onEnter,
    onEntered,
    onExit,
    onExited,
    visible,
  } = props;

  const timerRef = useRef<number>();
  const [status, setStatus] = useState<TransitionState>("exit");

  function performEnter() {
    const enterDuration = getDuration(duration).enter;

    window.setTimeout(() => {
      setStatus("enter");
      if (typeof onEnter === "function") onEnter();
    }, 0);

    timerRef.current = window.setTimeout(() => {
      if (typeof onEntered === "function") onEntered();
    }, enterDuration);
  }

  function performExit() {
    const exitDuration = getDuration(duration).exit;

    window.setTimeout(() => {
      setStatus("exit");
      if (typeof onExit === "function") onExit();
    }, 0);

    timerRef.current = window.setTimeout(() => {
      if (typeof onExited === "function") onExited();
    }, exitDuration);
  }

  // Trigger next state
  useEffect(() => {
    if (visible && status === "exit") {
      performEnter();
    } else if (!visible && status === "enter") {
      performExit();
    }

    return () => {
      window.clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when visible changes
  }, [visible]);

  return <>{render(status)}</>;
}

function getDuration(duration: Duration) {
  if (typeof duration === "number") {
    return { enter: duration, exit: duration };
  }

  return duration;
}
