import { Children, ReactNode } from "react";
import { StyleSheet, ViewStyle } from "react-native";

import Token from "../Token";
import View from "../View";

export type TrayActionProps = {
  children?: ReactNode;
};

export default function TrayAction(props: TrayActionProps) {
  const childLength = Children.count(props.children);

  return (
    <View
      style={[
        styles.root,
        { gridTemplateColumns: `repeat(${childLength}, 1fr)` } as ViewStyle,
      ]}
    >
      {props.children}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: "grid" as any,
    columnGap: Token.spacing.xs,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
    boxShadow: `0 1px 1px 0 inset ${Token.color.lightNeutral}`,
  },
});
