import { ReactNode } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import Modal, { ModalProps } from "../Modal";
import Token from "../Token";
import View from "../View";

type OmitModalProps = Omit<ModalProps, "onClose" | "render" | "duration">;

export type FullScreenDialogProps = OmitModalProps & {
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
};

export default function FullScreenDialog(props: FullScreenDialogProps) {
  const { children, style, ...rest } = props;

  return (
    <Modal
      {...rest}
      isMobile
      duration={Token.timing.instant}
      render={(state) => (
        <View style={[styles.dialog, styles[state], style]}>{children}</View>
      )}
    />
  );
}

const styles = StyleSheet.create({
  dialog: {
    transitionDuration: `${Token.timing.instant}ms`,
    backgroundColor: Token.color.lightPrimary,
    flex: 1,
    overflowY: "auto",
  },
  enter: {
    transform: [{ translateY: 0 }],
    animationTimingFunction: "ease-out",
  },
  exit: {
    transform: [{ translateY: "100%" as any }],
    animationTimingFunction: "ease-in",
  },
});
