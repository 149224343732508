import { Children, ReactNode, forwardRef } from "react";
import {
  View as RNView,
  ViewProps as RNViewProps,
  StyleSheet,
  ViewStyle,
} from "react-native";

import { intersperse } from "../../utils";
import Token, { Spacing } from "../Token";

export type ViewRef = RNView;

export type ViewProps = {
  children?: ReactNode;
  /**
   * Defines `align-items` style property.
   * @default 'stretch'
   */
  align?: "start" | "end" | "center" | "stretch" | "baseline";
  /**
   * Use `gap` style for spacing between children.
   * @default false
   */
  gapSpacing?: boolean;
  /**
   * Defines `justify-content` style property.
   * @default 'start'
   */
  justify?: "start" | "end" | "center" | "around" | "between" | "evenly";
  /**
   * Set`flex-direction` as `row-reverse` or `column-reverse`
   */
  reverse?: boolean;
  /**
   * Set `flex-direction` as `row` or `column`
   */
  row?: boolean;
  /**
   * Defines the space between the children.
   */
  spacing?: Spacing;
  /**
   * Set `flex: 1` to fill the remaining space.
   */
  flex?: number | boolean;
} & RNViewProps;

export default forwardRef<HTMLElement | RNView, ViewProps>(function View(
  props,
  ref
) {
  const {
    align,
    flex,
    gapSpacing = false,
    justify,
    reverse,
    row,
    spacing,
    style,
    ...rest
  } = props;

  let children = props.children;
  if (!gapSpacing && spacing !== undefined) {
    const property = row ? "width" : "height";
    children = intersperse(Children.toArray(props.children), (index) => (
      <div
        aria-hidden
        key={"s." + index}
        style={{ flexShrink: 0, [property]: Token.spacing[spacing] }}
      />
    ));
  }

  let directionStyle: ViewStyle = {};
  if (row && reverse) {
    directionStyle = directionStyles["row-reverse"];
  } else if (row) {
    directionStyle = directionStyles["row"];
  } else if (reverse) {
    directionStyle = directionStyles["column-reverse"];
  }

  const rootStyles = [
    directionStyle,
    typeof align === "string" && alignmentStyles[align],
    typeof justify === "string" && justifyStyles[justify],
    typeof flex === "boolean" && flex
      ? { flex: 1 }
      : typeof flex === "number" && { flex },
    typeof spacing === "string" && gapSpacing && gapStyles[spacing],
    style,
  ];

  return (
    // @ts-expect-error -- ref type for HTMLElement is correct because we are using react-native-web
    <RNView {...rest} ref={ref} style={rootStyles}>
      {children}
    </RNView>
  );
});

const directionStyles = StyleSheet.create({
  row: { flexDirection: "row" },
  "row-reverse": { flexDirection: "row-reverse" },
  "column-reverse": { flexDirection: "column-reverse" },
});

const alignmentStyles = StyleSheet.create({
  start: { alignItems: "flex-start" },
  end: { alignItems: "flex-end" },
  center: { alignItems: "center" },
  baseline: { alignItems: "baseline" },
  stretch: { alignItems: "stretch" },
});

const justifyStyles = StyleSheet.create({
  start: { justifyContent: "flex-start" },
  end: { justifyContent: "flex-end" },
  center: { justifyContent: "center" },
  around: { justifyContent: "space-around" },
  between: { justifyContent: "space-between" },
  evenly: { justifyContent: "space-evenly" },
});

const gapStyles: Record<Spacing, ViewStyle> = StyleSheet.create({
  xxs: { gap: Token.spacing.xxs },
  xs: { gap: Token.spacing.xs },
  s: { gap: Token.spacing.s },
  m: { gap: Token.spacing.m },
  ml: { gap: Token.spacing.ml },
  l: { gap: Token.spacing.l },
  xl: { gap: Token.spacing.xl },
  xxl: { gap: Token.spacing.xxl },
  xxxl: { gap: Token.spacing.xxxl },
  xxxxl: { gap: Token.spacing.xxxxl },
  xxxxxl: { gap: Token.spacing.xxxxxl },
});
