"use client";
import { useEffect, useState } from "react";
import { Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { useId, useScrollLock } from "../hooks";
import Portal from "../Portal";
import Token from "../Token";
import Transition, { TransitionProps } from "../Transition";
import View from "../View";
import ModalManager from "./ModalManager";

export type ModalProps = TransitionProps & {
  /**
   * Called when the close request is trigerred
   */
  onClose?(): void;
  /**
   * Use first version of Modal Component
   */
  isMobile?: boolean;
  /**
   * Custom styling for the modal root
   */
  style?: StyleProp<ViewStyle>;
};

export default function Modal(props: ModalProps) {
  const { onClose, isMobile, style, ...transitionProps } = props;

  const {
    duration = Token.timing.instant,
    onExited,
    onEnter,
    visible,
  } = transitionProps;

  const [mount, setMount] = useState(false);
  const [active, setActive] = useState(false);
  const modalId = useId();
  const hasOnClose = typeof onClose === "function";

  function handleClose() {
    if (hasOnClose) onClose();
  }

  function handleExited() {
    ModalManager.remove(modalId);
    setMount(false);
    if (typeof onExited === "function") onExited();
  }

  function handleEnter() {
    ModalManager.add(modalId, setActive);
    if (typeof onEnter === "function") onEnter();
  }

  useScrollLock(!ModalManager.empty);

  useEffect(() => {
    if (visible) setMount(true);
  }, [visible]);

  useEffect(() => {
    function handleClose(e: KeyboardEvent) {
      if (active && e.key === "Escape") {
        e.stopPropagation();

        if (hasOnClose) onClose();
      }
    }

    document.addEventListener("keyup", handleClose, false);

    return () => document.removeEventListener("keyup", handleClose, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when active, onClose changes
  }, [active, onClose]);

  useEffect(() => {
    return () => ModalManager.remove(modalId);
  }, [modalId]);

  if (!mount) return null;

  if (isMobile) {
    return (
      <Portal>
        <View accessibilityRole="none" style={[styles.root, styles.mobile]}>
          <Transition
            duration={duration}
            visible={visible}
            render={(state) => (
              <Pressable
                onPress={handleClose}
                style={[
                  backdropStyles.root,
                  backdropStyles[state],
                  { transitionDuration: `${duration}ms` } as ViewStyle,
                ]}
              />
            )}
          />
          <Transition
            {...transitionProps}
            onEnter={handleEnter}
            onExited={handleExited}
          />
        </View>
      </Portal>
    );
  }

  return (
    <Portal>
      <View accessibilityRole="none" style={[styles.root, iosModalStyle]}>
        <Transition
          duration={duration}
          visible={visible}
          render={(state) => (
            <>
              <Pressable
                onPress={handleClose}
                style={[
                  backdropStyles.root,
                  backdropStyles[state],
                  { transitionDuration: `${duration}ms` } as ViewStyle,
                  !hasOnClose && backdropStyles.unclickable,
                ]}
              />
              <View
                style={[
                  styles.content,
                  backdropStyles[state],
                  {
                    transitionDuration: `${duration}ms`,
                    transitionProperty: "opacity",
                  } as ViewStyle,
                ]}
              >
                {transitionProps.render(state)}
              </View>
            </>
          )}
          onEnter={handleEnter}
          onExited={handleExited}
        />
      </View>
    </Portal>
  );
}

const styles = StyleSheet.create({
  root: {
    inset: 0,
    position: "fixed" as any,
    zIndex: Token.zIndex.modal,
    // @ts-expect-error - https://29022131.atlassian.net/browse/CTV-5991
    overflow: "auto",
    // @ts-ignore
    maxHeight: "100vh",
  },
  content: {
    margin: "auto",
  },
  iosModal: {
    position: "absolute",
    width: 1240,
  },
  mobile: {
    // @ts-ignore
    maxWidth: "100vw",
  },
});
const iosModalStyle = isIphone() ? styles.iosModal : undefined;

const backdropStyles = StyleSheet.create({
  root: {
    backgroundColor: `rgba(3, 18, 26, .5)`,
    // @ts-ignore
    cursor: undefined,
    inset: 0,
    position: "absolute",
    transitionProperty: "opacity",
    zIndex: -1,
  },
  unclickable: {
    // @ts-ignore
    cursor: "initial",
  },
  enter: {
    opacity: 1,
    // @ts-ignore
    animationTimingFunction: "ease-out",
  },
  exit: {
    opacity: 0,
    // @ts-ignore
    animationTimingFunction: "ease-in",
  },
});

// To resolve this issue: https://29022131.atlassian.net/browse/CTV-5551
function isIphone() {
  try {
    return /iPhone;/.test(navigator.userAgent);
  } catch (e) {
    return false;
  }
}
