import { forwardRef, HTMLAttributes, useMemo } from "react";

import { BaseTextProps } from "./types";

import styles from "./Text.module.css";

export type TextRef = HTMLSpanElement;

export type TextSeoProps = BaseTextProps &
  HTMLAttributes<HTMLSpanElement> & {
    /**
     * Handle onPress event
     */
    onPress?: () => void;
  };

export default forwardRef<TextRef, TextSeoProps>(function Text(props, ref) {
  const {
    ink = "black-primary",
    variant = "ui-baseline",
    onPress,
    className,
    ddMask: ddMaskProps,
    ...rest
  } = props;

  const rootStyles = [styles.root, styles[variant], styles[ink], className];

  const ddData = useMemo(
    () =>
      ddMaskProps
        ? { "data-dd-action-name": ddMaskProps, "data-dd-privacy": "mask" }
        : undefined,
    [ddMaskProps]
  );

  return (
    <span
      {...rest}
      {...ddData}
      ref={ref}
      className={rootStyles.join(" ")}
      onClick={onPress}
    />
  );
});
