import Token from "../Token/Token";
import { Dimension, DimensionSetter, Directions, Position } from "./types";

export function toolTipPlacementCalculator(
  dimension: Dimension,
  position: Position | undefined
): Directions {
  if (position === "top") {
    return {
      bottom: dimension.bottomSpace + dimension.height + Token.spacing.m,
      left: dimension.leftSpace,
    };
  }

  if (position === "right") {
    return {
      left: dimension.width + dimension.leftSpace + Token.spacing.m,
      top: dimension.topSpace,
    };
  }

  if (position === "left") {
    return {
      right: dimension.width + dimension.rightSpace + Token.spacing.m,
      top: dimension.topSpace,
    };
  }

  if (position === "bottom") {
    return {
      top: dimension.height + dimension.topSpace + Token.spacing.m,
      left: dimension.leftSpace,
    };
  }

  return {
    top: dimension.topSpace,
    left: dimension.leftSpace,
  };
}

export function dimensionSetter({
  element,
  setDimension,
  isFit,
}: DimensionSetter) {
  const rect = element.getBoundingClientRect();
  if (!rect) return;
  if (isFit) {
    setDimension({
      height: rect.height,
      width: rect.width,
      leftSpace: rect.left,
      rightSpace: window.innerWidth - rect.right,
      topSpace: rect.top,
      bottomSpace: window.innerHeight - rect.bottom,
    });
    return;
  }
  setDimension({
    height: rect.height + 20,
    width: rect.width + 20,
    leftSpace: rect.left - 10,
    rightSpace: window.innerWidth - rect.right - 10,
    topSpace: rect.top - 10,
    bottomSpace: window.innerHeight - rect.bottom - 10,
  });
}

export function dimensionHighlightSetter({
  element,
  setDimension,
}: DimensionSetter) {
  const rect = element.getBoundingClientRect();
  if (!rect) return;
  setDimension({
    height: rect.height + Token.spacing.xs,
    width: rect.width + Token.spacing.xs,
    leftSpace: rect.left - Token.spacing.xxs,
    rightSpace: window.innerWidth - rect.right - Token.spacing.xxs,
    topSpace: rect.top - Token.spacing.xxs,
    bottomSpace: window.innerHeight - rect.bottom - Token.spacing.xxs,
  });
}

export function debounce(fn: Function, time: number) {
  let timOut: any = null;
  return function (...args: any) {
    if (timOut) {
      clearTimeout(timOut);
    }
    timOut = setTimeout(() => {
      fn.apply(null, args);
      timOut = null;
    }, time);
  };
}
