import { ReactElement, forwardRef } from "react";

import { ViewSeo } from "../../View";
import { InputError, InputPropsSeo } from "../Input";
import InputHelperSeo from "../Input/InputHelperSeo";
import InputLabelSeo from "../Input/InputLabelSeo";
import InputSeo from "../Input/InputSeo";
import cssStyles from "./InputField.module.css";

type TrimmedInputProps = Omit<InputPropsSeo, "error" | "className">;

export type InputFieldSeoProps = {
  endHelper?: string;
  error?: string | boolean;
  helperId?: string;
  helperText?: ReactElement;
  inputClassName?: string;
  label?: string;
  labelId?: string;
  required?: boolean;
  startHelper?: string;
  className?: string;
} & TrimmedInputProps;

export default forwardRef<HTMLInputElement, InputFieldSeoProps>(
  function InputField(props, ref) {
    const {
      endHelper,
      error,
      helperId,
      helperText,
      inputClassName,
      label,
      labelId,
      required,
      startHelper,
      className,
      id,
      size,
      ...inputProps
    } = props;

    const { disabled } = inputProps;

    return (
      <ViewSeo spacing="xxs" className={[cssStyles.root, className].join(" ")}>
        {label && (
          <InputLabelSeo
            disabled={disabled}
            inputId={id}
            required={required}
            text={label}
          />
        )}
        <InputSeo
          {...inputProps}
          ref={ref}
          error={isValidError(error) || Boolean(error)}
          helperId={helperId}
          labelId={labelId}
          className={inputClassName}
        />
        {isValidError(error) ? (
          <InputError nativeID={helperId} text={error} />
        ) : (
          helperText
        )}
        {(typeof endHelper === "string" || typeof startHelper === "string") && (
          <InputHelperSeo
            disabled={disabled}
            endText={endHelper}
            nativeID={helperId}
            startText={startHelper}
          />
        )}
      </ViewSeo>
    );
  }
);

function isValidError(error: string | boolean | undefined): error is string {
  return typeof error === "string" && error !== "";
}
