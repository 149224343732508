import { StyleSheet, View, ViewStyle } from "react-native";

import Text from "../../Text/Text";
import Token from "../../Token/Token";
import RadioDisc from "./RadioDisc";

export type ButtonComponentProps = {
  isSelected?: boolean;
  isFocused?: boolean;
  label: string;
  disabled?: boolean;
  size?: "small" | "medium";
};

type RadioButtonProps = ButtonComponentProps & {
  size?: "medium" | "small";
  containerStyle?: ViewStyle;
};

export default function RadioButton({
  label,
  isSelected = false,
  isFocused = false,
  disabled = false,
  size = "medium",
  containerStyle,
}: RadioButtonProps) {
  const labelInk = getLabelInk();

  function getLabelInk() {
    if (disabled) {
      return "black-muted";
    }
    return "black-primary";
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <RadioDisc
        containerStyle={styles.discContainer}
        disabled={disabled}
        isSelected={isSelected}
        isFocused={isFocused}
        size={size}
      />
      <Text variant={getTextVariant(size)} ink={labelInk}>
        {label}
      </Text>
    </View>
  );
}

function getTextVariant(size: RadioButtonProps["size"]) {
  switch (size) {
    case "medium":
      return "ui-baseline";
    case "small":
      return "ui-small";
    default:
      throw new Error(`Unhandled size variant: ${size}`);
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.m,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  discContainer: {
    marginRight: Token.spacing.m,
  },
});
