"use client";
import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { View as RNView, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { Dimension, Position } from "./types";
import {
  debounce,
  dimensionHighlightSetter,
  dimensionSetter,
  toolTipPlacementCalculator,
} from "./utils";

import View, { ViewRef } from "../View/View";
import CoachmarkOverlay from "./CoachmarkOverlay";
import HighlightElement from "./HighlightElement";

type Props = {
  /**
   * The element that the coachmark will be attached to
   */
  target?: MutableRefObject<Element | null>;
  /**
   * The element that the coachmark highlight will be attached to (using border dashed)
   */
  highlightTarget?: Element | MutableRefObject<Element | null>;
  /**
   * The content of the coachmark
   */
  content: ReactNode;
  /**
   * Coachmark visibility
   */
  visible: boolean;
  /**
   * The position of the coachmark content
   */
  position?: Position;
  /**
   * Tooltip follow highlightTarget
   */
  followHighlight?: boolean;
  /**
   * To fixated the coachmark content
   */
  fixatedContent?: Dimension;
  /**
   * To handle scroll behavior to the highlighted element
   */
  scrollToHighlight?: boolean;
  /**
   * To handle fit highlighted area
   */
  fitHighlightedArea?: boolean;
};

export default function Coachmark(props: Props) {
  const { visible, target, highlightTarget } = props;
  const [element, setElement] = useState<Element | null>(null);
  const [highlightElement, setHighlightElement] = useState<Element | null>(
    null
  );
  const [hasNoTarget, setHasNoTarget] = useState(false);
  const defaultRef = useRef(null);

  useEffect(() => {
    // Forcefully re-render the component to get the latest ref
    // Somehow sometimes the target is not pointing to the correct element
    // Possibly due to the re-rendering being too fast, will need to slow down the re-rendering
    setTimeout(() => {
      setElement(target?.current ?? defaultRef.current);
      setHasNoTarget(!target?.current);

      if (highlightTarget && isElement(highlightTarget)) {
        setHighlightElement(highlightTarget);
      } else {
        setHighlightElement(highlightTarget?.current || null);
      }
    }, 100);
  }, [visible, defaultRef, target, highlightTarget]);

  return (
    <CoachmarkContent
      {...props}
      defaultRef={defaultRef}
      hasNoTarget={hasNoTarget}
      element={element}
      highlightElement={highlightElement}
    />
  );
}

function CoachmarkContent(
  props: Omit<Props, "target" | "highlightTarget"> & {
    element: Element | null;
    highlightElement: Element | null;
    defaultRef: MutableRefObject<ViewRef | null>;
    hasNoTarget: boolean;
  }
) {
  const {
    visible,
    position,
    content: propsContent,
    element,
    highlightElement,
    followHighlight = false,
    fixatedContent,
    defaultRef,
    hasNoTarget,
    scrollToHighlight = true,
    fitHighlightedArea = false,
  } = props;

  const [dimension, setDimension] = useState<Dimension>();
  const [highlightDimension, setHighlightDimension] = useState<Dimension>();
  const [activeStyle, setActiveStyle] = useState<StyleProp<ViewStyle>>({});

  useEffect(() => {
    if (!visible || !element) {
      return;
    }

    if (scrollToHighlight) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    dimensionSetter({
      element,
      setDimension,
      isFit: hasNoTarget || fitHighlightedArea,
    });
    if (highlightElement) {
      if (scrollToHighlight) {
        highlightElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      dimensionHighlightSetter({
        element: highlightElement,
        setDimension: setHighlightDimension,
      });
    }

    function reassignValue() {
      debounce(
        dimensionSetter,
        100
      )({
        element,
        setDimension,
        isFit: hasNoTarget || fitHighlightedArea,
      });

      if (!highlightElement) return;
      debounce(
        dimensionHighlightSetter,
        100
      )({
        element: highlightElement,
        setDimension: setHighlightDimension,
      });
    }
    window.addEventListener("resize", reassignValue);
    return () => {
      window.removeEventListener("resize", reassignValue);
    };
    //eslint-disable-next-line
  }, [visible, element, highlightElement]);

  useEffect(() => {
    // To wait until the element render first (because of the if has no element then render null)
    // After render with 0 opacity, then add the 1 opacity to add the animation
    if (visible) {
      setTimeout(() => {
        setActiveStyle(styles.active);
      }, 100);
      return;
    }
    setActiveStyle({});
  }, [visible]);

  // To create the content of the coachmark
  let content = null;
  if (fixatedContent) {
    content = (
      <View
        ref={(el) => {
          defaultRef.current = el as RNView;
        }}
        style={[
          styles.content,
          activeStyle,
          toolTipPlacementCalculator(fixatedContent, position),
        ]}
      >
        {propsContent}
      </View>
    );
  } else if (dimension) {
    content = (
      <View
        ref={(el) => {
          defaultRef.current = el as RNView;
        }}
        style={[
          styles.content,
          activeStyle,
          toolTipPlacementCalculator(
            followHighlight && highlightDimension
              ? highlightDimension
              : dimension,
            position
          ),
        ]}
      >
        {propsContent}
      </View>
    );
  }

  if (!element && fixatedContent && visible) {
    return <>{createPortal(content, document.body)}</>;
  }

  if (!dimension || !visible || !element) {
    if (dimension && visible && !element) {
      console.error("Ref is not passed properly @Coach-Mark");
    }
    return null;
  }

  // To prevent user click on the background
  const background = <View style={styles.background} />;

  // To create transparent coloring on the background
  const coachmarkOverlay = (
    <CoachmarkOverlay
      dimension={dimension}
      hasNoTarget={hasNoTarget}
      fixatedContent={fixatedContent}
    />
  );

  // To highlight the target element
  const highlight = (
    <HighlightElement
      active={Boolean(highlightElement && highlightDimension)}
      dimension={highlightDimension}
    />
  );

  return (
    <>
      {createPortal(background, document.body)}
      {createPortal(coachmarkOverlay, document.body)}
      {highlight && createPortal(highlight, document.body)}
      {createPortal(content, document.body)}
    </>
  );
}

function isElement(
  highlightTarget: Element | MutableRefObject<Element | null>
): highlightTarget is Element {
  if (highlightTarget.hasOwnProperty("current")) {
    return false;
  } else {
    return true;
  }
}

const styles = StyleSheet.create({
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 998,
  },
  content: {
    position: "absolute",
    zIndex: 1000,
    transition: "all 0.3s ease-out",
    opacity: 0,
  },
  active: {
    opacity: 1,
  },
});
