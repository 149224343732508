import {
  DetailedHTMLProps,
  HTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from "react";
import {
  StyleProp,
  StyleSheet,
  ViewStyle,
  // @ts-ignore
  unstable_createElement as createElement,
} from "react-native";

import Token from "../../Token/Token";

type TableStyle<T> = Omit<T, "style"> & {
  style?: StyleProp<ViewStyle>;
};

export function Table(props: TableStyle<HTMLAttributes<HTMLTableElement>>) {
  return createElement("table", {
    ...props,
    style: [Style.table, props.style],
  });
}

export function Tr(props: TableStyle<HTMLAttributes<HTMLTableRowElement>>) {
  return createElement("tr", {
    ...props,
    style: [Style.tr, props.style],
  });
}

export function Th(
  props: TableStyle<
    DetailedHTMLProps<
      ThHTMLAttributes<HTMLTableHeaderCellElement>,
      HTMLTableHeaderCellElement
    >
  >
) {
  return createElement("th", {
    ...props,
    style: [Style.th, props.style],
  });
}

export function Td(
  props: TableStyle<
    DetailedHTMLProps<
      TdHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    >
  >
) {
  return createElement("td", {
    ...props,
    style: [Style.td, props.style],
  });
}

const Style = StyleSheet.create({
  table: {
    // @ts-ignore
    borderCollapse: "collapse",
    width: "100%",
  },
  tr: {},
  th: {
    textAlign: "left",
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.s,
  },
  td: {
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.s,
  },
});
