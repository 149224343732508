"use client";
import {
  FC,
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Keyboard,
  NativeSyntheticEvent,
  View as RNView,
  StyleProp,
  StyleSheet,
  TextInputFocusEventData,
  TextInputKeyPressEventData,
  ViewStyle,
} from "react-native";

import IcSystemChevronDown from "@traveloka/icon-kit-web/react/IcSystemChevronDown16";

import { appendTestId } from "../../../utils/appendTestId";
import DotLoader from "../../DotLoader/DotLoader";
import Fade from "../../Fade/Fade";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import Token from "../../Token";
import View from "../../View/View";
import { InputRef } from "../Input/Input";
import InputField, { InputFieldProps } from "../InputField/InputField";
import InputDropdownList, { InputDropdownListProps } from "./InputDropdownList";
import { InputDropdownItemValue } from "./types";

export type InputDropdownProps<T> = {
  dropdownStyle?: StyleProp<ViewStyle>;
  width?: number;
  onPressItem?: InputDropdownListProps<T>["onPressItem"];
  items: T[];
  searchable?: boolean;
  position?: "down" | "up";
  dropdownHeight?: number;
  dropdownOffset?: number;
  FooterComponent?: ReactNode;
  ItemComponent?: FC<any>;
  FilterEmptyComponent?: ReactElement;
  // TODO Chore: Need to check all usage if this props logic can be implemented permanently
  labelAsInputValue?: boolean;
  selectable?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  isEndIconClickable?: boolean;
  visible?: boolean;
  onVisibleChange?(visible: boolean): void;
  /**
   * If true, change dropdown into loading state
   */
  loading?: boolean;
  errorRef?: (element: HTMLElement | null) => void;
} & InputFieldProps;

function InputDropdown<T extends InputDropdownItemValue>(
  props: InputDropdownProps<T>,
  ref: ForwardedRef<InputRef>
) {
  const {
    dropdownStyle,
    items,
    onChangeText = noop,
    onPressItem = noop,
    value,
    containerStyle,
    width,
    editable,
    searchable,
    position = "down",
    testID,
    dropdownHeight,
    dropdownOffset,
    ItemComponent,
    FooterComponent,
    FilterEmptyComponent,
    labelAsInputValue = false,
    isEndIconClickable = true,
    visible: controlledVisible,
    onVisibleChange,
    loading,
    onFocus,
    selectable = true,
    errorRef,
    ...inputProps
  } = props;
  const rootRef = useRef<RNView>(null);
  const itemsLength = useRef(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterText, setFilterText] = useState<string>("");
  const [filterItems, setFilterItems] = useState<T[]>([]);

  const itemMap = useMemo(() => {
    return items.reduce((obj, info) => {
      obj[info.value] = info.label;

      return obj;
    }, {} as Dictionary<string>);
  }, [items]);

  function switchPosition() {
    switch (position) {
      case "down":
        return styles.positionDown;
      case "up":
        return styles.positionUp;
    }
  }

  function handleFocus(e: NativeSyntheticEvent<TextInputFocusEventData>) {
    if (!selectable) return;
    setActiveIndex(0);
    setShowDropdown(true);
    onVisibleChange?.(true);
    handleChangeText("");
    onFocus?.(e);
  }

  function handlePressItem(item: T) {
    setShowDropdown(false);
    onVisibleChange?.(false);
    onPressItem(item);
  }

  function filter(text: string) {
    if (searchable) {
      const list = items.filter((item) => {
        if (item.label.toLocaleLowerCase().includes(text.toLowerCase())) {
          return true;
        } else if (item.subLabel) {
          return item.subLabel.toLocaleLowerCase().includes(text.toLowerCase());
        }

        return false;
      });

      setFilterItems(list);
    }
  }

  function handleChangeText(text: string) {
    filter(text);
    setFilterText(text);
    onChangeText(text);
  }

  function moveCursor(increment: number) {
    let newIndex = activeIndex + increment;

    if (newIndex < 0) {
      newIndex = itemsLength.current - 1;
    } else if (newIndex >= itemsLength.current) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  }

  function handleKeyPress(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    switch (e.nativeEvent.key) {
      case "ArrowUp":
        moveCursor(-1);
        break;
      case "ArrowDown":
        moveCursor(+1);
        break;
      case "Escape":
      case "Tab":
        Keyboard.dismiss();
        setShowDropdown(false);
        onVisibleChange?.(false);

        break;
      case "Enter":
        if (filterItems[activeIndex]) {
          handlePressItem(filterItems[activeIndex]);
        } else {
          setShowDropdown(false);
          onVisibleChange?.(false);
        }
        break;
    }
    return;
  }

  useOnClickOutside(rootRef, () => {
    setShowDropdown(false);
    onVisibleChange?.(false);
  });

  useEffect(() => {
    setFilterItems((prev) => (prev === items ? prev : items));
  }, [setFilterItems, items]);

  useEffect(() => {
    itemsLength.current = filterItems.length;
  }, [filterItems]);

  let shownValue = value;
  if (showDropdown && editable) {
    shownValue = filterText;
  } else if (labelAsInputValue && value) {
    shownValue = itemMap[value];
  }

  const dropdownOffsetStyle =
    !!dropdownOffset &&
    (position === "up" ? { bottom: dropdownOffset } : { top: dropdownOffset });

  return (
    <View ref={rootRef} style={[containerStyle, { position: "relative" }]}>
      <Fade
        visible={controlledVisible ?? showDropdown}
        style={[
          styles.dropdown,
          switchPosition(),
          { width },
          dropdownStyle,
          dropdownOffsetStyle,
        ]}
      >
        <InputDropdownList
          testID={testID}
          items={loading ? [] : filterItems}
          onPressItem={handlePressItem}
          activeIndex={activeIndex}
          dropdownHeight={dropdownHeight}
          FooterComponent={FooterComponent}
          ItemComponent={ItemComponent}
          FilterEmptyComponent={
            FilterEmptyComponent ??
            (loading ? <DotLoader style={styles.dotLoader} /> : undefined)
          }
        />
      </Fade>
      <InputField
        {...inputProps}
        ref={ref}
        rootRef={errorRef}
        testID={appendTestId(testID, "input")}
        editable={editable}
        onFocus={handleFocus}
        onChangeText={handleChangeText}
        onKeyPress={handleKeyPress}
        value={shownValue}
        isEndIconClickable={isEndIconClickable}
        endIcon={
          selectable ? (
            <IcSystemChevronDown
              width={16}
              height={16}
              color={
                inputProps.disabled
                  ? Token.color.lightSecondary
                  : Token.color.bluePrimary
              }
            />
          ) : undefined
        }
      />
    </View>
  );
}

// Adding type assertion for forwardRef.
// https://oida.dev/typescript-react-generic-forward-refs/
export default forwardRef(InputDropdown) as <T>(
  props: InputDropdownProps<T> & { ref?: React.ForwardedRef<InputRef> }
) => ReturnType<typeof InputDropdown>;

const styles = StyleSheet.create({
  dropdown: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 10,
  },
  dotLoader: { paddingVertical: 12, marginHorizontal: "auto" },
  positionDown: {
    paddingTop: Token.spacing.xs,
    top: "100%",
  },
  positionUp: {
    bottom: "100%",
  },
});

// ===== HELPERS
function noop() {}
