import { StyleProp, StyleSheet, TextStyle } from "react-native";

import Text, { TextProps } from "../Text";
import Token from "../Token";

export type BadgeProps = {
  text: string;
  /**
   * Custom style applied to the root element
   */
  style?: StyleProp<TextStyle>;
  /**
   * Defines look and feel of the component
   * @default 'info'
   */
  variant?:
    | "alert"
    | "info-subtle"
    | "info"
    | "negative-subtle"
    | "negative"
    | "neutral-subtle"
    | "neutral"
    | "notification"
    | "outline-dark"
    | "outline-light"
    | "positive-subtle"
    | "positive"
    | "warning-subtle"
    | "warning";

  textVariant?: TextProps["variant"];
};

export default function Badge(props: BadgeProps) {
  const { style, text, variant = "info", textVariant } = props;

  const rootStyles = [styles.root, styles[variant], style];

  return (
    <Text style={rootStyles} variant={textVariant}>
      {text}
    </Text>
  );
}

const outlineStyle: TextStyle = {
  borderWidth: Token.borderWidth.thick,
  paddingVertical: Token.spacing.xxs - Token.borderWidth.thick,
  paddingHorizontal: Token.spacing.xs - Token.borderWidth.thick,
};

const styles = StyleSheet.create({
  root: {
    ...Token.typography.uiTiny,
    borderRadius: Token.borderRadius.rounded,
    paddingHorizontal: Token.spacing.xs,
    paddingVertical: Token.spacing.xxs,
    textAlign: "center",
  },

  // Variant
  alert: {
    backgroundColor: Token.color.yellowPrimary,
    color: Token.color.darkPrimary,
  },
  "info-subtle": {
    backgroundColor: Token.color.blueLight,
    color: Token.color.blueDark,
  },
  info: {
    backgroundColor: Token.color.blueSecondary,
    color: Token.color.lightPrimary,
  },
  "negative-subtle": {
    backgroundColor: Token.color.redLight,
    color: Token.color.redDark,
  },
  negative: {
    backgroundColor: Token.color.redSecondary,
    color: Token.color.lightPrimary,
  },
  "neutral-subtle": {
    backgroundColor: Token.color.lightNeutral,
    color: Token.color.darkSecondary,
  },
  neutral: {
    backgroundColor: Token.color.darkNeutral,
    color: Token.color.lightSecondary,
  },
  notification: {
    backgroundColor: Token.color.orangePrimary,
    color: Token.color.lightPrimary,
  },
  "outline-dark": {
    ...outlineStyle,
    borderColor: Token.color.darkPrimary,
    color: Token.color.darkPrimary,
  },
  "outline-light": {
    ...outlineStyle,
    borderColor: Token.color.lightPrimary,
    color: Token.color.lightPrimary,
  },
  "positive-subtle": {
    backgroundColor: Token.color.greenLight,
    color: Token.color.greenDark,
  },
  positive: {
    backgroundColor: Token.color.greenSecondary,
    color: Token.color.lightPrimary,
  },
  "warning-subtle": {
    backgroundColor: Token.color.yellowLight,
    color: Token.color.yellowDark,
  },
  warning: {
    backgroundColor: Token.color.yellowSecondary,
    color: Token.color.lightPrimary,
  },
});
