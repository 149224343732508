"use client";
import { PropsWithChildren, createContext, useContext } from "react";

export type ClientInterface = "mobile" | "desktop";

const ClientInterfaceContext = createContext<ClientInterface>(null!);

export default function ClientInterfaceProvider(
  props: PropsWithChildren<{ clientInterface: ClientInterface }>
) {
  return (
    <ClientInterfaceContext.Provider value={props.clientInterface}>
      {props.children}
    </ClientInterfaceContext.Provider>
  );
}

export function useClientInterface() {
  return useContext(ClientInterfaceContext);
}
