import { useController, useFormContext } from "react-hook-form";
import { NativeSyntheticEvent, StyleSheet } from "react-native";

import { createRhfRef } from "../../../utils";
import { FieldProps } from "../types";
import InputDropdown, { InputDropdownProps } from "./InputDropdown";

export type InputDropdownRHFProps = FieldProps<string> &
  Omit<InputDropdownProps<any>, "value"> & {
    leftHelper?: string;
    rightHelper?: string;
  };

export default function InputDropdownRHF(props: InputDropdownRHFProps) {
  const {
    defaultValue,
    name,
    validate,
    onFocus,
    label,
    leftHelper,
    rightHelper,
    disabled,
    style,
    onPressItem,
    items,
    ...inputDropdownProps
  } = props;

  const { control } = useFormContext();
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  function handleFocus(e: NativeSyntheticEvent<any>) {
    if (typeof onFocus === "function") {
      onFocus(e);
    }
  }

  function handlePressItem(item: any) {
    onChange(item.value);
    onPressItem && onPressItem(item);
  }

  return (
    <InputDropdown
      {...inputDropdownProps}
      errorRef={createRhfRef(ref)}
      style={style}
      label={label}
      error={error?.message}
      startHelper={leftHelper}
      endHelper={rightHelper}
      disabled={disabled}
      onBlur={onBlur}
      onFocus={handleFocus}
      items={items}
      onPressItem={handlePressItem}
      value={value || ""}
      containerStyle={styles.input}
      labelAsInputValue
    />
  );
}

const styles = StyleSheet.create({
  input: {
    zIndex: 1,
  },
});
