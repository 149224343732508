import Link, { LinkProps } from "next/link";
import { PropsWithChildren, forwardRef } from "react";

type OptionalLinkType = PropsWithChildren<LinkProps & { className: string }>;

export default forwardRef<HTMLAnchorElement, OptionalLinkType>(
  function OptionalLink(props: OptionalLinkType, ref) {
    const { href, children, ...rest } = props;
    return href ? (
      <Link ref={ref} {...rest} href={href} target="_blank">
        {children}
      </Link>
    ) : (
      children
    );
  }
);
