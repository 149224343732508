import { CSSProperties, ReactElement, cloneElement } from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from "react-native";

import { useHoverable } from "../../hooks/useHoverable";
import { appendTestId } from "../../utils";
import Text, { TextProps } from "../Text/Text";
import Token from "../Token";
import View from "../View";

export type FakeInputProps = {
  testID?: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  text: string;
  onPress?(): void;
  ink?: TextProps["ink"];
  variant?: TextProps["variant"];
  inputStyle?: StyleProp<ViewStyle>;
  size?: "medium" | "small";
  disabled?: boolean;
};

export default function FakeInput(props: FakeInputProps) {
  const {
    testID,
    leftIcon,
    rightIcon,
    text,
    onPress,
    ink,
    variant,
    inputStyle,
    size = "medium",
    disabled,
  } = props;

  const forcedLeftIcon =
    leftIcon &&
    enforceIconStyle(leftIcon, 24, { marginRight: Token.spacing.xs });
  const forcedRightIcon =
    rightIcon &&
    enforceIconStyle(rightIcon, 16, { marginLeft: Token.spacing.xs });

  const [isHovered, eventMap] = useHoverable();

  const containerStyle = {
    borderColor: isHovered
      ? Token.color.godwitBlue
      : Token.color.lightSecondary,
    backgroundColor: Token.color.lightPrimary,
    height: size === "medium" ? 40 : 32,
  };

  return (
    <TouchableOpacity
      testID={appendTestId(testID, "input.trigger")}
      activeOpacity={0.5}
      onPress={onPress}
      disabled={disabled}
    >
      <View
        {...eventMap}
        style={[Style.inputContainer, containerStyle, inputStyle]}
      >
        {forcedLeftIcon}
        <Text
          numberOfLines={1}
          style={Style.inputText}
          ink={disabled ? "black-muted" : ink}
          variant={variant}
        >
          {text}
        </Text>
        {forcedRightIcon}
      </View>
    </TouchableOpacity>
  );
}
function enforceIconStyle(
  icon: ReactElement,
  size: number,
  style: CSSProperties
) {
  return cloneElement(icon, {
    width: size,
    height: size,
    style,
  });
}

const Style = StyleSheet.create({
  inputContainer: {
    padding: Token.spacing.xs - Token.borderWidth.thin,
    borderRadius: Token.borderRadius.normal,
    borderWidth: Token.borderWidth.thick,
    transitionDuration: `${Token.timing.instant}ms`,
    transitionProperty: "border-color",
    flexDirection: "row",
    alignItems: "center",
  },
  inputText: {
    flex: 1,
  },
  medium: {
    height: 40,
  },
  small: {
    height: 32,
  },
});
