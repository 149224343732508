"use client";
import { set } from "es-cookie";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback } from "react";

import { Locale, localeCookieKey } from "../constants";

export default function useChangeLocale() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // to retain search params / query string
  const searchParamList = searchParams.toString();

  // pathname will retain previous locale `/en-id/...`
  const [_, __, ...parsedPathname] = pathname.split("/");

  return useCallback(
    (locale: Locale) => {
      let url = `/${locale}/${parsedPathname.join("/")}`;
      if (searchParamList) {
        url += `?${searchParamList}`;
      }

      set(localeCookieKey, locale);
      router.push(url);
    },
    [router, parsedPathname, searchParamList]
  );
}
