import { Animated, StyleSheet } from "react-native";

import Token from "../Token/Token";

type Props = {
  dimension: {
    topSpace: number;
    leftSpace: number;
    height: number;
    width: number;
  };
  hasNoTarget: boolean;
  fixatedContent?: {
    topSpace: number;
    leftSpace: number;
    height: number;
    width: number;
  };
};
export default function CoachmarkOverlay(props: Props) {
  const { dimension, hasNoTarget, fixatedContent } = props;

  if (fixatedContent) {
    return (
      <>
        <Animated.View
          style={[
            styles.coachmarkOverlay,
            hasNoTarget && styles.active,
            {
              top: fixatedContent.topSpace,
              left: fixatedContent.leftSpace,
              height: fixatedContent.height,
              width: fixatedContent.width,
            },
          ]}
        />
        <Animated.View
          style={[
            styles.coachmarkOverlay,
            !hasNoTarget && styles.active,
            {
              top: dimension.topSpace,
              left: dimension.leftSpace,
              height: dimension.height,
              width: dimension.width,
            },
          ]}
        />
      </>
    );
  }

  return (
    <Animated.View
      style={[
        styles.coachmarkOverlay,
        styles.active,
        {
          top: dimension.topSpace,
          left: dimension.leftSpace,
          height: dimension.height,
          width: dimension.width,
        },
      ]}
    />
  );
}

const styles = StyleSheet.create({
  coachmarkOverlay: {
    position: "absolute",
    border: "1px solid var(--black-transparent)",
    borderStyle: "solid",
    borderWidth: Token.borderWidth.thick,
    borderColor: Token.opacity.obscure(Token.color.darkPrimary),
    zIndex: 999,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    boxShadow: `0px 0px 0px 3000px ${
      Token.opacity.obscure(Token.color.darkPrimary) as string
    }`,
    borderRadius: Token.borderRadius.normal,
    opacity: 0,
  },
  active: {
    opacity: 1,
  },
});
