"use client";
import { Fragment, ReactNode, useMemo, useRef } from "react";
import {
  View as RNView,
  ScrollView,
  StyleSheet,
  ViewStyle,
} from "react-native";

import Text from "../../Text/Text";
import Token from "../../Token/Token";
import View from "../../View/View";

import AZItem from "./AZItem";
import AZScrollBar, { azList } from "./AZScrollBar";
import { AZData } from "./types";

type Props = {
  type: "form" | "view";
  customNotFound?: ReactNode;
  data: AZData[];
  disableAction?: boolean;
  contentStyle: ViewStyle;
  selectedData: AZData[];
  checkDisabled?(item: AZData): boolean;
  onPress(item: AZData): void;
};

export default function AZGroupScroll(props: Props) {
  const {
    customNotFound,
    checkDisabled,
    data,
    disableAction,
    type,
    contentStyle,
    selectedData,
    onPress,
  } = props;
  const azRef = useRef(Array(azList.length));
  const scrollRef = useRef<ScrollView>(null);

  const current = { key: "", index: -1 };
  const newGroups = useMemo(
    () =>
      data.reduce<string[]>((acc, item, index) => {
        const isNewGroup =
          !index || formatData(item) !== formatData(data[index - 1]);
        if (isNewGroup) acc.push(item.text.trim()[0].toUpperCase());
        return acc;
      }, []),
    [data]
  );

  return (
    <View style={styles.wrapper} row>
      <AZScrollBar
        activeLetters={newGroups}
        azRef={azRef}
        onScroll={(top) => scrollRef.current?.scrollTo({ y: top })}
      />
      <ScrollView ref={scrollRef} style={contentStyle}>
        {data.length
          ? data.map((item, index) => {
              current.index += 1;
              const currentGroup = item.text.trim()[0];
              const isNewGroup =
                currentGroup.toLowerCase() !== current.key.toLowerCase();
              if (isNewGroup) {
                current.key = item.text.trim()[0].toUpperCase();
                current.index = -1;
              }
              const groupIndex = azList.indexOf(currentGroup.toUpperCase());

              return (
                <Fragment key={index}>
                  {isNewGroup && (
                    <View
                      ref={(el) =>
                        (el as RNView)?.measure((_, y) => {
                          azRef.current[groupIndex] = y;
                        })
                      }
                    >
                      <Text variant="ui-small-bold" style={styles.azGroup}>
                        {current.key}
                      </Text>
                    </View>
                  )}
                  <AZItem
                    checkDisabled={checkDisabled}
                    disableAction={disableAction}
                    type={type}
                    item={item}
                    onPress={onPress}
                    isSelected={selectedData
                      .map((x) => x.value)
                      .includes(item.value)}
                    style={current.index % 2 === 0 ? styles.odd : undefined}
                  />
                </Fragment>
              );
            })
          : customNotFound}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    zIndex: -1,
  },
  azContainer: {
    padding: Token.spacing.s,
    borderRightColor: Token.color.borderSubtle,
    borderRightWidth: Token.borderWidth.thin,
  },
  azGroup: {
    paddingHorizontal: Token.spacing.s,
    paddingTop: Token.spacing.s,
    paddingBottom: Token.spacing.xs,
    borderBottomColor: Token.color.borderSubtle,
    borderBottomWidth: Token.borderWidth.thin,
  },
  odd: {
    backgroundColor: Token.color.lightStain,
  },
});

function formatData(item: AZData) {
  return item.text.trim()[0].toLowerCase();
}
