"use client";
import { useSearchParams } from "next/navigation";
import { useCallback } from "react";

function useQsStringReturn() {
  const searchParams = useSearchParams();

  return useCallback(() => {
    let qsString = "?";
    for (const [key, value] of searchParams.entries()) {
      qsString += `${key}=${value}&`;
    }
    return qsString.replace(/&$/, "");
  }, [searchParams]);
}

function useQsObjectReturn() {
  const searchParams = useSearchParams();

  return useCallback(() => {
    const qsObject: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
      qsObject[key] = value;
    }
    return qsObject;
  }, [searchParams]);
}

function useQueryString(returnType: "string"): string;
// eslint-disable-next-line no-redeclare -- intentional for function overloading
function useQueryString<T extends Record<string, string>>(
  returnType: "object"
): Partial<T>;
// eslint-disable-next-line no-redeclare -- intentional for function overloading
function useQueryString(returnType: "string" | "object") {
  const getQsStringReturn = useQsStringReturn();
  const getQsObjectReturn = useQsObjectReturn();

  switch (returnType) {
    case "string":
      return getQsStringReturn();
    case "object":
      return getQsObjectReturn();
  }
}

export default useQueryString;
