import { Fragment } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import Card from "../Card/Card";
import Image from "../Image/Image";
import Text from "../Text/Text";
import Token from "../Token/Token";

import styles from "./PhotoList.module.css";

export type PhotoListProps = {
  /**
   * Controls max number card shown.
   * @default 4
   */
  maxCardCount: number;
  /**
   * Controls horizontal align.
   * @default flex-start
   */
  hAlign?: "flex-end" | "flex-start" | "center";
  /**
   * Controls card width.
   * @default 66
   */
  cardWidth?: number;
  /**
   * Controls card height.
   * @default 66
   */
  cardHeight?: number;
  /**
   * Array of images shown in the cards.
   * @default []
   */
  images: Array<string>;
  /**
   * On Press Callback, it will return callback with params index of the card
   */
  onPress: (idx: number) => void;
  /**
   * Array of images alternative texts
   * @default []
   */
  imageAlts?: string[];
};

const renderOverlay = (number: number) => {
  return (
    <Fragment>
      <View style={Style.overlay} />
      <Text
        style={Style.moreCardCount}
        variant={"title-2"}
      >{`+${number}`}</Text>
    </Fragment>
  );
};

const renderCard = (
  src: string,
  props: PhotoListProps,
  idx: number,
  moreCardCount: number,
  imageAlts?: string[]
) => {
  const { cardHeight, cardWidth, onPress, maxCardCount } = props;
  // moreCardCount > 1: if moreCard = 1, do not render moreCard overlay
  const showOverlay = idx === maxCardCount - 1 && moreCardCount > 1;

  if (idx >= maxCardCount) {
    return null;
  }

  return (
    <View style={Style.cardContainer} key={src}>
      <TouchableOpacity
        onPress={() => onPress(idx)}
        activeOpacity={Number(Token.opacity.translucent())}
      >
        <Card elevation="container" style={Style.card}>
          {showOverlay && renderOverlay(moreCardCount)}
          <Image
            className={styles.image}
            height={cardHeight}
            width={cardWidth}
            variant="rounded"
            src={src}
            alt={imageAlts?.[idx]}
          />
        </Card>
      </TouchableOpacity>
    </View>
  );
};

function PhotoList(props: PhotoListProps) {
  const { images, maxCardCount, hAlign, imageAlts } = props;
  const moreCardCount = images.length - (maxCardCount - 1 || 0);
  let containerStyle;

  if (hAlign) {
    containerStyle = [Style.container, { justifyContent: hAlign }];
  } else {
    containerStyle = [Style.container];
  }

  return (
    <View style={containerStyle}>
      {images.map((image, idx) =>
        renderCard(image, props, idx, moreCardCount, imageAlts)
      )}
    </View>
  );
}

PhotoList.defaultProps = {
  maxCardCount: 4,
  hAlign: "flex-start",
  cardWidth: 66,
  cardHeight: 66,
  images: [],
  onPress: (idx: number) => void 0,
  imageAlts: [],
};

const Style = StyleSheet.create({
  card: {
    alignSelf: "stretch",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    position: "absolute",
    backgroundColor: Token.color.darkPrimary,
    margin: "auto",
    opacity: Number(Token.opacity.obscure()),
    width: "100%",
    height: "100%",
    borderRadius: Token.borderRadius.normal,
  },
  moreCardCount: {
    color: "white",
    position: "absolute",
  },
  container: {
    flexDirection: "row",
  },
  cardContainer: {
    marginRight: 8,
  },
});

export default PhotoList;
