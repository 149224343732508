import { ReactNode } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import InputError from "./InputError";
import InputHelper from "./InputHelper";
import InputLabel from "./InputLabel";

export type InputGroupProps = {
  label?: string;
  error?: string;
  leftHelper?: string;
  rightHelper?: string;
  children: ReactNode;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function InputGroup(props: InputGroupProps) {
  const { label, error, leftHelper, rightHelper, disabled, children, style } =
    props;

  const hasError = !!error && !disabled;
  const hasHelper = leftHelper || rightHelper;

  return (
    <View style={style}>
      {label && <InputLabel text={label} disabled={disabled} />}
      {children}
      {hasError && <InputError text={error!} />}
      {hasHelper && (
        <InputHelper
          startText={leftHelper}
          endText={rightHelper}
          disabled={disabled}
        />
      )}
    </View>
  );
}
