import { ReactElement } from "react";
import { FlatList } from "react-native";

import { appendTestId } from "../../../utils";
import Card from "../../Card/Card";
import DotLoader from "../../DotLoader/DotLoader";
import Token from "../../Token/Token";
import InputCheckboxDropdownItem from "./InputCheckboxDropdownItem";
import InputCheckboxDropdownItemGroup from "./InputCheckboxDropdownItemGroup";
import { InputCheckboxItem } from "./types";

export type InputCheckboxDropdownListProps<T> = {
  testID?: string;
  selectedItems: string[];
  disabledItems?: string[];
  items: T[];
  onPressItem: (item: T) => void;
  dropdownHeight?: number;
  footerItem?: T;
  footerComponent?: ReactElement;
  headerComponent?: ReactElement;
  loading?: boolean;
};

type FinalItem = InputCheckboxItem & { hasHeader?: boolean };

export default function InputCheckboxDropdownList<T extends InputCheckboxItem>(
  props: InputCheckboxDropdownListProps<T>
) {
  const {
    testID,
    selectedItems,
    disabledItems = [],
    items,
    onPressItem,
    dropdownHeight = 350,
    headerComponent,
    footerItem,
    footerComponent,
    loading,
  } = props;

  let finalItems: FinalItem[] = items;
  if (Boolean(items[0]?.group)) {
    let previousGroup = "";
    finalItems = items.map((item) => {
      const { group } = item;
      const hasHeader = previousGroup !== group!;
      previousGroup = group!;

      return {
        ...item,
        hasHeader,
      };
    });
  }

  return (
    <Card elevation="float">
      {loading ? (
        <DotLoader
          style={{ marginHorizontal: "auto", marginVertical: Token.spacing.xs }}
        />
      ) : (
        <>
          {headerComponent}
          <FlatList
            style={{ maxHeight: dropdownHeight }}
            initialNumToRender={10}
            maxToRenderPerBatch={10}
            data={finalItems}
            windowSize={3}
            keyExtractor={(item, index) => `${index}-${item.value}`}
            ListFooterComponent={
              footerItem && (
                <InputCheckboxDropdownItem
                  testID={appendTestId(testID, `list.footer-item`)}
                  label={footerItem.label}
                  subLabel={footerItem.subLabel}
                  onPress={() => onPressItem(footerItem)}
                  checked={selectedItems.includes(footerItem.value)}
                  hasCheckbox={footerItem.value !== ""}
                />
              )
            }
            renderItem={({ item, index }) => (
              <>
                {hasHeader(item) && (
                  <InputCheckboxDropdownItemGroup text={item.group} />
                )}
                <InputCheckboxDropdownItem
                  testID={appendTestId(testID, `list.${index}`)}
                  label={item.label}
                  subLabel={item.subLabel}
                  onPress={() => onPressItem(item as T)}
                  checked={selectedItems.includes(item.value)}
                  hasCheckbox={item.value !== ""}
                  disabled={disabledItems.includes(item.value)}
                />
              </>
            )}
          />
          {footerComponent}
        </>
      )}
    </Card>
  );
}

function hasHeader(item: FinalItem): item is Required<FinalItem> {
  return item.hasHeader === true;
}
