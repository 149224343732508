import { ObserverCallback } from './types';

type Instance = {
  observer: IntersectionObserver;
  elements: Map<Element, ObserverCallback[]>;
};

const observerMap = new Map<string, Instance>();

const rootIds = new Set<Element | Document>();

function getRootId(root?: IntersectionObserverInit['root']) {
  if (!root) return '0';

  if (!rootIds.has(root)) rootIds.add(root);

  return rootIds.size.toString();
}

function optionsToId(options: IntersectionObserverInit) {
  const keys = Object.keys(options) as Array<keyof IntersectionObserverInit>;

  return keys
    .sort()
    .filter((key) => options[key] !== undefined)
    .map((key) => {
      return `${key}_${
        key === 'root' ? getRootId(options.root) : options[key]
      }`;
    })
    .toString();
}

function createInstance(options: IntersectionObserverInit) {
  const id = optionsToId(options);
  let instance = observerMap.get(id);

  if (!instance) {
    // Create a map of elements this observer is going to observe.
    // Each element has a list of callbacks that should be triggered,
    // once it comes into view.
    const elements = new Map<Element, ObserverCallback[]>();

    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        elements.get(entry.target)?.forEach((callback) => {
          callback(entry);
        });
      }
    }, options);

    instance = { observer, elements };

    observerMap.set(id, instance);
  }

  return { ...instance, id };
}

export default function observe(
  element: Element,
  callback: ObserverCallback,
  options: IntersectionObserverInit = {}
) {
  if (!element)
    return () => {
      // does nothing
    };

  const { id, observer, elements } = createInstance(options);

  // Register the callback listener for this element
  const callbacks = elements.get(element) || [];
  if (!elements.has(element)) {
    elements.set(element, callbacks);
  }

  callbacks.push(callback);
  observer.observe(element);

  return function unobserve() {
    // Remove the callback from the callback list
    callbacks.splice(callbacks.indexOf(callback), 1);

    if (callbacks.length === 0) {
      // No more callback exists for element, so destroy it
      elements.delete(element);
      observer.unobserve(element);
    }

    if (elements.size === 0) {
      // No more elements are being observer by this instance, so destroy it
      observer.disconnect();
      observerMap.delete(id);
    }
  };
}
