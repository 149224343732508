import { ComponentProps, ReactHTML } from "react";
import Text, { TextProps } from "../Text";

export type HtmrTextProps = Omit<ComponentProps<keyof ReactHTML>, "ref"> &
  Pick<TextProps, "variant" | "ink">;

/**
 * Wrapper for Text momentum component when using htmr library.
 * This component is created to cater transform object parameter in htmr
 * which requires ReactHTML props in the provided functional component
 */
export default function HtmrText(props: HtmrTextProps) {
  const { children, variant, ink } = props;
  return (
    <Text variant={variant} ink={ink}>
      {children}
    </Text>
  );
}
