import { cloneElement, HTMLAttributes, ReactElement } from "react";
import { LayoutChangeEvent, StyleSheet, TouchableOpacity } from "react-native";

import Text, { TextProps } from "../Text";
import Token from "../Token";
import View, { ViewSeo } from "../View";
import cssStyles from "./TabSeo.module.css";

export type TabSeoProps<T extends string> = {
  className?: string;
  fixed?: boolean;
  icon?: ReactElement;
  iconPosition?: "top" | "start" | "end";
  label: string;
  onPress?(value: T): void;
  onLayout?(event: LayoutChangeEvent): void;
  selected?: boolean;
  value?: T;
  variant?: TextProps["variant"];
} & HTMLAttributes<HTMLDivElement>;

export default function TabSeo<T extends string>(props: TabSeoProps<T>) {
  const {
    role = "menuitem",
    className = "",
    fixed,
    icon: iconProp,
    iconPosition = "start",
    label,
    onPress,
    onLayout,
    selected,
    style,
    value,
    variant = "ui-baseline",
    ...rest
  } = props;

  const iconColor = selected
    ? Token.color.bluePrimary
    : Token.color.darkSecondary;
  const textInk = selected ? "interactive" : "black-secondary";
  const icon =
    iconProp &&
    cloneElement(iconProp, {
      accentColor: iconColor,
      color: iconColor,
      height: 16,
      width: 16,
    });

  const rootStyle = [
    cssStyles.root,
    fixed && cssStyles.fixed,
    cssStyles["position-" + iconPosition],
    className,
  ].join(" ");
  const iconStyle = [cssStyles.icon, cssStyles["icon-" + iconPosition]].join(
    " "
  );

  function handlePress() {
    if (typeof onPress === "function") onPress(value!);
  }

  return (
    <View onLayout={onLayout}>
      <ViewSeo
        {...rest}
        role={role}
        onClick={handlePress}
        className={rootStyle}
      >
        {icon && <ViewSeo className={iconStyle}>{icon}</ViewSeo>}
        <Text
          ink={textInk}
          numberOfLines={1}
          style={styles.label}
          variant={variant}
        >
          {label}
        </Text>
      </ViewSeo>
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    textAlign: "center",
  },
});
