import { useController, useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";

import Checkbox from "../Checkbox/Checkbox";
import Text from "../Text/Text";
import { FieldProps } from "../form/types";

type CheckboxRHFProps = {
  onChange?: (value: boolean) => void;
  /**
   * @default false
   */
  disabled?: boolean;
  /**
   * @default true
   */
  inline?: boolean;
  /**
   * Use custom colour
   * @default Token.color.uiBluePrimary
   */
  color?: string;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
  testID?: string;
  style?: StyleProp<ViewStyle>;
};

type Props = FieldProps<boolean> & CheckboxRHFProps;

export default function CheckboxRHF(props: Props) {
  const {
    name,
    validate,
    onChange,
    style,
    defaultValue,
    label,
    ...checkboxFieldProps
  } = props;

  const { control } = useFormContext();
  const {
    field: { value, onChange: onChangeField },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  function handleChange(value: boolean) {
    onChangeField(value);
    onChange && onChange(value);
  }

  return (
    <View style={style}>
      <Checkbox
        {...checkboxFieldProps}
        onChange={handleChange}
        checked={value ?? false}
      >
        {label && <Text>{label}</Text>}
      </Checkbox>
    </View>
  );
}
