type Stack = {
  modalId: string;
  isActive: boolean;
  listener: Listener;
};

type Listener = (value: boolean) => void;

class ModalManager {
  stack: Stack[] = [];

  private notify() {
    if (this.empty) {
      return;
    }

    const activeModal = this.stack.find((modal) => modal.isActive);
    activeModal?.listener(true);
  }

  get empty() {
    return this.stack.length === 0;
  }

  get topModal() {
    return this.stack[this.stack.length - 1];
  }

  isTopModal(modalId: string) {
    return !this.empty && this.topModal.modalId === modalId;
  }

  isDuplicate(modalId: string) {
    return this.stack.some((curr) => curr.modalId === modalId);
  }

  add(modalId: string, listener: Listener) {
    if (this.isDuplicate(modalId)) return;

    let isActive = true;
    if (this.stack.length > 0) {
      isActive = false;
    }

    this.stack.push({ modalId, isActive, listener });
    this.notify();
  }

  remove(modalId: string) {
    const index = this.stack.findIndex((modal) => modal.modalId === modalId);
    if (index !== -1) {
      const removedStack = this.stack.splice(index, 1);
      removedStack[0]?.listener(false);
      if (!this.empty) {
        this.topModal.isActive = true;
      }
      this.notify();
    }
  }
}

const modalManager = new ModalManager();

export default modalManager;
