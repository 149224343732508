"use client";
import {
  Children,
  cloneElement,
  isValidElement,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";

import Token from "../Token";
import View, { ViewRef } from "../View";
import { TabProps } from "./Tab";

export type TabsProps<T extends string> = {
  containerRef?: MutableRefObject<ViewRef | null>;
  children?: ReactNode;
  iconPosition?: "top" | "start" | "end";
  onPress?(value: T): void;
  style?: StyleProp<ViewStyle>;
  value: T;
  variant?: "fixed" | "scrollable";
};

export default function Tabs<T extends string>(props: TabsProps<T>) {
  const {
    containerRef,
    children,
    iconPosition,
    onPress,
    style,
    value,
    variant = "scrollable",
  } = props;

  const tabsRef = useRef<Map<string, [number, number]>>(new Map());
  const [state, setState] = useState({
    animated: false,
    x: 0,
    width: 0,
  });

  useEffect(() => {
    // Delay to wait for the tab to be rendered
    setTimeout(() => {
      const tab = tabsRef.current.get(value);
      if (tab) {
        const [x, width] = tab;

        setState({ animated: true, x, width });
      }
    }, 100);
  }, [value]);

  const indicatorStyle: StyleProp<ViewStyle> = [
    styles.indicator,
    state.animated && styles.indicatorAnimated,
    { transform: [{ translateX: state.x }], width: state.width },
  ];

  return (
    <View
      ref={containerRef}
      style={[styles.root, style]}
      accessibilityRole="tablist"
      row
    >
      {Children.toArray(children).map((child, index) => {
        if (
          !isValidElement<
            TabProps & {
              value: string;
            }
          >(child)
        ) {
          return null;
        }

        const childIconPosition = iconPosition ?? child.props.iconPosition;
        const childValue = child.props.value ?? String(index);

        const selected = childValue === value;

        return cloneElement(child, {
          fixed: variant === "fixed",
          iconPosition: childIconPosition,
          onPress,
          selected,
          value: childValue,
          onLayout(e: LayoutChangeEvent) {
            // Tab have xxs inset
            const x = e.nativeEvent.layout.x + 4;
            const width = e.nativeEvent.layout.width - 8;

            tabsRef.current.set(childValue, [x, width]);

            if (selected) {
              setState({ animated: false, width, x });
            }
          },
          style: index !== 0 && styles.tabSpacer,
        });
      })}
      <View accessibilityRole="none" style={indicatorStyle} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: Token.color.lightPrimary,
    minHeight: 48,
    // @ts-expect-error
    width: "fit-content",
  },
  indicator: {
    position: "absolute",
    height: 3,
    width: 1,
    bottom: 0,
    backgroundColor: Token.color.bluePrimary,
    // @ts-expect-error
    transformOrigin: "left bottom",
    // borderRadius: Token.borderRadius.rounded,
  },
  indicatorAnimated: {
    transitionDuration: `${Token.timing.instant}ms`,
    transitionProperty: "transform, width",
  } as ViewStyle,
  tabSpacer: {
    marginLeft: Token.spacing.m,
  },
});
