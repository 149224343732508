"use client";
import { useEffect, useState, ReactNode } from "react";
import { createPortal } from "react-dom";

export type PortalProps = {
  children?: ReactNode;
};

export default function Portal(props: PortalProps) {
  const { children } = props;

  const [mount, setMount] = useState(false);
  useEffect(() => {
    setMount(true);
  }, []);

  return mount ? createPortal(children, document.body) : null;
}
