import {
  cloneElement,
  forwardRef,
  PropsWithoutRef,
  ReactElement,
  Ref,
  RefAttributes,
} from "react";
import { StyleSheet } from "react-native";

import Text from "../Text";
import Token from "../Token";
import View, { ViewProps, ViewRef } from "../View";

export type BottomBarActionRef = ViewRef;

export type BottomBarActionProps<T> = {
  icon: ReactElement;
  label: string;
  selected?: boolean;
  value?: T;
} & ViewProps;

function BottomBarAction<T>(
  props: BottomBarActionProps<T>,
  ref: Ref<BottomBarActionRef>
) {
  const {
    accessibilityRole = "link",
    icon: iconProp,
    label,
    selected,
    ...rest
  } = props;

  const iconColor = selected
    ? Token.color.bluePrimary
    : Token.color.darkSecondary;
  const icon =
    iconProp &&
    cloneElement(iconProp, {
      width: 24,
      height: 24,
      color: iconColor,
      accentColor: iconColor,
    });

  return (
    <View
      {...rest}
      accessibilityRole={accessibilityRole}
      align="center"
      justify="center"
      ref={ref}
      style={styles.root}
    >
      {icon}
      <Text
        ink={selected ? "interactive" : "black-secondary"}
        variant="caption-tiny"
        style={styles.label}
        numberOfLines={1}
      >
        {label}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  label: {
    marginTop: Token.spacing.xxs,
  },
});

// Preserve generic types
export default forwardRef(BottomBarAction) as <T>(
  props: PropsWithoutRef<BottomBarActionProps<T>> &
    RefAttributes<BottomBarActionRef>
) => JSX.Element;
