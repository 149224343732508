import { StyleSheet, TouchableOpacity } from "react-native";
import Image from "../Image";
import Token from "../Token";

export type AppBarActionProps = {
  edge?: "end" | "start";
  icon: string;
  onPress?(): void;
};

export default function AppBarAction(props: AppBarActionProps) {
  const { edge, icon, onPress } = props;

  function press() {
    if (typeof onPress === "function") onPress();
  }

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      style={[styles.root, edge && styles[edge]]}
      onPress={press}
    >
      <Image src={icon} width={24} height={24} alt={icon} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: Token.spacing.s,
  },
  end: { marginEnd: -Token.spacing.s },
  start: { marginStart: -Token.spacing.s },
});
