"use client";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useRef,
  useState,
} from "react";
import { LayoutChangeEvent } from "react-native";

import { Spacing } from "../Token";
import { ViewSeo } from "../View";
import { TabSeoProps } from "./TabSeo";

import cssStyles from "./TabsSeo.module.css";

export type TabsPropsSeo<T extends string> = {
  children?: ReactNode;
  iconPosition?: "top" | "start" | "end";
  onPress?(value: T): void;
  className?: string;
  containerClassName?: string;
  value: T;
  variant?: "fixed" | "scrollable";
  /**
   * Spacing between tab and indicator
   */
  spacingWrapper?: Spacing;
  /**
   * Spacing between tabs
   */
  spacingRoot?: Spacing;
};

export default function TabsSeo<T extends string>(props: TabsPropsSeo<T>) {
  const {
    children,
    iconPosition,
    onPress: propOnPress,
    className,
    containerClassName,
    spacingWrapper = "m",
    spacingRoot = "l",
    value,
    variant = "scrollable",
  } = props;
  const tabsRef = useRef<Map<string, [number, number]>>(new Map());
  const [state, setState] = useState({
    animated: false,
    x: 0,
    width: 0,
  });

  const indicatorStyle = [
    cssStyles.indicator,
    state.animated && cssStyles.indicatorAnimated,
  ].join(" ");

  function onPress(value: T) {
    const tab = tabsRef.current.get(value);
    if (tab) {
      const [x, width] = tab;
      setState({ animated: true, x: x, width });
    }
    propOnPress?.(value);
  }

  return (
    <ViewSeo spacing={spacingWrapper} className={containerClassName}>
      <ViewSeo
        row
        className={[cssStyles.root, className].join(" ")}
        role="tablist"
        spacing={spacingRoot}
      >
        {Children.toArray(children).map((child, index) => {
          if (
            !isValidElement<
              TabSeoProps<T> & {
                value: string;
              }
            >(child)
          ) {
            return null;
          }

          const childIconPosition = iconPosition ?? child.props.iconPosition;
          const childValue = child.props.value ?? String(index);

          const selected = childValue === value;
          const newProps = {
            fixed: variant === "fixed",
            iconPosition: childIconPosition,
            onPress,
            selected,
            value: childValue,
            onLayout(e: LayoutChangeEvent) {
              // Tab have xxs inset
              const x = e.nativeEvent.layout.x + 4;
              const width = e.nativeEvent.layout.width - 8;

              tabsRef.current.set(childValue, [x, width]);

              if (selected) {
                setState({ animated: false, width, x });
              }
            },
          };

          return cloneElement(child, newProps);
        })}
      </ViewSeo>
      <ViewSeo
        role="none"
        className={indicatorStyle}
        style={{
          transform: `translateX(${state.x}px)`,
          width: `${state.width}px`,
        }}
      />
    </ViewSeo>
  );
}
