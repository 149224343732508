export type CountryInfo = {
  countryId: string;
  countryName: string;
  telPref: string;
};

export default [
  { countryId: "AF", telPref: "93", countryName: "Afghanistan" },
  { countryId: "AL", telPref: "355", countryName: "Albania" },
  { countryId: "DZ", telPref: "213", countryName: "Algeria" },
  { countryId: "AS", telPref: "1684", countryName: "American Samoa" },
  { countryId: "AD", telPref: "376", countryName: "Andorra" },
  { countryId: "AO", telPref: "244", countryName: "Angola" },
  { countryId: "AI", telPref: "1264", countryName: "Anguilla" },
  { countryId: "AQ", telPref: "672", countryName: "Antarctica" },
  { countryId: "AG", telPref: "1268", countryName: "Antigua and Barbuda" },
  { countryId: "AR", telPref: "54", countryName: "Argentina" },
  { countryId: "AM", telPref: "374", countryName: "Armenia" },
  { countryId: "AW", telPref: "297", countryName: "Aruba" },
  { countryId: "AU", telPref: "61", countryName: "Australia" },
  { countryId: "AT", telPref: "43", countryName: "Austria" },
  { countryId: "AZ", telPref: "994", countryName: "Azerbaijan" },
  { countryId: "BS", telPref: "1242", countryName: "Bahamas" },
  { countryId: "BH", telPref: "973", countryName: "Bahrain" },
  { countryId: "BD", telPref: "880", countryName: "Bangladesh" },
  { countryId: "BB", telPref: "1246", countryName: "Barbados" },
  { countryId: "BY", telPref: "375", countryName: "Belarus" },
  { countryId: "BE", telPref: "32", countryName: "Belgium" },
  { countryId: "BZ", telPref: "501", countryName: "Belize" },
  { countryId: "BJ", telPref: "229", countryName: "Benin" },
  { countryId: "BM", telPref: "1441", countryName: "Bermuda" },
  { countryId: "BT", telPref: "975", countryName: "Bhutan" },
  { countryId: "BO", telPref: "591", countryName: "Bolivia" },
  { countryId: "BA", telPref: "387", countryName: "Bosnia and Herzegovina" },
  { countryId: "BW", telPref: "267", countryName: "Botswana" },
  { countryId: "BV", telPref: "47", countryName: "Bouvet Island" },
  { countryId: "BR", telPref: "55", countryName: "Brazil" },
  {
    countryId: "IO",
    telPref: "246",
    countryName: "British Indian Ocean Territory",
  },
  { countryId: "VG", telPref: "1284", countryName: "British Virgin Islands" },
  { countryId: "BN", telPref: "673", countryName: "Brunei" },
  { countryId: "BG", telPref: "359", countryName: "Bulgaria" },
  { countryId: "BF", telPref: "226", countryName: "Burkina Faso" },
  { countryId: "BI", telPref: "257", countryName: "Burundi" },
  { countryId: "KH", telPref: "855", countryName: "Cambodia" },
  { countryId: "CM", telPref: "237", countryName: "Cameroon" },
  { countryId: "CA", telPref: "1", countryName: "Canada" },
  { countryId: "CV", telPref: "238", countryName: "Cape Verde" },
  { countryId: "KY", telPref: "1345", countryName: "Cayman Islands" },
  { countryId: "CF", telPref: "236", countryName: "Central African Republic" },
  { countryId: "TD", telPref: "235", countryName: "Chad" },
  { countryId: "CL", telPref: "56", countryName: "Chile" },
  { countryId: "CN", telPref: "86", countryName: "China" },
  { countryId: "CX", telPref: "61", countryName: "Christmas Island" },
  { countryId: "CC", telPref: "61", countryName: "Cocos Islands" },
  { countryId: "CO", telPref: "57", countryName: "Colombia" },
  { countryId: "KM", telPref: "269", countryName: "Comoros" },
  { countryId: "CD", telPref: "243", countryName: "Congo Democratic Republic" },
  { countryId: "CG", telPref: "242", countryName: "Congo Republic" },
  { countryId: "CK", telPref: "682", countryName: "Cook Islands" },
  { countryId: "CR", telPref: "506", countryName: "Costa Rica" },
  { countryId: "HR", telPref: "385", countryName: "Croatia" },
  { countryId: "CU", telPref: "53", countryName: "Cuba" },
  { countryId: "CY", telPref: "357", countryName: "Cyprus" },
  { countryId: "CZ", telPref: "420", countryName: "Czech Republic" },
  { countryId: "DK", telPref: "45", countryName: "Denmark" },
  { countryId: "DJ", telPref: "253", countryName: "Djibouti" },
  { countryId: "DM", telPref: "1767", countryName: "Dominica" },
  { countryId: "DO", telPref: "1809", countryName: "Dominican Republic" },
  { countryId: "EC", telPref: "593", countryName: "Ecuador" },
  { countryId: "EG", telPref: "20", countryName: "Egypt" },
  { countryId: "SV", telPref: "503", countryName: "El Salvador" },
  { countryId: "GQ", telPref: "240", countryName: "Equatorial Guinea" },
  { countryId: "ER", telPref: "291", countryName: "Eritrea" },
  { countryId: "EE", telPref: "372", countryName: "Estonia" },
  { countryId: "ET", telPref: "251", countryName: "Ethiopia" },
  { countryId: "FK", telPref: "500", countryName: "Falkland Islands" },
  { countryId: "FO", telPref: "298", countryName: "Faroe Islands" },
  { countryId: "FJ", telPref: "679", countryName: "Fiji" },
  { countryId: "FI", telPref: "358", countryName: "Finland" },
  { countryId: "FR", telPref: "33", countryName: "France" },
  { countryId: "PF", telPref: "689", countryName: "French Polynesia" },
  {
    countryId: "TF",
    telPref: "569",
    countryName: "French Southern Territories",
  },
  { countryId: "GA", telPref: "241", countryName: "Gabon" },
  { countryId: "GM", telPref: "220", countryName: "Gambia" },
  { countryId: "GE", telPref: "995", countryName: "Georgia" },
  { countryId: "DE", telPref: "49", countryName: "Germany" },
  { countryId: "GH", telPref: "233", countryName: "Ghana" },
  { countryId: "GI", telPref: "350", countryName: "Gibraltar" },
  { countryId: "GR", telPref: "30", countryName: "Greece" },
  { countryId: "GL", telPref: "299", countryName: "Greenland" },
  { countryId: "GD", telPref: "1473", countryName: "Grenada" },
  { countryId: "GU", telPref: "1671", countryName: "Guam" },
  { countryId: "GT", telPref: "502", countryName: "Guatemala" },
  { countryId: "GG", telPref: "44", countryName: "Guernsey" },
  { countryId: "GW", telPref: "245", countryName: "Guinea-Bissau" },
  { countryId: "GN", telPref: "224", countryName: "Guinea" },
  { countryId: "GY", telPref: "592", countryName: "Guyana" },
  { countryId: "HT", telPref: "509", countryName: "Haiti" },
  {
    countryId: "HM",
    telPref: "672",
    countryName: "Heard Island and McDonald Islands",
  },
  { countryId: "VA", telPref: "39", countryName: "Holy See" },
  { countryId: "HN", telPref: "504", countryName: "Honduras" },
  { countryId: "HK", telPref: "852", countryName: "Hong Kong" },
  { countryId: "HU", telPref: "36", countryName: "Hungary" },
  { countryId: "IS", telPref: "354", countryName: "Iceland" },
  { countryId: "IN", telPref: "91", countryName: "India" },
  { countryId: "ID", telPref: "62", countryName: "Indonesia" },
  { countryId: "IR", telPref: "98", countryName: "Iran" },
  { countryId: "IQ", telPref: "964", countryName: "Iraq" },
  { countryId: "IE", telPref: "353", countryName: "Ireland" },
  { countryId: "IM", telPref: "44", countryName: "Isle of Man" },
  { countryId: "IL", telPref: "972", countryName: "Israel" },
  { countryId: "IT", telPref: "39", countryName: "Italy" },
  { countryId: "CI", telPref: "225", countryName: "Ivory Coast" },
  { countryId: "JM", telPref: "1876", countryName: "Jamaica" },
  { countryId: "JP", telPref: "81", countryName: "Japan" },
  { countryId: "JE", telPref: "441534", countryName: "Jersey" },
  { countryId: "JO", telPref: "962", countryName: "Jordan" },
  { countryId: "KZ", telPref: "7", countryName: "Kazakhstan" },
  { countryId: "KE", telPref: "254", countryName: "Kenya" },
  { countryId: "KI", telPref: "686", countryName: "Kiribati" },
  { countryId: "KP", telPref: "850", countryName: "Korea North" },
  { countryId: "KR", telPref: "82", countryName: "Korea South" },
  { countryId: "KW", telPref: "965", countryName: "Kuwait" },
  { countryId: "KG", telPref: "996", countryName: "Kyrgyzstan" },
  { countryId: "LA", telPref: "856", countryName: "Laos" },
  { countryId: "LV", telPref: "371", countryName: "Latvia" },
  { countryId: "LB", telPref: "961", countryName: "Lebanon" },
  { countryId: "LS", telPref: "266", countryName: "Lesotho" },
  { countryId: "LR", telPref: "231", countryName: "Liberia" },
  { countryId: "LY", telPref: "218", countryName: "Libya" },
  { countryId: "LI", telPref: "423", countryName: "Liechtenstein" },
  { countryId: "LT", telPref: "370", countryName: "Lithuania" },
  { countryId: "LU", telPref: "352", countryName: "Luxembourg" },
  { countryId: "MO", telPref: "853", countryName: "Macau" },
  { countryId: "MK", telPref: "389", countryName: "Macedonia" },
  { countryId: "MG", telPref: "261", countryName: "Madagascar" },
  { countryId: "MW", telPref: "265", countryName: "Malawi" },
  { countryId: "MY", telPref: "60", countryName: "Malaysia" },
  { countryId: "MV", telPref: "960", countryName: "Maldives" },
  { countryId: "ML", telPref: "223", countryName: "Mali" },
  { countryId: "MT", telPref: "356", countryName: "Malta" },
  { countryId: "MH", telPref: "692", countryName: "Marshall Islands" },
  { countryId: "MR", telPref: "222", countryName: "Mauritania" },
  { countryId: "MU", telPref: "230", countryName: "Mauritius" },
  { countryId: "YT", telPref: "262", countryName: "Mayotte" },
  { countryId: "MX", telPref: "52", countryName: "Mexico" },
  { countryId: "FM", telPref: "691", countryName: "Micronesia" },
  { countryId: "MD", telPref: "373", countryName: "Moldova" },
  { countryId: "MC", telPref: "377", countryName: "Monaco" },
  { countryId: "MN", telPref: "976", countryName: "Mongolia" },
  { countryId: "ME", telPref: "382", countryName: "Montenegro" },
  { countryId: "MS", telPref: "1664", countryName: "Montserrat" },
  { countryId: "MA", telPref: "212", countryName: "Morocco" },
  { countryId: "MZ", telPref: "258", countryName: "Mozambique" },
  { countryId: "MM", telPref: "95", countryName: "Myanmar" },
  { countryId: "NA", telPref: "264", countryName: "Namibia" },
  { countryId: "NR", telPref: "674", countryName: "Nauru" },
  { countryId: "NP", telPref: "977", countryName: "Nepal" },
  { countryId: "AN", telPref: "599", countryName: "Netherlands Antilles" },
  { countryId: "NL", telPref: "31", countryName: "Netherlands" },
  { countryId: "NC", telPref: "687", countryName: "New Caledonia" },
  { countryId: "NZ", telPref: "64", countryName: "New Zealand" },
  { countryId: "NI", telPref: "505", countryName: "Nicaragua" },
  { countryId: "NE", telPref: "227", countryName: "Niger" },
  { countryId: "NG", telPref: "234", countryName: "Nigeria" },
  { countryId: "NU", telPref: "683", countryName: "Niue" },
  { countryId: "NF", telPref: "672", countryName: "Norfolk Island" },
  { countryId: "MP", telPref: "1670", countryName: "Northern Mariana Islands" },
  { countryId: "NO", telPref: "47", countryName: "Norway" },
  { countryId: "OM", telPref: "968", countryName: "Oman" },
  { countryId: "PK", telPref: "92", countryName: "Pakistan" },
  { countryId: "PW", telPref: "680", countryName: "Palau" },
  { countryId: "PS", telPref: "970", countryName: "Palestinian Territory" },
  { countryId: "PA", telPref: "507", countryName: "Panama" },
  { countryId: "PG", telPref: "675", countryName: "Papua New Guinea" },
  { countryId: "PY", telPref: "595", countryName: "Paraguay" },
  { countryId: "PE", telPref: "51", countryName: "Peru" },
  { countryId: "PH", telPref: "63", countryName: "Philippines" },
  { countryId: "PN", telPref: "64", countryName: "Pitcairn" },
  { countryId: "PL", telPref: "48", countryName: "Poland" },
  { countryId: "PT", telPref: "351", countryName: "Portugal" },
  { countryId: "PR", telPref: "1", countryName: "Puerto Rico" },
  { countryId: "QA", telPref: "974", countryName: "Qatar" },
  { countryId: "RS", telPref: "381", countryName: "Republic of Serbia" },
  { countryId: "RO", telPref: "40", countryName: "Romania" },
  { countryId: "RU", telPref: "7", countryName: "Russia" },
  { countryId: "RW", telPref: "250", countryName: "Rwanda" },
  { countryId: "BL", telPref: "590", countryName: "Saint Barthelemy" },
  {
    countryId: "SH",
    telPref: "290",
    countryName: "Saint Helena Ascension and Tristan da Cunha",
  },
  { countryId: "KN", telPref: "1869", countryName: "Saint Kitts and Nevis" },
  { countryId: "LC", telPref: "1758", countryName: "Saint Lucia" },
  { countryId: "MF", telPref: "590", countryName: "Saint Martin" },
  { countryId: "PM", telPref: "508", countryName: "Saint Pierre and Miquelon" },
  {
    countryId: "VC",
    telPref: "1784",
    countryName: "Saint Vincent and the Grenadines",
  },
  { countryId: "WS", telPref: "685", countryName: "Samoa" },
  { countryId: "SM", telPref: "378", countryName: "San Marino" },
  { countryId: "ST", telPref: "239", countryName: "Sao Tome and Principe" },
  { countryId: "SA", telPref: "966", countryName: "Saudi Arabia" },
  { countryId: "SN", telPref: "221", countryName: "Senegal" },
  { countryId: "SC", telPref: "248", countryName: "Seychelles" },
  { countryId: "SL", telPref: "232", countryName: "Sierra Leone" },
  { countryId: "SG", telPref: "65", countryName: "Singapore" },
  { countryId: "SK", telPref: "421", countryName: "Slovakia" },
  { countryId: "SI", telPref: "386", countryName: "Slovenia" },
  { countryId: "SB", telPref: "677", countryName: "Solomon Islands" },
  { countryId: "SO", telPref: "252", countryName: "Somalia" },
  { countryId: "ZA", telPref: "27", countryName: "South Africa" },
  { countryId: "ES", telPref: "34", countryName: "Spain" },
  { countryId: "LK", telPref: "94", countryName: "Sri Lanka" },
  { countryId: "SD", telPref: "249", countryName: "Sudan" },
  { countryId: "SR", telPref: "597", countryName: "Suriname" },
  { countryId: "SJ", telPref: "47", countryName: "Svalbard" },
  { countryId: "SZ", telPref: "268", countryName: "Swaziland" },
  { countryId: "SE", telPref: "46", countryName: "Sweden" },
  { countryId: "CH", telPref: "41", countryName: "Switzerland" },
  { countryId: "SY", telPref: "963", countryName: "Syria" },
  { countryId: "TW", telPref: "886", countryName: "Taiwan" },
  { countryId: "TJ", telPref: "992", countryName: "Tajikistan" },
  { countryId: "TZ", telPref: "255", countryName: "Tanzania" },
  { countryId: "TH", telPref: "66", countryName: "Thailand" },
  { countryId: "TL", telPref: "670", countryName: "Timor-Leste" },
  { countryId: "TG", telPref: "228", countryName: "Togo" },
  { countryId: "TK", telPref: "690", countryName: "Tokelau" },
  { countryId: "TO", telPref: "676", countryName: "Tonga" },
  { countryId: "TT", telPref: "1868", countryName: "Trinidad and Tobago" },
  { countryId: "TN", telPref: "216", countryName: "Tunisia" },
  { countryId: "TR", telPref: "90", countryName: "Turkey" },
  { countryId: "TM", telPref: "993", countryName: "Turkmenistan" },
  { countryId: "TC", telPref: "1649", countryName: "Turks and Caicos Islands" },
  { countryId: "TV", telPref: "688", countryName: "Tuvalu" },
  { countryId: "UG", telPref: "256", countryName: "Uganda" },
  { countryId: "UA", telPref: "380", countryName: "Ukraine" },
  { countryId: "AE", telPref: "971", countryName: "United Arab Emirates" },
  { countryId: "GB", telPref: "44", countryName: "United Kingdom" },
  { countryId: "US", telPref: "1", countryName: "United States" },
  { countryId: "UY", telPref: "598", countryName: "Uruguay" },
  { countryId: "UZ", telPref: "998", countryName: "Uzbekistan" },
  { countryId: "VU", telPref: "678", countryName: "Vanuatu" },
  { countryId: "VE", telPref: "58", countryName: "Venezuela" },
  { countryId: "VN", telPref: "84", countryName: "Vietnam" },
  { countryId: "VI", telPref: "1340", countryName: "Virgin Islands" },
  { countryId: "WF", telPref: "681", countryName: "Wallis and Futuna" },
  { countryId: "EH", telPref: "212", countryName: "Western Sahara" },
  { countryId: "YE", telPref: "967", countryName: "Yemen" },
  { countryId: "ZM", telPref: "260", countryName: "Zambia" },
  { countryId: "ZW", telPref: "263", countryName: "Zimbabwe" },
] as CountryInfo[];
