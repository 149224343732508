"use client";
import { useState } from "react";

type HoverConfig<T> = {
  onMouseDown?(e: T): void;
  onMouseUp?(e: T): void;
};

export default function usePress<T>(config: HoverConfig<T> = {}) {
  const { onMouseDown, onMouseUp } = config;
  const [pressed, setPressed] = useState(false);

  const handlers: HoverConfig<T> = {
    onMouseDown(e) {
      setPressed(true);
      if (typeof onMouseDown === "function") onMouseDown(e);
    },
    onMouseUp(e) {
      setPressed(false);
      if (typeof onMouseUp === "function") onMouseUp(e);
    },
  };

  return [pressed, handlers] as const;
}
