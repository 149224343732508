import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";

import Checkbox from "../../Checkbox/Checkbox";
import Text from "../../Text/Text";
import Token from "../../Token/Token";
import View from "../../View/View";

import { AZData } from "./types";

type Props = {
  type: "form" | "view";
  item: AZData;
  isSelected: boolean;
  onPress(item: AZData): void;
  checkDisabled?(item: AZData): boolean;
  disableAction?: boolean;
  style?: ViewStyle;
};

export default function AZItem(props: Props) {
  const {
    checkDisabled,
    disableAction,
    item,
    type,
    isSelected,
    onPress,
    style,
  } = props;

  const itemDisabled = checkDisabled?.(item);
  const checkboxDisabled = itemDisabled || disableAction;

  return (
    <TouchableOpacity
      disabled={type === "view" || checkboxDisabled}
      onPress={() => onPress(item)}
    >
      <View style={[styles.container, style]} row spacing="xs" align="center">
        <Checkbox
          style={type === "view" && { display: "none" }}
          checked={isSelected}
          onChange={() => onPress(item)}
          size="small"
          disabled={checkboxDisabled}
        />
        <Text>{item.render || item.text}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.s,
  },
});
