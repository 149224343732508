import { ReactNode } from "react";
import { StyleSheet } from "react-native";

import Card from "../Card/Card";
import Modal, { ModalProps } from "../Modal";
import Token from "../Token";
import View from "../View";

type OmitModalProps = Omit<ModalProps, "render" | "duration">;

export type TrayProps = OmitModalProps & {
  children?: ReactNode;
};

export default function Tray(props: TrayProps) {
  const { children, ...modalProps } = props;

  return (
    <Modal
      {...modalProps}
      isMobile
      duration={Token.timing.instant}
      render={(state) => (
        <Card elevation="float" style={[styles.root, styles[state]]}>
          <View style={styles.cue} />
          {children}
        </Card>
      )}
    />
  );
}

const styles = StyleSheet.create({
  root: {
    // @ts-expect-error
    maxHeight: "75vh",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    transitionDuration: `${Token.timing.instant}ms`,
  },
  enter: {
    transform: [{ translateY: 0 }],
    // @ts-expect-error
    animationTimingFunction: "ease-out",
  },
  exit: {
    transform: [{ translateY: "100%" as any }],
    // @ts-expect-error
    animationTimingFunction: "ease-in",
  },

  cue: {
    backgroundColor: Token.color.lightSecondary,
    borderRadius: Token.borderRadius.rounded,
    width: 32,
    height: 4,
    marginVertical: Token.spacing.xs,
    marginHorizontal: "auto",
  },
});
