import {
  DimensionValue,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import Token from "../Token";

export type SkeletonProps = {
  /**
   * Height of the skeleton.
   */
  height: number | string;
  /**
   * Custom style applied to the root element
   */
  style?: StyleProp<ViewStyle>;
  /**
   * The type of content that will be rendered.
   * @default 'text'
   */
  variant?: "circle" | "box" | "text";
  /**
   * Width of the skeleton.
   */
  width?: number | string;
};

export default function Skeleton(props: SkeletonProps) {
  const { height, style, variant = "box", width } = props;

  const rootStyle: ViewStyle = { height: Number(height) };
  const skeletonStyle = [styles.skeleton, styles[variant]];

  if (width !== undefined) {
    rootStyle.width = width as DimensionValue;
  } else {
    rootStyle.flex = 1;
  }

  return (
    <View style={[rootStyle, style]}>
      <View style={skeletonStyle} />
    </View>
  );
}

const styles = StyleSheet.create({
  skeleton: {
    animationDuration: `750ms`,
    animationIterationCount: "infinite",
    animationKeyframes: {
      from: { backgroundPosition: `100% 0` },
      to: { backgroundPosition: `-100% 0` },
    },
    animationTimingFunction: "ease-in",
    backgroundImage: `linear-gradient(to right,
      rgba(3, 18, 26, 0.05) 0%,
      rgba(3, 18, 26, 0.15) 25%,
      rgba(3, 18, 26, 0.05) 50%)`,
    backgroundSize: `200% 100%`,
    flex: 1,
  },

  // Variant
  box: {
    borderRadius: Token.borderRadius.normal,
  },
  circle: {
    borderRadius: Token.borderRadius.rounded,
  },
  text: {
    borderRadius: Token.borderRadius.normal,
    // Approximation of ratio between font size and line height
    transform: [{ scaleY: 0.75 }],
  },
});
