export { default as AZTable } from "./AZTable/AZTable";

export * from "./PaginationTable/PaginationTable";
export { default as PaginationTable } from "./PaginationTable/PaginationTable";

export * from "./Table/Table";
export * from "./Table/TableSeo";

export * from "./AZTable/types";
export * from "./PaginationTable/types";
