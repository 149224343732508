import { StyleProp, TextStyle } from "react-native";
import Text from "../../Text";

export type InputErrorProps = {
  text: string;
  nativeID?: string;
  style?: StyleProp<TextStyle>;
};

export default function InputError(props: InputErrorProps) {
  const { text, nativeID, style } = props;

  return (
    <Text
      nativeID={nativeID}
      variant="ui-tiny"
      ink="alert"
      ellipsizeMode="tail"
      style={style}
    >
      {text}
    </Text>
  );
}
