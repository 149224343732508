"use client";
import { useState } from "react";

type HoverConfig<T> = {
  onFocus?(e: T): void;
  onBlur?(e: T): void;
};

export default function useFocus<T>(config: HoverConfig<T> = {}) {
  const { onFocus, onBlur } = config;
  const [focused, setFocused] = useState(false);

  const handlers: HoverConfig<T> = {
    onFocus(e) {
      setFocused(true);
      if (typeof onFocus === "function") onFocus(e);
    },
    onBlur(e) {
      setFocused(false);
      if (typeof onBlur === "function") onBlur(e);
    },
  };

  return [focused, handlers] as const;
}
