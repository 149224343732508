"use client";
import { format, isValid } from "date-fns";
import { useCallback, useRef, useState } from "react";
import {
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TouchableOpacity,
  ViewStyle,
} from "react-native";

import Calendar, { CalendarProps } from "../Calendar/Calendar";
import Card from "../Card/Card";
import Fade from "../Fade/Fade";
import InputField, { InputFieldProps } from "../form/InputField/InputField";
import useOnClickOutside from "../hooks/useOnClickOutside";
import View from "../View/View";

import CalendarIcon from "@traveloka/icon-kit-web/react/IcSystemCalendar16";

type Props = {
  containerStyle?: StyleProp<ViewStyle>;
  direction?: "up" | "down";
  defaultValue?: Date;
  value?: Date;
  onChange?(date: Date): void;
  formatDate: string;
  selectable?: boolean;
} & Omit<InputFieldProps, "defaultValue" | "value" | "onChange"> &
  Pick<CalendarProps, "minDate" | "maxDate">;

export default function CalendarInputField(props: Props) {
  const {
    minDate,
    maxDate,
    containerStyle,
    direction = "down",
    defaultValue = null,
    editable = false,
    value,
    formatDate,
    onChange,
    onFocus,
    selectable = true,
    ...inputProps
  } = props;
  const [date, setDate] = useState<Nullable<Date>>(defaultValue);
  const inputRef = useRef<HTMLElement>(null);
  const rootRef = useRef<TextInput>(null);
  const [visible, setVisible] = useState(false);

  const handleDateChange = useCallback(
    (date: Date) => {
      setDate(date);
      onChange?.(date);
      setVisible(false);
    },
    [onChange]
  );
  const handleFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (!selectable) return;
      setVisible(true);
      onFocus?.(e);
    },
    [onFocus, selectable]
  );

  useOnClickOutside(rootRef, () => setVisible(false));

  return (
    <View ref={rootRef} style={[styles.container, containerStyle]}>
      <InputField
        {...inputProps}
        editable={editable}
        ref={inputRef}
        value={formatValue(value ?? date, formatDate)}
        onFocus={handleFocus}
        endIcon={
          <TouchableOpacity
            disabled={!selectable}
            onPress={() => {
              setVisible((prev) => !prev);
              inputRef.current?.focus();
            }}
          >
            <CalendarIcon />
          </TouchableOpacity>
        }
      />
      <Fade
        style={[
          styles.calendar,
          direction === "down" ? styles.calendarDown : styles.calendarUp,
        ]}
        visible={visible}
      >
        <Card>
          <Calendar
            minDate={minDate}
            maxDate={maxDate}
            toggleYear
            value={value ?? date ?? new Date()}
            onChange={handleDateChange}
          />
        </Card>
      </Fade>
    </View>
  );
}

function formatValue(value: Nullable<Date>, formatDate: string) {
  if (isValidDate(value)) {
    return format(value, formatDate);
  }
  return "";
}

function isValidDate(date: Nullable<Date>): date is Date {
  return isValid(date);
}

const styles = StyleSheet.create({
  container: { position: "relative", zIndex: 10 },
  calendar: { position: "absolute" },
  calendarUp: { bottom: "100%" },
  calendarDown: { top: "100%" },
});
