/**
 * observeRect utility
 * this utility is used to observe any changes made to an element boundingClientRect values (el.getBoundingClientRect)
 * this utility also provide information for absolute positioning, i.e. pageX and pageY coordinate relative to
 * the root element (not viewport)
 *
 * This is used because ResizeObserver only observe viewport resizes, while sometimes we need to get an element
 * boundingClientRect values or absolute positioning coordinates at any given time to render a view correctly
 *
 * To use this, first you need to initiate the observer by calling the observeRect function and provide your valid DOM node
 * and your desired callback if a change happens. The callback function may receive 2 parameters, which are rect that
 * contains the value of getBoundingClientRect() of the node, and pagePosition, which contains the information about
 * pageX and pageY, x and y coordinate relative to the root element
 *
 * If a node has already been observed by another component, since the implementation uses map, it will automatically add your callback
 * to the node's observed state
 *
 * To start observing, call the observe function from your initated observer
 * Don't forget to stop observing once you don't need it anymore (i.e. dismounting) by calling the unobserve function.
 *
 * example usage with useEffect and reactRef:
 * import { useRef, useEffect } from 'react';
 * import { View, findDomNode } from 'react-native';
 *
 * function Component(props) {
 *  const ref = useRef();
 *
 *  useEffect(
 *    () => {
 *      if (ref && ref.current) {
 *        const refNode = findDomNode(ref.current);
 *        const observer = observeRect(refNode, (rect, pagePosition) => { ...your change handler });
 *
 *        observer.observe();
 *
 *        return observer.unobserve;
 *      }
 *
 *      return () => {}; // no op
 *    },
 *    [ref]
 *  );
 *
 *  return (
 *    <View ref={ref}>
 *      {props.children}
 *    </View>
 *  );
 * }
 *
 *
 *
 * heavily inspired from https://github.com/reach/observe-rect
 */

type Rect = {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
};

type RectPagePosition = {
  pageX: number;
  pageY: number;
};

type ChangeObservedCallback = (
  rect: Rect,
  pagePosition: RectPagePosition
) => void;

type RectState = {
  rect?: Rect;
  pagePosition?: RectPagePosition;
  hasRectChanged: boolean;
  callbacks: Array<ChangeObservedCallback>;
};

const canUseDom =
  typeof window !== "undefined" && typeof window.document !== "undefined";

const observedNodes = new Map<any, RectState>();
let rafId: any;
let totemRef: any;

if (canUseDom) {
  // Create a dummy totem DOM element as a base anchor to do absolute measurements of other rect
  // We need to do this because using document.body.getBoundingClientRect is inconsistent
  // The only drawback is that we attach one additional, non mutated, static div as a child on the body
  // ref: https://stackoverflow.com/questions/25630035/javascript-getboundingclientrect-changes-while-scrolling
  totemRef = document.createElement("div");
  totemRef.style.position = "absolute";
  totemRef.style.top = 0;
  totemRef.style.left = 0;
  document.body.appendChild(totemRef);
}

export default function observeRect(node: any, cb: ChangeObservedCallback) {
  if (!canUseDom) {
    return {
      observe: () => undefined, // no op, not in a browser env
      unobserve: () => undefined, // no op, not in a browser env
    };
  }

  return {
    observe() {
      const wasEmpty = observedNodes.size === 0;

      if (observedNodes.has(node)) {
        // @ts-ignore node exist since it has been checked up above by calling .has() but typescript ignores it
        observedNodes.get(node).callbacks.push(cb);
      } else {
        observedNodes.set(node, {
          rect: undefined,
          pagePosition: undefined,
          hasRectChanged: false,
          callbacks: [cb],
        });
      }

      if (wasEmpty) initiateObserver();
    },
    unobserve() {
      const observedNodeState = observedNodes.get(node);

      if (observedNodeState) {
        // Remove the callback
        const index = observedNodeState.callbacks.indexOf(cb);
        if (index >= 0) observedNodeState.callbacks.splice(index, 1);

        // Remove the node reference
        if (!observedNodeState.callbacks.length) observedNodes.delete(node);

        // Stop the loop
        if (!observedNodes.size) cancelAnimationFrame(rafId);
      }
    },
  };
}

function initiateObserver() {
  runCallbackWhenChangeDidHappen();

  setTimeout(observeForChange, 0);

  rafId = requestAnimationFrame(initiateObserver);
}

function runCallbackWhenChangeDidHappen() {
  observedNodes.forEach((observedNodeState) => {
    if (observedNodeState.hasRectChanged) {
      observedNodeState.callbacks.forEach((cb: ChangeObservedCallback) => {
        if (observedNodeState.rect && observedNodeState.pagePosition) {
          return cb(observedNodeState.rect, observedNodeState.pagePosition);
        }
      });
      observedNodeState.hasRectChanged = false;
    }
  });
}

function observeForChange() {
  observedNodes.forEach((observedNodeState, node) => {
    const newRect = node.getBoundingClientRect();

    if (rectHasChanged(newRect, observedNodeState.rect) && totemRef) {
      const totemRect = totemRef.getBoundingClientRect();

      // if past value exist, validate if overall change actually happens or not
      // and update state then call callback if actual change happens
      // say top or bottom changes, but given that the pageX or pageY stays the same
      // it will not update. This is to prevent non relevant callback call for this use case.
      if (observedNodeState.rect && observedNodeState.pagePosition) {
        if (
          observedNodeState.rect.width !== newRect.width ||
          observedNodeState.rect.height !== newRect.height ||
          observedNodeState.pagePosition.pageX !==
            newRect.left - totemRect.left ||
          observedNodeState.pagePosition.pageY !== newRect.top - totemRect.top
        ) {
          observedNodeState.hasRectChanged = true;
          observedNodeState.rect = newRect;
          observedNodeState.pagePosition = {
            pageX: newRect.left - totemRect.left,
            pageY: newRect.top - totemRect.top,
          };
        }
      } else {
        // if value didn't exist, initate it first.
        observedNodeState.hasRectChanged = true;
        observedNodeState.rect = newRect;
        observedNodeState.pagePosition = {
          pageX: newRect.left - totemRect.left,
          pageY: newRect.top - totemRect.top,
        };
      }
    }
  });
}

const OBSERVED_KEYS = ["width", "height", "top", "right", "bottom", "left"];

function rectHasChanged(rectA: any = {}, rectB: any = {}) {
  return OBSERVED_KEYS.some(
    (observedKey) => rectA[observedKey] !== rectB[observedKey]
  );
}
