import { HtmrOptions } from "htmr";
import HtmrLink from "./HtmrLink";
import HtmrText from "./HtmrText";

const transformConfig: HtmrOptions["transform"] = {
  span: HtmrText,
  a: HtmrLink,
};

export const HTMR_TRANSFORM: HtmrOptions = {
  transform: transformConfig,
};
