import { Auth } from "aws-amplify";

type EnvironmentMetadata =
  | {
      appEnvironment: "dev" | "test" | "demo" | "staging" | "production";
    }
  | {
      appEnvironment: "pr";
      stagingPreviewPrefix: string;
    };
function getEnvironmentMetadata(): EnvironmentMetadata {
  const { hostname } = window.location;

  if (hostname === "localhost") {
    return { appEnvironment: "dev" };
  }

  if (hostname.startsWith("pr-")) {
    return {
      appEnvironment: "pr",
      // hostname example value: pr-1058.test-corporates.ctv.staging-traveloka.com
      // getting the `pr-1058` value
      stagingPreviewPrefix: hostname.split(".")[0],
    };
  }

  if (hostname.startsWith("test-")) {
    return {
      appEnvironment: "test",
    };
  }

  if (hostname.startsWith("demo-")) {
    return {
      appEnvironment: "demo",
    };
  }

  if (hostname.includes("staging-traveloka")) {
    return {
      appEnvironment: "staging",
    };
  }

  return {
    appEnvironment: "production",
  };
}

export async function doForgetPassword(email: string) {
  const emailAddress = email.toLowerCase();
  const data = encodeBase64(generateClientMetaData(emailAddress));
  return Auth.forgotPassword(emailAddress, {
    data,
    ...getEnvironmentMetadata(),
  }).then(() => encodeURIComponent(data));
}

export function generateClientMetaData(email: string) {
  return JSON.stringify({
    ea: email.toLowerCase(),
    ed: new Date().getTime(),
  });
}

export function decodeBase64(base64: string) {
  try {
    const binString: string = atob(base64);
    return new TextDecoder().decode(
      Uint8Array.from(binString, (m) => m.codePointAt(0)!)
    );
  } catch (e) {
    return undefined;
  }
}

export function encodeBase64(data: string): string {
  const bytes = new TextEncoder().encode(data);
  const numArray = Array.prototype.slice.call(bytes) as number[];
  const binString: string = String.fromCodePoint(...numArray);
  return btoa(binString);
}
