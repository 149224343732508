import { StyleSheet, ViewStyle } from "react-native";
import { v4 as uuidV4 } from "uuid";

import Text from "../../Text/Text";
import Radio from "./Radio";
import RadioButton from "./RadioButton";

type Props = {
  /**
   * Array of objects containing "label" & "value" properties.
   *
   * It can also contain "disabled" property if you want to
   * disable some of the radio buttons.
   */
  options: Array<{
    label: string;
    /**
     *  @default empty string
     */
    value: string | number;
    /**
     *  @default false
     */
    disabled?: boolean;
  }>;
  /**
   *  @default empty string
   */
  value?: string | number;
  onChange?: (value: string | number) => void;
  /**
   * Medium: 24x24;
   *
   * Small: 16x16
   *
   * @default medium
   */
  size?: "small" | "medium";
  /**
   * HTML ID attribute for this radio group label.
   * This is mandatory for accessibility!
   */
  labelID?: string;
  /**
   * Use it to control radio button layout
   * (e.g., flexDirection)
   */
  containerStyle?: ViewStyle;
  optionContainerStyle?: ViewStyle;
};

const generatedIDFallback = uuidV4();

export default function RadioGroup({
  options,
  labelID = generatedIDFallback,
  value = "",
  onChange,
  size = "medium",
  containerStyle,
  optionContainerStyle,
}: Props) {
  const undefinedLabelID = labelID === generatedIDFallback;
  return (
    <>
      {undefinedLabelID && (
        <Text nativeID={labelID} style={styles.visuallyHidden}>
          Please Select Below Options
        </Text>
      )}
      <Radio.Group
        labelID={labelID}
        value={value}
        onChange={onChange}
        style={containerStyle}
      >
        {options.map((option) => (
          <Radio.Button
            key={option.value}
            label={option.label}
            value={option.value}
            disabled={option.disabled}
            // @ts-ignore - todo: figure out the right typing
            ButtonComponent={(props) => (
              <RadioButton
                {...props}
                size={size}
                containerStyle={optionContainerStyle}
              />
            )}
          />
        ))}
      </Radio.Group>
    </>
  );
}

const styles = StyleSheet.create({
  visuallyHidden: {
    position: "absolute",
    opacity: 0,
  },
});
