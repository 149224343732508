import { StyleSheet } from "react-native";

import Token from "../Token/Token";
import View from "../View/View";

type Props = {
  currentPage: number;
  maxPage: number;
};
export default function DotPagination(props: Props) {
  const { currentPage, maxPage } = props;
  return (
    <View row spacing="s">
      {Array.from({ length: maxPage }).map((_, index) => (
        <View
          key={index}
          style={[
            styles.dot,
            currentPage - 1 === index ? styles.active : styles.inactive,
          ]}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  active: {
    backgroundColor: Token.opacity.opaque(Token.color.darkPrimary),
  },
  inactive: {
    backgroundColor: Token.color.lightSecondary,
  },
});
