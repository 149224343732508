"use client";
import { useState } from "react";

export type HoverConfig<T> = {
  onMouseEnter?(e: T): void;
  onMouseLeave?(e: T): void;
};

export default function useHover<T>(config: HoverConfig<T> = {}) {
  const { onMouseEnter, onMouseLeave } = config;
  const [hovered, setHovered] = useState(false);

  const handlers: HoverConfig<T> = {
    onMouseEnter(e) {
      setHovered(true);
      if (typeof onMouseEnter === "function") onMouseEnter(e);
    },
    onMouseLeave(e) {
      setHovered(false);
      if (typeof onMouseLeave === "function") onMouseLeave(e);
    },
  };

  return [hovered, handlers] as const;
}
