"use client";
import { differenceWith, unionWith } from "lodash";
import { ReactNode, useMemo, useState } from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from "react-native";

import Cross from "@traveloka/icon-kit-web/react/IcSystemCrossClose16";

import { formatMessage } from "../../../utils";
import Checkbox from "../../Checkbox/Checkbox";
import Text from "../../Text/Text";
import Token from "../../Token/Token";
import View from "../../View/View";

import AZGroupScroll from "./AZGroupScroll";
import { AZData } from "./types";

type Props = {
  type: "form" | "view";
  /**
   * Props to check if the item is disabled.
   * Disabled items will not be selectable in:
   * - Item Checkbox
   * - Select All Checkbox
   * @param item
   */
  checkDisabled?(item: AZData): boolean;
  isInverted?: boolean;
  data: string[] | AZData[];
  defaultValue?: string[] | AZData[];
  /**
   * Props to disable all interactions in the table.
   */
  disableAction?: boolean;
  unfilteredData: string[] | AZData[];
  content: {
    unselectedTitle: string;
    selectedTitle: string;
    selectAllText: string;
    unselectAllText: string;
  };
  CustomHeader?: ReactNode;
  onChange?(items: string[]): void;
  customNotFound?: ReactNode;
  style?: StyleProp<ViewStyle>;
};

export default function AZTable(props: Props) {
  const {
    customNotFound,
    checkDisabled,
    disableAction,
    content,
    type,
    CustomHeader,
    onChange,
    style,
    isInverted,
    unfilteredData,
    defaultValue,
  } = props;

  const data = useMemo(
    () =>
      mapStringToData(
        props.data.sort((val1, val2) => {
          return typeof val1 === "string"
            ? val1.localeCompare(val2 as string)
            : val1.text.localeCompare((val2 as AZData).text);
        })
      ),
    [props.data]
  );

  const mappedUnfilteredData = mapStringToData(unfilteredData);

  const [selectedData, setSelectedData] = useState<AZData[]>(() => {
    if (isInverted) {
      if (defaultValue) {
        const mappedDefaultValue = mapStringToData(defaultValue);
        return mappedUnfilteredData.filter(
          (mud) => !mappedDefaultValue.some((mdv) => mdv.value === mud.value)
        );
      }

      return mappedUnfilteredData;
    }

    return defaultValue ? mapStringToData(defaultValue) : [];
  });

  function onPressCheckbox(item: AZData) {
    const isSelected = selectedData.some(
      (selectedItem) => selectedItem.value === item.value
    );
    const newSelectedData: AZData[] = isSelected
      ? selectedData.filter((selectedItem) => selectedItem.value !== item.value)
      : [...selectedData, item];

    onChange?.(
      isInverted
        ? invertSelectedData(mappedUnfilteredData, newSelectedData)
        : newSelectedData.map((data) => data.value)
    );
    setSelectedData(newSelectedData);
  }

  const selectableData = data.filter((item) => !checkDisabled?.(item));

  const isSelectedAll =
    !!selectedData.length && selectedData.length === selectableData.length;

  const shownSelectedData = isInverted
    ? mappedUnfilteredData
        .filter((d) => !selectedData.some((sd) => sd.value === d.value))
        .sort((d1, d2) => d1.text.localeCompare(d2.text))
    : selectedData;

  return (
    <View style={[styles.wrapper, style]} row spacing="l">
      <View style={styles.container}>
        <View spacing="l" style={styles.selectedHeaderContainer}>
          <View row justify="between">
            <Text variant="ui-small-bold">
              {formatMessage(content.selectedTitle, {
                num: isInverted ? selectedData.length : selectableData.length,
              })}
            </Text>
            {type === "form" && (
              <Checkbox
                checked={isSelectedAll}
                disabled={!data.length || disableAction}
                onChange={(value) => {
                  const newSelectedData = value
                    ? unionWith(selectedData, selectableData, compareAZDATA)
                    : differenceWith(
                        selectedData,
                        selectableData,
                        compareAZDATA
                      );
                  onChange?.(
                    isInverted
                      ? invertSelectedData(
                          mappedUnfilteredData,
                          newSelectedData
                        )
                      : newSelectedData.map((data) => data.value)
                  );
                  setSelectedData(newSelectedData);
                }}
                size="small"
              >
                <Text variant="ui-small">
                  {formatMessage(
                    isSelectedAll
                      ? content.unselectAllText
                      : content.selectAllText,
                    {
                      num:
                        unfilteredData.length === data.length || !data.length
                          ? ""
                          : `(${selectableData.length})`,
                    }
                  )}
                </Text>
              </Checkbox>
            )}
          </View>
          {CustomHeader}
        </View>
        <AZGroupScroll
          customNotFound={customNotFound}
          disableAction={disableAction}
          type={type}
          contentStyle={styles.content}
          checkDisabled={checkDisabled}
          data={
            type === "form"
              ? data
              : data.filter(
                  (d) => !shownSelectedData.some((sd) => sd.value === d.value)
                )
          }
          selectedData={selectedData}
          onPress={onPressCheckbox}
        />
      </View>
      <View style={styles.container}>
        <View style={styles.selectedHeaderContainer}>
          <Text variant="ui-small-bold">
            {formatMessage(content.unselectedTitle, {
              num: isInverted ? shownSelectedData.length : selectedData.length,
            })}
          </Text>
        </View>
        <View style={styles.content}>
          {shownSelectedData.map((item, index) => (
            <View
              key={index}
              style={styles.item}
              row
              align="center"
              justify="between"
            >
              <Text style={index % 2 ? styles.odd : undefined}>
                {item.text}
              </Text>
              <TouchableOpacity
                disabled={disableAction}
                style={type === "view" && { display: "none" }}
                onPress={() => onPressCheckbox(item)}
              >
                <Cross
                  color={
                    disableAction
                      ? Token.color.lightSecondary
                      : Token.color.darkSecondary
                  }
                />
              </TouchableOpacity>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    maxHeight: 810,
  },
  container: {
    borderRadius: Token.borderRadius.normal,
    borderWidth: Token.borderWidth.thick,
    borderColor: Token.color.borderDivide,
    width: 366,
  },
  selectedHeaderContainer: {
    paddingHorizontal: Token.spacing.s,
    paddingTop: Token.spacing.m,
    paddingBottom: Token.spacing.s,
    borderBottomColor: Token.color.borderDivide,
    borderBottomWidth: Token.borderWidth.thick,
  },
  content: {
    //@ts-expect-error -- auto is not in React Native type
    overflow: "auto",
    flex: 1,
  },
  item: {
    paddingVertical: Token.spacing.xs,
    paddingLeft: Token.spacing.s,
    paddingRight: Token.spacing.m,
  },
  odd: {
    backgroundColor: Token.color.lightStain,
  },
});

function convertStringToData(value: string): AZData {
  return {
    text: value,
    value: value,
  };
}

function mapStringToData(data: string[] | AZData[]) {
  return data.map((item) => {
    if (typeof item === "string") {
      return convertStringToData(item);
    }
    return item;
  });
}
function compareAZDATA(data1: AZData, data2: AZData) {
  return data1.value === data2.value;
}

function invertSelectedData(allData: AZData[], selectedData: AZData[]) {
  return allData
    .filter((d) => !selectedData.some((sd) => sd.value === d.value))
    .sort((d1, d2) => d1.text.localeCompare(d2.text))
    .map((data) => data.value);
}
