import { parse } from "date-fns";
import { useCallback, useMemo } from "react";

import { Locale } from "../constants";
import { useLocale } from "../contexts/LocaleContext";

export type Format =
  | WeekdayFormat
  | GeneralDateFormat
  | MonthYearFormat
  | MonthOnlyFormat
  | USGeneralDateFormat;
type WeekdayFormat =
  | "SHORT_WEEKDAY" // EEE, dd MMM yyyy
  | "FULL_WEEKDAY" // EEEE, dd MMM yyyy
  | "FULL_WEEKDAY_MONTH"; // EEEE, dd MMMM yyyy
type GeneralDateFormat =
  | "SHORT_MONTH" // dd MMM yyyy
  | "SHORT_MONTH_2" // dd MMM yy
  | "FULL_MONTH"; // dd MMMM yyyy
type MonthYearFormat =
  | "SHORT_MONTH_NO_DAY" // MMM yyyy
  | "FULL_MONTH_NO_DAY"; // MMMM yyyy
type MonthOnlyFormat = "FULL_MONTH_ONLY"; // MMMM

// United States Date Format
type USGeneralDateFormat =
  | "SHORT_MONTH_US" // MMM dd, yyyy
  | "SHORT_MONTH_2_US" // MMM dd, yy
  | "FULL_MONTH_US"; // MMMM dd, yyyy

function useFormat() {
  const locale = useLocale();

  return useCallback(
    (date: Date | number, dateFormat: Format, withTime: boolean = false) => {
      try {
        const options = formatToIntlFormat(dateFormat, withTime);
        const dateParts = new Intl.DateTimeFormat(
          localeToIntlLocale(locale),
          options
        ).formatToParts(date);

        return renderDate(dateParts, dateFormat, withTime);
      } catch (error) {
        return "";
      }
    },
    [locale]
  );
}

export function useLocalizedDateFormat() {
  const format = useFormat();

  return useMemo(
    () => ({
      format,
      changeFormat: (
        date: string,
        from: string,
        to: Format,
        baseDate: number | Date = 0
      ) => {
        try {
          return format(parse(date, from, baseDate), to);
        } catch (error) {
          return "";
        }
      },
      formatWithOffset: (dateString: string, dateFormat: Format) => {
        try {
          const date = new Date(dateString.replace(" ", "T") + "Z");
          return format(date, dateFormat, true);
        } catch (error) {
          return "";
        }
      },
    }),
    [format]
  );
}

function localeToIntlLocale(locale: Locale) {
  switch (locale) {
    case Locale.ENID:
      return "en-US";
    case Locale.IDID:
      return "id-ID";
  }
}

/*
    Source:
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
    https://devhints.io/wip/intl-datetime
*/
type IntlOptions = {
  weekday?: "long" | "short" | "narrow";
  era?: "long" | "short" | "narrow";
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  fractionalSecondDigits?: 1 | 2 | 3;
  timeZoneName?: "long" | "short";
  hour12?: boolean;
};
const formatToIntlFormat = (
  format: Format,
  withTime?: boolean
): IntlOptions => {
  let options: IntlOptions;
  switch (format) {
    // Weekday
    case "SHORT_WEEKDAY":
      options = {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      break;
    case "FULL_WEEKDAY":
      options = {
        weekday: "long",
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      break;
    case "FULL_WEEKDAY_MONTH":
      options = {
        weekday: "long",
        day: "2-digit",
        month: "long",
        year: "numeric",
      };
      break;

    // General Date / US General Date
    case "SHORT_MONTH":
    case "SHORT_MONTH_US":
      options = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      break;
    case "SHORT_MONTH_2":
    case "SHORT_MONTH_2_US":
      options = {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      };
      break;
    case "FULL_MONTH":
    case "FULL_MONTH_US":
      options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
      };
      break;

    // Month Year
    case "SHORT_MONTH_NO_DAY":
      options = {
        month: "short",
        year: "numeric",
      };
      break;
    case "FULL_MONTH_NO_DAY":
      options = {
        month: "long",
        year: "numeric",
      };
      break;

    // Month Only
    case "FULL_MONTH_ONLY":
      options = {
        month: "long",
      };
      break;
  }

  if (withTime) {
    options.hour = "2-digit";
    options.minute = "2-digit";
    options.hour12 = false;
  }

  return options;
};

type DateData = {
  weekday?: string;
  day?: string;
  month?: string;
  year?: string;
  hour?: string;
  minute?: string;
};
function constructDate(dateParts: Intl.DateTimeFormatPart[]) {
  const dateData: DateData = {};
  dateParts.forEach((part) => {
    if (part.type === "weekday") dateData.weekday = part.value; // EEE
    if (part.type === "day") dateData.day = part.value; // dd
    if (part.type === "month") dateData.month = part.value; // MMM
    if (part.type === "year") dateData.year = part.value; // yyyy
    if (part.type === "hour") dateData.hour = part.value; // HH
    if (part.type === "minute") dateData.minute = part.value; // mm
  });
  return dateData;
}

const renderDate = (
  dateParts: Intl.DateTimeFormatPart[],
  format: Format,
  withTime?: boolean
): string => {
  const { day, hour, minute, month, weekday, year } = constructDate(dateParts);

  let dateStr: string;
  switch (format) {
    // Weekday
    case "SHORT_WEEKDAY":
    case "FULL_WEEKDAY":
    case "FULL_WEEKDAY_MONTH":
      dateStr = `${weekday}, ${day} ${month} ${year}`;
      break;

    // General Date
    case "SHORT_MONTH":
    case "SHORT_MONTH_2":
    case "FULL_MONTH":
      dateStr = `${day} ${month} ${year}`;
      break;

    // US General Date
    case "SHORT_MONTH_US":
    case "SHORT_MONTH_2_US":
    case "FULL_MONTH_US":
      dateStr = `${month} ${day}, ${year}`;
      break;

    // Month Year
    case "SHORT_MONTH_NO_DAY":
    case "FULL_MONTH_NO_DAY":
      dateStr = `${month} ${year}`;
      break;

    // Month Only
    case "FULL_MONTH_ONLY":
      dateStr = `${month}`;
      break;
  }

  if (withTime) {
    dateStr += ` ${hour}:${minute}`;
  }

  return dateStr;
};
