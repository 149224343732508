"use client";
import { ReactElement, useEffect, useRef, useState } from "react";
import {
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TextInputFocusEventData,
  View,
  ViewStyle,
} from "react-native";

import ChevronDownIcon from "@traveloka/icon-kit-web/react/IcSystemChevronDown16";

import { appendTestId } from "../../../utils";
import Fade from "../../Fade/Fade";
import Token from "../../Token/Token";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import InputField, { InputFieldProps } from "../InputField/InputField";
import InputCheckboxDropdownList, {
  InputCheckboxDropdownListProps,
} from "./InputCheckboxDropdownList";
import { InputCheckboxItem } from "./types";

export type Props<T> = {
  width?: number;
  onPressItem?: InputCheckboxDropdownListProps<T>["onPressItem"];
  items: T[];
  selectedItems?: string[];
  defaultSelectedItems?: string[];
  disabledItems?: string[];
  listHeader?: ReactElement;
  footerItem?: T;
  listFooter?: ReactElement;
  searchable?: boolean;
  position?: "down" | "up";
  dropdownHeight?: number;
  loading?: boolean;
  resetOnFocus?: boolean;
  isEndIconClickable?: boolean;
  dropdownStyle?: StyleProp<ViewStyle>;
  visible?: boolean;
  onVisibleChange?(visible: boolean): void;
  onClickOutside?(): void;
} & InputFieldProps;

export default function InputCheckboxDropdown<T extends InputCheckboxItem>(
  props: Props<T>
) {
  const {
    dropdownStyle,
    items,
    selectedItems: controlledSelectedItem,
    visible: controlledVisible,
    disabledItems,
    defaultSelectedItems = [],
    listHeader,
    footerItem,
    listFooter,
    onChangeText = noop,
    onPressItem = noop,
    onVisibleChange,
    onClickOutside,
    onFocus,
    value,
    style,
    width,
    editable,
    searchable,
    position = "down",
    testID,
    dropdownHeight,
    loading,
    resetOnFocus = true,
    isEndIconClickable = true,
    ...inputProps
  } = props;
  const rootRef = useRef<View>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filterItems, setFilterItems] = useState<T[]>([]);
  const [selectedItems, setSelectedItems] =
    useState<string[]>(defaultSelectedItems);

  function switchPosition() {
    switch (position) {
      case "down":
        return styles.positionDown;
      case "up":
        return styles.positionUp;
    }
  }

  function handleFocus(e: NativeSyntheticEvent<TextInputFocusEventData>) {
    onVisibleChange?.(true);
    setShowDropdown(true);
    onFocus?.(e);
    resetOnFocus && handleChangeText("");
  }

  function handlePressItem(item: T) {
    if (item.value) {
      const findItem = selectedItems.find((s) => s === item.value);

      if (findItem) {
        setSelectedItems((prev) => prev.filter((p) => p !== item.value));
      } else {
        setSelectedItems((prev) => [...prev, item.value]);
      }
    }

    onPressItem(item);
  }

  function filter(text: string) {
    if (searchable) {
      const list = items.filter((item) => {
        if (item.label.toLocaleLowerCase().includes(text.toLowerCase())) {
          return true;
        } else if (item.subLabel) {
          return item.subLabel.toLocaleLowerCase().includes(text.toLowerCase());
        }

        return false;
      });

      setFilterItems(list);
    }
  }

  function handleChangeText(text: string) {
    filter(text);
    setFilterText(text);
    onChangeText(text);
  }

  const isVisible = controlledVisible ?? showDropdown;

  useOnClickOutside(rootRef, () => {
    onVisibleChange?.(false);
    onClickOutside?.();
    setShowDropdown(false);
  });

  useEffect(() => {
    setFilterItems((prev) => (prev === items ? prev : items));
  }, [setFilterItems, items]);

  return (
    <View ref={rootRef} style={style}>
      <InputField
        {...inputProps}
        testID={appendTestId(testID, "input")}
        editable={editable}
        onFocus={handleFocus}
        onChangeText={handleChangeText}
        value={isVisible && editable ? filterText : value}
        isEndIconClickable={isEndIconClickable}
        endIcon={
          <ChevronDownIcon
            color={Token.color.bluePrimary}
            width={16}
            height={16}
          />
        }
      />
      <Fade
        visible={isVisible}
        style={[styles.dropdown, switchPosition(), { width }, dropdownStyle]}
      >
        <InputCheckboxDropdownList
          testID={testID}
          items={filterItems}
          onPressItem={handlePressItem}
          dropdownHeight={dropdownHeight}
          selectedItems={controlledSelectedItem ?? selectedItems}
          disabledItems={disabledItems}
          headerComponent={listHeader}
          footerComponent={listFooter}
          footerItem={footerItem}
          loading={loading}
        />
      </Fade>
    </View>
  );
}

const styles = StyleSheet.create({
  dropdown: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 10,
  },
  positionDown: {
    paddingTop: Token.spacing.xs,
    top: "100%",
  },
  positionUp: {
    bottom: "100%",
  },
});

// ===== HELPERS
function noop() {}
