"use client";
import {
  Children,
  cloneElement,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { View as RNView, StyleProp, StyleSheet, ViewStyle } from "react-native";

import Card from "../Card/Card";
import Fade from "../Fade/Fade";
import Token from "../Token/Token";
import View from "../View/View";
import useOnClickOutside from "../hooks/useOnClickOutside";

type Alignment = "right" | "left";
type FadeAlignment = "up" | "down";

type Props = {
  alignment?: Alignment;
  fadeAlignment?: FadeAlignment;
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
  cardStyle?: StyleProp<ViewStyle>;
  trigger: ReactElement;
  onPress?(): void;
};

export type DropdownHandler = {
  toggle(): void;
  setIsCollapsed(isCollapsed: boolean): void;
};

function Dropdown(props: Props, ref?: Ref<DropdownHandler>) {
  const {
    alignment = "left",
    fadeAlignment = "down",
    children,
    onPress,
    style,
    cardStyle,
    trigger,
  } = props;

  const rootRef = useRef<RNView>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useImperativeHandle(ref, () => ({
    toggle() {
      setIsCollapsed(!isCollapsed);
    },
    setIsCollapsed(isCollapsed: boolean) {
      setIsCollapsed(isCollapsed);
    },
  }));

  useOnClickOutside(rootRef, () => {
    setIsCollapsed(true);
  });

  const handleToggle = useCallback(() => {
    onPress && onPress();
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, onPress]);

  const triggerEl = cloneElement(Children.only(trigger), {
    onPress: handleToggle,
  });

  return (
    <View ref={rootRef} style={style}>
      {triggerEl}
      <Fade
        visible={!isCollapsed}
        style={[
          Style.dropdown,
          alignment === "right" ? Style.alignRight : Style.alignLeft,
          fadeAlignment === "down" ? Style.fadeDown : Style.fadeUp,
        ]}
      >
        <Card style={cardStyle} elevation="float">
          {children}
        </Card>
      </Fade>
    </View>
  );
}

const Style = StyleSheet.create({
  alignRight: {
    right: 0,
  },
  alignLeft: {
    left: 0,
  },
  dropdown: {
    marginTop: Token.spacing.xs,
    position: "absolute",
    zIndex: 10,
    // @ts-ignore
    width: "max-content",
  },
  fadeDown: {
    top: "100%",
  },
  fadeUp: {
    bottom: "100%",
  },
});

export default forwardRef(Dropdown);
