"use client";
import { addDays, addYears, min, subDays, subYears } from "date-fns";
import { useState } from "react";
import { StyleSheet, View } from "react-native";

import {
  Format,
  useLocalizedDateFormat,
} from "../../core/locale/hooks/useLocalizedDateFormat";
import { appendTestId } from "../../utils";
import Calendar from "../Calendar/Calendar";
import Collapsible from "../Collapsible/Collapsible";
import Divider from "../Divider/Divider";
import Text from "../Text/Text";
import Token from "../Token/Token";

type Props = {
  testID?: string;
  startLabel: string;
  startDate: Date;
  endLabel: string;
  endDate: Date;
  onChange(start: Date, end: Date): void;
  dateFormat: Format;
};

export default function DateRange(props: Props) {
  const {
    testID,
    startLabel,
    startDate,
    endLabel,
    endDate,
    onChange,
    dateFormat,
  } = props;

  const containerStyle = {
    borderColor: Token.color.lightNeutral,
  };

  const [state, setState] = useState({
    start: false,
    end: false,
  });
  function handlePress(field: "start" | "end") {
    setState({
      start: false,
      end: false,
      [field]: !state[field],
    });
  }

  return (
    <View
      testID={appendTestId(testID, "container")}
      style={[Style.dateRange, containerStyle]}
    >
      <DateInput
        testID={appendTestId(testID, "start-date")}
        visible={state.start}
        onPress={() => handlePress("start")}
        label={startLabel}
        value={startDate}
        onChange={(value) => onChange(value, endDate)}
        dateFormat={dateFormat}
        minDate={addDays(subYears(endDate, 1), 1)}
        maxDate={endDate}
      />
      <Divider subtle />
      <DateInput
        testID={appendTestId(testID, "end-date")}
        visible={state.end}
        onPress={() => handlePress("end")}
        label={endLabel}
        value={endDate}
        onChange={(value) => onChange(startDate, value)}
        dateFormat={dateFormat}
        minDate={startDate}
        maxDate={min([subDays(addYears(startDate, 1), 1), Date.now()])}
      />
    </View>
  );
}

type DateInputProps = {
  testID?: string;
  dateFormat: Format;
  visible: boolean;
  label: string;
  maxDate?: Date;
  minDate?: Date;
  onChange(value: Date): void;
  onPress(): void;
  value: Date;
};

function DateInput(props: DateInputProps) {
  const {
    testID,
    dateFormat,
    visible,
    label,
    maxDate,
    minDate,
    onChange,
    onPress,
    value,
  } = props;

  const { format } = useLocalizedDateFormat();

  return (
    <Collapsible
      testID={appendTestId(testID, "collapsible")}
      visible={visible}
      onPress={onPress}
      contentStyle={Style.collapsibleContent}
      header={
        <View style={Style.collapsibleContent}>
          <Text variant="ui-tiny" ink="black-secondary">
            {label}
          </Text>
          <Text testID={appendTestId(testID, "value")} variant="ui-small">
            {format(value, dateFormat)}
          </Text>
        </View>
      }
    >
      <Calendar
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={onChange}
      />
    </Collapsible>
  );
}

const Style = StyleSheet.create({
  dateRange: {
    borderRadius: Token.borderRadius.normal,
    borderWidth: Token.borderWidth.thin,
    overflow: "hidden",
  },
  collapsibleHeader: {
    borderBottomWidth: 0,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
  },
  collapsibleContent: {
    padding: 0,
  },
  inputText: {
    flex: 1,
  },
});
