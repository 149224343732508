"use client";
import { useRef } from "react";

const MAX_VALUE = 36 ** 6;

export default function useId() {
  const ref = useRef<string>();
  if (!ref.current) {
    ref.current = Math.floor(Math.random() * MAX_VALUE).toString(36);
  }

  return ref.current;
}
