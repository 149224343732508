import { StyleProp, StyleSheet, ViewStyle } from "react-native";
import Button from "../Button";
import View from "../View";
import { Input } from "../form/Input";

import MinusIcon from "@traveloka/icon-kit-web/react/IcSymbolAddMinus16";
import PlusIcon from "@traveloka/icon-kit-web/react/IcSymbolAddPlus16";

import Token from "../Token";

type StepperProps = {
  size?: "small" | "medium";
  variant?: "formal" | "minimal";
  disabled?: boolean;
  error?: boolean;
  min?: number;
  max?: number;
  value: number;
  onChange?(value: number): void;
  style?: StyleProp<ViewStyle>;
};

const iconMap: Record<"small" | "medium", JSX.Element[]> = {
  small: [
    <MinusIcon
      key={1}
      color={Token.color.bluePrimary}
      width={12}
      height={12}
    />,
    <PlusIcon key={2} color={Token.color.bluePrimary} width={12} height={12} />,
    <MinusIcon
      key={3}
      color={Token.color.lightSecondary}
      width={12}
      height={12}
    />,
    <PlusIcon
      key={4}
      color={Token.color.lightSecondary}
      width={12}
      height={12}
    />,
  ],
  medium: [
    <MinusIcon
      key={1}
      color={Token.color.bluePrimary}
      width={16}
      height={16}
    />,
    <PlusIcon key={2} color={Token.color.bluePrimary} width={16} height={16} />,
    <MinusIcon
      key={3}
      color={Token.color.lightSecondary}
      width={16}
      height={16}
    />,
    <PlusIcon
      key={4}
      color={Token.color.lightSecondary}
      width={16}
      height={16}
    />,
  ],
};

function noop() {
  // does nothing
}

export default function Stepper(props: StepperProps) {
  const {
    size = "medium",
    variant,
    disabled = false,
    error,
    value,
    min = -Infinity,
    max = Infinity,
    onChange = noop,
    style,
  } = props;

  const minusDisabled = disabled || value <= min;
  const plusDisabled = disabled || value >= max;
  const [minusIcon, plusIcon, minusDisabledIcon, plusDisabledIcon] =
    iconMap[size];

  return (
    <View row align="center" spacing="xxs" style={style}>
      <Button
        variant="secondary"
        startIcon={minusDisabled ? minusDisabledIcon : minusIcon}
        disabled={minusDisabled}
        size={size}
        style={buttonStyles[size]}
        onPress={() => onChange(Math.max(min, value - 1))}
      />
      <Input
        editable={false}
        error={error}
        disabled={disabled}
        variant={variant}
        size={size}
        keyboardType="numeric"
        value={value.toString()}
        style={styles.input}
        inputStyle={styles.textInput}
      />
      <Button
        variant="secondary"
        startIcon={plusDisabled ? plusDisabledIcon : plusIcon}
        disabled={plusDisabled}
        size={size}
        style={buttonStyles[size]}
        onPress={() => onChange(Math.min(max, value + 1))}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    flex: 1,
  },
  textInput: {
    textAlign: "center",
  },
});

const buttonStyles = StyleSheet.create({
  small: { paddingVertical: 10 },
  medium: { paddingVertical: Token.spacing.s },
});
