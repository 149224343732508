import { Children, cloneElement, isValidElement, ReactNode } from "react";

import { useControlled } from "../hooks";
import View, { ViewProps } from "../View";

export type RadioGroupProps<T> = {
  children?: ReactNode;
  /**
   * The default `input` element value
   */
  defaultValue?: T;
  /**
   * Callback fired when a radio button is selected.
   */
  onChange?(value: T): void;
  /**
   * Value of the selected radio button
   */
  value?: T;
} & ViewProps;

export default function RadioGroup<T>(props: RadioGroupProps<T>) {
  const {
    children,
    defaultValue,
    onChange,
    value: valueProp,
    ...viewProps
  } = props;

  const [value, setValue] = useControlled({
    name: "RadioGroup",
    prop: "value",
    value: valueProp,
    defaultValue: defaultValue,
  });

  return (
    <View {...viewProps} accessibilityRole="radiogroup">
      {Children.map(children, (child, index) => {
        if (
          !isValidElement<
            RadioGroupProps<T> & {
              checked: boolean;
            }
          >(child)
        ) {
          return null;
        }

        const childValue = child.props.value ?? String(index);
        const childOnChange = child.props.onChange;

        return cloneElement(child, {
          checked: childValue === value,
          onChange(value: T) {
            setValue(value);
            if (typeof onChange === "function") onChange(value);
            if (typeof childOnChange === "function") childOnChange(value);
          },
        });
      })}
    </View>
  );
}
