import { ReactNode } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import Token, { Shadow, Spacing } from '../Token';
import View from '../View';

export type AppBarProps = {
  children?: ReactNode;
  elevation?: Shadow;
  style?: StyleProp<ViewStyle>;
  spacing?: Spacing | false;
};

export default function AppBar(props: AppBarProps) {
  const { children, spacing = 'm', style } = props;

  return (
    <View
      align="center"
      row
      spacing={spacing || undefined}
      style={[styles.root, style]}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: Token.color.godwitBlue,
    minHeight: 48,
    paddingHorizontal: Token.spacing.s,
    position: 'sticky' as any,
    top: 0,
    zIndex: Token.zIndex.appBar,
  },
});
