"use client";
// Taken from @traveloka/web-components/future
import { useCallback, useState } from "react";

import { LayoutChangeEvent } from "react-native";

export type LayoutRectangle = {
  x: number;
  y: number;
  width: number;
  height: number;
  hasFinishedLayout: boolean;
};

type Bindings = {
  onLayout: (layoutChange: LayoutChangeEvent) => void;
};

export default function useLayout(): [LayoutRectangle, Bindings] {
  const [layout, setLayout] = useState<LayoutRectangle>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    hasFinishedLayout: false,
  });

  const onLayout = useCallback((layoutChange: LayoutChangeEvent) => {
    const { x, y, width, height } = layoutChange.nativeEvent.layout;

    setLayout({ x, y, width, height, hasFinishedLayout: true });
  }, []);

  return [layout, { onLayout }];
}
