import { ChangeEvent } from "react";
import {
  // @ts-expect-error unstable_createElement does not exist in react-native typing, but exist in react-native-web
  unstable_createElement,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

import { useHover } from "../hooks";
import Knob from "../Knob";
import Token from "../Token";

export type SwitchProps = {
  accessibilityLabel?: string;
  /**
   * If true the user won't be able to toggle the switch.
   * @default false
   */
  disabled?: boolean;
  /**
   * If true the there will be a red ring in the knob
   * @default false
   */
  error?: boolean;
  /**
   * Invoked with the new value when the value changes.
   */
  onChange?(value: boolean): void;
  /**
   * The value of the switch. If true the switch will be turned on.
   * @default false
   */
  checked?: boolean;
  testID?: string;
};

export default function Switch(props: SwitchProps) {
  const {
    accessibilityLabel,
    disabled = false,
    onChange,
    checked = false,
    error,
    testID,
  } = props;

  // const [focused, focusHandler] = useFocusVisible();

  const [hovered, hoverHandler] = useHover();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (typeof onChange === "function") onChange(e.target.checked);
  }

  const input = unstable_createElement("input", {
    ...hoverHandler,
    // onBlur: handleFocusState,
    // onFocus: handleFocusState,
    accessibilityLabel,
    checked,
    disabled,
    onChange: handleChange,
    style: styles.input,
    type: "checkbox",
  });

  const rootStyle: StyleProp<ViewStyle> = [
    styles.root,
    disabled && styles.disabled,
  ];
  const trackStyle: StyleProp<ViewStyle> = [
    styles.track,
    checked && styles.trackChecked,
    error && styles.trackError,
    checked && error && styles.trackCheckedError,
    disabled && styles.trackDisabled,
  ];
  const thumbStyle: StyleProp<ViewStyle> = [
    styles.knob,
    checked && styles.knobChecked,
  ];

  return (
    <View nativeID={testID} style={rootStyle}>
      <View style={trackStyle} />
      <Knob
        disabled={disabled}
        error={error}
        hovered={hovered}
        style={thumbStyle}
      />
      {input}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    cursor: "pointer",
    height: 28,
    userSelect: "none",
    width: 48,
    justifyContent: "center",
    paddingVertical: Token.spacing.xxs,
  },
  disabled: {
    display: undefined,
    cursor: "default",
  },

  // Track
  track: {
    backgroundColor: Token.color.lightSecondary,
    borderRadius: Token.borderRadius.rounded,
    flex: 1,
    transitionDuration: `${Token.timing.instant}ms`,
    transitionProperty: "background-color",
  },
  trackDisabled: {
    backgroundColor: Token.color.lightNeutral,
  },
  trackChecked: {
    backgroundColor: Token.color.bluePrimary,
  },
  trackError: {
    backgroundColor: Token.color.redLight,
  },
  trackCheckedError: {
    backgroundColor: Token.color.redPrimary,
  },

  // Knob
  knob: {
    position: "absolute",
    start: 0,
  },
  knobChecked: {
    marginStart: -28,
    start: "100%",
  },

  input: {
    cursor: "inherit",
    height: "100%",
    inset: 0,
    margin: 0,
    opacity: 0,
    padding: 0,
    position: "absolute",
    width: "100%",
  },
});
