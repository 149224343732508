"use client";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import Button, { ButtonProps } from "../Button";
import { useLatest } from "../hooks";
import Portal from "../Portal";
import Text, { TextProps } from "../Text";
import Token from "../Token";
import Transition, { TransitionProps } from "../Transition";
import View from "../View";

type Variant =
  | "alert"
  | "alert-subtle"
  | "neutral-light"
  | "positive"
  | "positive-subtle"
  | "warning"
  | "warning-subtle";

type OmitTransitionProps = Pick<
  TransitionProps,
  "onEnter" | "onExited" | "visible"
>;

export type SnackbarProps = OmitTransitionProps & {
  /**
   * Array of actions that will be displayed.
   */
  action?: ReactElement | ReactElement[];
  onClose?(): void;
  hideDuration?: number;
  /**
   * Position of the snackbar relative to viewport
   * @default 'bottom'
   */
  position?: "top" | "bottom";
  /**
   * Message displayed
   */
  message: string;
  /**
   *
   */
  title?: string;
  /**
   * Defines look and feel of the component
   * @default 'neutral-light'
   */
  variant?: Variant;
  style?: StyleProp<ViewStyle>;
};

const textInkMap: Record<Variant, TextProps["ink"]> = {
  alert: "white-primary",
  "alert-subtle": "alert",
  "neutral-light": "black-primary",
  positive: "white-primary",
  "positive-subtle": "positive",
  warning: "black-primary",
  "warning-subtle": "warning",
};

const buttonVariantMap: Record<Variant, ButtonProps["variant"]> = {
  alert: "text-white",
  "alert-subtle": "text-black",
  "neutral-light": "text-black",
  positive: "text-white",
  "positive-subtle": "text-black",
  warning: "text-black",
  "warning-subtle": "text-black",
};

export default function Snackbar(props: SnackbarProps) {
  const {
    action,
    hideDuration,
    position = "bottom",
    message,
    title,
    variant = "neutral-light",
    visible,
    onEnter,
    onExited,
    style,
  } = props;

  const handler = useRef<number>();
  const onClose = useLatest(props.onClose);

  const [mount, setMount] = useState(false);

  useEffect(() => {
    if (visible) {
      setMount(true);

      if (typeof hideDuration === "number") {
        handler.current = window.setTimeout(() => {
          if (typeof onClose.current === "function") {
            onClose.current();
          }
        }, hideDuration);

        return () => window.clearTimeout(handler.current);
      }
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when visible, hideDuration changes
  }, [visible, hideDuration]);

  if (!mount) return null;

  const actions = Children.toArray(action);

  function handleExited() {
    setMount(false);
    if (typeof onExited === "function") onExited();
  }

  return (
    <Portal>
      <Transition
        onEnter={onEnter}
        onExited={handleExited}
        visible={visible}
        render={(state) => (
          <View
            spacing="xxs"
            style={[
              styles.root,
              styles[state],
              styles[position],
              variantStyles[variant],
              style,
            ]}
          >
            {typeof title === "string" && (
              <Text ink={textInkMap[variant]} variant="ui-tiny-bold">
                {title}
              </Text>
            )}
            <Text ink={textInkMap[variant]} variant="ui-small">
              {message}
            </Text>
            {actions.length > 0 && (
              <View row reverse spacing="m">
                {actions.map((action) => {
                  if (
                    !isValidElement<{
                      size: string;
                      variant?: ButtonProps["variant"];
                    }>(action) ||
                    action.type !== Button
                  ) {
                    return action;
                  }

                  return cloneElement(action, {
                    size: "small",
                    variant: buttonVariantMap[variant],
                  });
                })}
              </View>
            )}
          </View>
        )}
      />
    </Portal>
  );
}

const styles = StyleSheet.create({
  root: {
    position: "fixed" as any,
    left: Token.spacing.xs,
    right: Token.spacing.xs,
    zIndex: Token.zIndex.snackbar,
    boxShadow: Token.shadow.hover,
    borderRadius: Token.borderRadius.normal,
    paddingHorizontal: Token.spacing.s,
    paddingTop: Token.spacing.xs,
    transition: `${Token.timing.instant}ms transform`,
  },
  enter: {
    transform: [{ translateY: 0 }],
    animationTimingFunction: "ease-out",
  },
  exit: {
    transform: [{ translateY: "100%" as any }],
    animationTimingFunction: "ease-in",
  },
  top: { top: Token.spacing.xs },
  bottom: { bottom: Token.spacing.xs },
});

const variantStyles = StyleSheet.create({
  alert: {
    backgroundColor: Token.color.redSecondary,
  },
  "alert-subtle": {
    backgroundColor: Token.color.redLight,
  },
  "neutral-light": {
    backgroundColor: Token.color.lightStain,
  },
  positive: {
    backgroundColor: Token.color.greenSecondary,
  },
  "positive-subtle": {
    backgroundColor: Token.color.greenLight,
  },
  warning: {
    backgroundColor: Token.color.yellowPrimary,
  },
  "warning-subtle": {
    backgroundColor: Token.color.yellowLight,
  },
});
