import { StyleSheet, TouchableOpacity } from "react-native";

import Text from "../../Text";
import Token from "../../Token";
import useHover from "../../hooks/useHover";

type DropdownItemProps = {
  testID?: string;
  label: string;
  subLabel?: string;
  isActive: boolean;
  onPress: () => void;
};

export default function InputDropdownItem(props: DropdownItemProps) {
  const { testID, label, subLabel, isActive, onPress } = props;

  const [isHovered, event] = useHover();
  const hoverStyle = {
    backgroundColor: Token.color.lightStain,
  };

  return (
    <TouchableOpacity
      {...event}
      testID={testID}
      onPress={onPress}
      style={[ItemStyle.container, (isHovered || isActive) && hoverStyle]}
    >
      <Text>{label}</Text>
      {typeof subLabel === "string" && (
        <Text variant="ui-tiny" ink="black-secondary">
          {subLabel}
        </Text>
      )}
    </TouchableOpacity>
  );
}

const ItemStyle = StyleSheet.create({
  container: {
    cursor: "pointer",
    padding: Token.spacing.xs,
  },
});
