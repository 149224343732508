import Link from "next/link";
import { AnchorHTMLAttributes, DetailedHTMLProps } from "react";
import { StyleSheet } from "react-native";
import LocalizedLink from "../../core/locale/components/LocalizedLink";
import Text, { TextProps } from "../Text";

export type HtmrLinkProps = Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  "ref"
> &
  Pick<TextProps, "variant" | "ink"> & { localized?: boolean };

/**
 * Wrapper for LocalizedLink momentum component when using htmr library.
 * This component is created to parse anchor tags in content resource
 * value into LocalizedLink. You can also pass variant and ink props
 * in the anchor tag.
 */
export default function HtmrLink(props: HtmrLinkProps) {
  const {
    href,
    variant,
    localized,
    ink = "interactive",
    style,
    children,
    ...rest
  } = props;

  const LinkComponent = localized ? LocalizedLink : Link;
  const linkComponentStyle = { ...styles.link, ...style };

  return (
    <LinkComponent style={linkComponentStyle} {...rest} href={href ?? ""}>
      <Text variant={variant} ink={ink}>
        {children}
      </Text>
    </LinkComponent>
  );
}

const styles = StyleSheet.create({
  link: {
    textDecorationLine: "none",
  },
});
