import { StyleSheet, View } from "react-native";

import Text from "../Text";
import Token from "../Token";
import { getWeekdayNames } from "./utils";

export type CalendarWeekProps = {
  locale?: string;
};

export default function CalendarWeekday(props: CalendarWeekProps) {
  const { locale } = props;

  const names = getWeekdayNames(locale);

  return (
    <View style={styles.root}>
      {names.map((name) => (
        <Text
          key={name}
          ink="black-secondary"
          style={styles.weekday}
          variant="ui-tiny"
        >
          {name}
        </Text>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    borderBottomColor: Token.color.lightNeutral,
    borderBottomWidth: Token.borderWidth.thick,
    display: "grid" as any,
    gridTemplateColumns: "repeat(7, 1fr)",
    paddingTop: Token.spacing.xxs,
    paddingBottom: Token.spacing.xxs - 1,
  },
  weekday: {
    textAlign: "center",
  },
});
