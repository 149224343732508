import { useController, useFormContext } from "react-hook-form";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { createRhfRef } from "../../../utils";
import Token from "../../Token";
import View from "../../View";
import { Input, InputError, InputHelper, InputLabel } from "../Input";
import { FieldProps } from "../types";
import { InputFieldProps } from "./InputField";

type Props = FieldProps<string> &
  Omit<InputFieldProps, "value" | "defaultValue" | "onBlur"> & {
    style?: StyleProp<ViewStyle>;
    inputInnerStyle?: StyleProp<ViewStyle>;
    onBlur?(value: string, onChange: (event: any) => void): void;
  };

export default function InputRHF(props: Props) {
  const {
    name,
    validate,
    maxLength,
    defaultValue = "",
    onBlur: propsOnBlur,

    endHelper,
    helperId,
    inputStyle,
    inputInnerStyle,
    label,
    labelId,
    required,
    startHelper,
    style,
    ...inputProps
  } = props;

  const { disabled, nativeID, size } = inputProps;

  const { control } = useFormContext();
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({ control, name, defaultValue, rules: { validate } });

  function handleBlur() {
    onBlur();
    propsOnBlur?.(value, onChange);
  }

  const max = typeof maxLength !== "undefined" && maxLength > 0;
  const rightHelperText = max
    ? `${value?.length || 0}/${maxLength}`
    : undefined;

  return (
    <View spacing="xxs" style={[styles.root, style]}>
      {label && (
        <InputLabel
          disabled={disabled}
          inputId={nativeID}
          required={required}
          size={size}
          text={label}
        />
      )}
      <Input
        {...inputProps}
        ref={createRhfRef(ref)}
        value={value}
        onBlur={handleBlur}
        onChange={(e) => {
          onChange(e);
          props.onChange?.(e);
        }}
        error={isValidError(error?.message) || Boolean(error)}
        helperId={helperId}
        labelId={labelId}
        style={inputStyle}
        inputStyle={inputInnerStyle}
        maxLength={maxLength}
      />
      {isValidError(error?.message) && (
        <InputError nativeID={helperId} text={error!.message} />
      )}
      {(typeof endHelper === "string" ||
        typeof startHelper === "string" ||
        !!rightHelperText) && (
        <InputHelper
          disabled={disabled}
          endText={endHelper}
          nativeID={helperId}
          startText={startHelper || rightHelperText}
        />
      )}
    </View>
  );
}

function isValidError(error: string | boolean | undefined): error is string {
  return typeof error === "string" && error !== "";
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: Token.spacing.xs,
  },
});
