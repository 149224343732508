import {
  CSSProperties,
  DetailedHTMLProps,
  ImgHTMLAttributes,
  ReactEventHandler,
} from "react";

import Token from "../Token";

export type ImageProps = {
  /**
   * Provides alternative information for an image if a user
   * for some reason cannot view it.
   * Required for accessibility (a11y)
   */
  alt?: string;
  /**
   * Height of an image, in pixels or percentage
   */
  height?: number | string;
  // /**
  //  * https://wicg.github.io/priority-hints/
  //  * @default 'low'
  //  **/
  // importance?: "auto" | "high" | "low";
  // /**
  //  * https://web.dev/native-lazy-loading/
  //  * @default 'lazy'
  //  */
  // loading?: "auto" | "eager" | "lazy";
  /**
   * https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit
   * @default 'fill'
   */
  objectFit?: "fill" | "cover" | "contain" | "none" | "scale-down";
  /**
   * https://developer.mozilla.org/en-US/docs/Web/CSS/object-position
   * @default '50% 50%'
   */
  objectPosition?: string;
  /**
   * URL of the image
   */
  src: string;
  /**
   * Callback function if the image fails to load
   */
  onError?: ReactEventHandler;
  /**
   * Custom style applied to the root element
   */
  style?: CSSProperties;
  /**
   * Defines look and feel of the component
   * @default 'default'
   */
  variant?: "circle" | "default" | "rounded" | "top-rounded";
  /**
   * Width of an image, in pixels or percentage
   */
  width?: number | string;
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export default function Image(props: ImageProps) {
  const {
    alt,
    style,
    variant = "default",
    src,
    onError,
    width,
    height,
    objectFit,
    objectPosition,
    ...imageProps
  } = props;

  const additionalStyles = {
    objectFit,
    objectPosition,
  };

  return (
    // TODO: Ack on the eslint warning. Will refactor this by using the next/image.
    // But we can't use the percentage width and height if we are using the next/image.
    // Intentionally put the warning to remind us to refactor this.
    <img
      src={src}
      style={{
        ...styles[variant],
        ...additionalStyles,
        ...style,
      }}
      alt={alt}
      width={width}
      height={height}
      onError={onError}
      {...imageProps}
    />
  );
}

const styles = {
  // Variant
  default: {},
  rounded: {
    borderRadius: Token.borderRadius.normal,
  },
  circle: {
    borderRadius: Token.borderRadius.rounded,
  },
  "top-rounded": {
    borderTopLeftRadius: Token.borderRadius.normal,
    borderTopRightRadius: Token.borderRadius.normal,
  },
};
