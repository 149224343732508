"use client";
import { useEffect, useRef } from "react";

import { Animated, StyleSheet, View } from "react-native";

import { useHoverable } from "../../hooks/useHoverable";
import useLayout from "../../hooks/useLayout";
import useTransition from "../../hooks/useTransition";
import Absolute from "../Absolute/Absolute";
import Card from "../Card/Card";
import Text from "../Text/Text";
import Token from "../Token";

type TooltipVariant = "normal" | "info" | "alert";

type Position = "top" | "right" | "bottom" | "left";

type TooltipContentProps = {
  /**
   * Content shown on the tooltip, either accepts a string or a valid react node.
   */
  content: string | React.ReactNode;
  /**
   * Color variance of tooltip
   *
   * @default 'normal'
   */
  variant?: TooltipVariant;
  /**
   * Intended position of the tooltip,
   * @default 'top'
   */
  position?: Position;
  /**
   * Desired width of tooltip content,
   * 'auto' means it will follow the content width
   * 'stretchToChild' means it will follow the child width
   * any number means it will be set ot that value.
   *
   * @default 'auto'
   */
  width?: "auto" | "stretchToChild" | number;
  /**
   * Desired offset of the tooltip from its content's edge
   * i.e. if positioned on top, it will apply the offset value as a
   * margin from the top edge of the child to the bottom edge of the tooltip
   *
   * @default Token.spacing.xxs,
   */
  offset?: { x?: number; y?: number };
  /**
   * Controlled prop. The default behaviour of this tooltip is show-on-hover.
   * If you need to override this behaviour, use this prop.
   */
  show?: boolean;
  /**
   * This props is to cater cases where your tooltip is on top of a component that has zIndex
   */
  contentZIndex?: number;
};

type Props = {
  children: React.ReactNode;
} & TooltipContentProps;

// API Inspiration: https://ui.reach.tech/tooltip/
export default function Tooltip(props: Props) {
  const parentRef = useRef<any>();
  const { children, show, contentZIndex, ...tooltipContentProps } = props;

  const isControlled = typeof show === "boolean";

  const [isHovered, hoverEventBindings] = useHoverable();
  const [baseLayout, baseLayoutBindings] = useLayout();

  const shouldShowContent = isControlled ? show === true : isHovered;

  const absoluteStyle = contentZIndex ? { zIndex: contentZIndex } : {};

  return (
    <View ref={parentRef} {...hoverEventBindings} {...baseLayoutBindings}>
      {children}
      <Absolute style={absoluteStyle} base={parentRef}>
        <TooltipContent
          show={shouldShowContent}
          {...tooltipContentProps}
          baseWidth={baseLayout.width}
          baseHeight={baseLayout.height}
        />
      </Absolute>
    </View>
  );
}

type TooltipShowBehavior = "show" | "hide";

type TooltipShowBehaviorValueMap = { [key in TooltipShowBehavior]: any };

function TooltipContent(
  props: {
    show: boolean;
    baseWidth: number;
    baseHeight: number;
  } & TooltipContentProps
) {
  const {
    content,
    variant = "normal",
    position = "top",
    width = "auto",
    offset,
    show,
    baseWidth,
    baseHeight,
  } = props;

  const contentWidth = width === "stretchToChild" ? baseWidth : width;

  const [tooltipContentDimension, tooltipContentDimensionBindings] =
    useLayout();
  const variantColor = getVariantColor(variant, Token.color);

  const { setCurrentBehavior, interpolate } = useTransition<
    TooltipShowBehavior,
    TooltipShowBehaviorValueMap
  >("hide", Token.timing.instant);

  useEffect(() => {
    setCurrentBehavior(show ? "show" : "hide");
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when show changes
  }, [show]);

  return (
    <Animated.View
      style={[
        styles.tooltipContentWrapper,
        {
          maxHeight: show ? tooltipContentDimension.height : 0,
          overflow: show ? "visible" : "hidden",
          width: contentWidth,
          transform: [
            {
              [getInterpolatedAnimationTransformKey(position)]: interpolate(
                getInterpolatedAnimationValueMap(position)
              ),
            },
          ],
          opacity: interpolate({ show: 1, hide: 0 }),
        },
        applyOffset(
          getAbsolutePositioning(
            position,
            tooltipContentDimension.width,
            tooltipContentDimension.height,
            baseWidth,
            baseHeight
          ),
          offset
        ),
      ]}
    >
      {tooltipContentDimension.width !== 0 &&
        tooltipContentDimension.height !== 0 && (
          <View
            style={[
              styles.triangleWrapper,
              getArrowStyle(position, "triangleWrapper"),
              getArrowOffset(
                position,
                tooltipContentDimension.width,
                tooltipContentDimension.height
              ),
            ]}
          >
            <View
              style={[
                styles.triangle,
                getArrowStyle(position, "triangle"),
                {
                  borderBottomColor: variantColor,
                  borderRightColor: variantColor,
                },
              ]}
            />
          </View>
        )}
      <View {...tooltipContentDimensionBindings}>
        <Card
          elevation="float"
          style={[
            styles.tooltipCard,
            getWrapperOffset(position),
            { backgroundColor: variantColor },
          ]}
        >
          {typeof content === "string" && (
            <Text style={styles.tooltipText}>{content}</Text>
          )}
          {typeof content !== "string" && <View>{content}</View>}
        </Card>
      </View>
    </Animated.View>
  );
}

/*************************************/
/**                                 **/
/** Dynamic style builder functions **/
/**                                 **/
/*************************************/

// Determine positioning based on position preference (top/right/bottom/left)
// takes the position variant, the offset (distance from an edge),
// the width and height of the Tooltip content,
// and the width and height of the child content.
function getAbsolutePositioning(
  position: Position,
  width: number,
  height: number,
  baseWidth: number,
  baseHeight: number
) {
  const defaultDistance = Token.spacing.xxs;
  switch (position) {
    case "top":
      return {
        top: -1 * height - defaultDistance,
        left: (baseWidth - width) / 2,
      };
    case "right":
      return {
        top: (baseHeight - height) / 2,
        left: baseWidth + defaultDistance,
      };
    case "bottom":
      return {
        top: baseHeight + defaultDistance,
        left: (baseWidth - width) / 2,
      };
    case "left":
    default:
      return {
        top: (baseHeight - height) / 2,
        left: -1 * width - defaultDistance,
      };
  }
}

function applyOffset(
  basePosition: { top: number; left: number },
  offset: Props["offset"]
) {
  if (!offset) {
    return basePosition;
  }
  const { x = 0, y = 0 } = offset;
  return {
    top: basePosition.top - y,
    left: basePosition.left + x,
  };
}

// Determine which translate direction should be used in
// animating the transformation (slide from x axis or y axis)
function getInterpolatedAnimationTransformKey(
  position: Position
): "translateX" | "translateY" {
  switch (position) {
    case "top":
    case "bottom":
      return "translateY";
    case "left":
    case "right":
    default:
      return "translateX";
  }
}

// Determine the transformation styling should be used in
// animating the tooltip when shown or hidden (slide value)
function getInterpolatedAnimationValueMap(
  position: Position
): TooltipShowBehaviorValueMap {
  switch (position) {
    case "top":
    case "left":
      return {
        show: 0,
        hide: -1 * Token.spacing.m,
      };
    case "bottom":
    case "right":
    default:
      return {
        show: 0,
        hide: Token.spacing.m,
      };
  }
}

// get variant color, returns the color based on current theme
function getVariantColor(variant: TooltipVariant, color: typeof Token.color) {
  switch (variant) {
    case "alert":
      return color.redPrimary;
    case "info":
      return color.bluePrimary;
    default:
      return color.darkPrimary;
  }
}

// get the preferred arrowWrapper and arrow styling based on position
function getArrowStyle(position: Position, prefix: string) {
  switch (position) {
    case "top":
      return styles[`${prefix}Top`];
    case "right":
      return styles[`${prefix}Right`];
    case "bottom":
      return styles[`${prefix}Bottom`];
    case "left":
    default:
      return styles[`${prefix}Left`];
  }
}

// get the arrow offset necessary to make it center
// horizontally if it is a top or bottom positioned tooltip
// vertically if it is a left or right positioned tooltip
function getArrowOffset(position: Position, width: number, height: number) {
  if (position === "top" || position === "bottom") {
    return { left: (width - 20) / 2 };
  }

  return { top: (height - 20) / 2 };
}

// get offset values of the content wrapper to accomodate arrow placing
// based on arrow position
function getWrapperOffset(position: Position) {
  switch (position) {
    case "top":
      return { marginBottom: 9 };
    case "right":
      return { marginLeft: 9 };
    case "bottom":
      return { marginTop: 9 };
    case "left":
    default:
      return { marginRight: 9 };
  }
}

/*******************/
/**               **/
/** Static styles **/
/**               **/
/*******************/

const styles: { [key in string]: any } = StyleSheet.create({
  tooltipContentWrapper: {
    position: "absolute",
    zIndex: 500,
  },
  tooltipCard: {
    paddingHorizontal: Token.spacing.s,
    paddingVertical: Token.spacing.xs,
    borderRadius: Token.borderRadius.normal,
  },
  triangleWrapper: {
    position: "absolute",
    zIndex: -1,
  },
  triangleWrapperTop: {
    bottom: 10,
  },
  triangleWrapperBottom: {
    top: 0,
  },
  triangleWrapperLeft: {
    right: 10,
  },
  triangleWrapperRight: {
    left: 0,
  },
  triangle: {
    position: "absolute",
    width: 0,
    height: 0,
    borderWidth: 8,
    borderRadius: 2,
    borderTopColor: "transparent",
    borderLeftColor: "transparent",
    transformOrigin: "bottom right",
  },
  triangleTop: {
    left: -6,
    top: -5,
    transform: [
      {
        rotate: "45deg",
      },
    ],
  },
  triangleRight: {
    top: -6,
    left: -17,
    transform: [
      {
        rotate: "135deg",
      },
    ],
  },
  triangleBottom: {
    top: -17,
    left: -6,
    transform: [
      {
        rotate: "-135deg",
      },
    ],
  },
  triangleLeft: {
    top: -6,
    left: -5,
    transform: [
      {
        rotate: "-45deg",
      },
    ],
  },
  tooltipText: {
    textAlign: "center",
    color: Token.color.lightPrimary,
  },
});
