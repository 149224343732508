import { cloneElement, isValidElement, Children, ReactNode } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import Token from "../Token";
import View from "../View";

export type BottomBarProps<T> = {
  children?: ReactNode;
  onPress?(value: T): void;
  style?: StyleProp<ViewStyle>;
  value?: T;
};

export default function BottomBar<T>(props: BottomBarProps<T>) {
  const { children, value, onPress, style } = props;

  return (
    <View row style={[styles.root, style]}>
      {Children.toArray(children).map((child, index) => {
        if (
          !isValidElement<
            BottomBarProps<T> & {
              selected: boolean;
              value: string | NonNullable<T>;
            }
          >(child)
        ) {
          return null;
        }

        const childValue = child.props.value ?? String(index);

        return cloneElement(child, {
          onPress,
          selected: childValue === value,
          value: childValue,
        });
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: Token.color.lightPrimary,
    boxShadow: `0 1px 1px 0 inset ${Token.color.lightSecondary}`,
    minHeight: 48,
  },
});
