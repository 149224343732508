export const event = {
  CORPORATE_TRAVEL: "ctv.eventAction",
};

const pageName = {
  CTV_LANDING_PAGE: "CTV_LANDING_PAGE",
  CTV_LOGIN_PAGE: "CTV_LOGIN_PAGE",
  CTV_REGISTRATION_PAGE: "CTV_REGISTRATION_PAGE",
  CTV_ARTICLE_LIST_PAGE: "CTV_ARTICLE_LIST_PAGE",
  CTV_ARTICLE_PAGE: "CTV_ARTICLE_PAGE",
  CTV_FEATURE_PAGE: "CTV_FEATURE_PAGE",
  CTV_PRODUCT_PAGE: "CTV_PRODUCT_PAGE",
};

const actionName = {
  LOAD: "load",
  CLICK: "click",
  ERROR: "error",
  SCROLL: "scroll",
};

export const landingPageTracking = {
  LOAD_PAGE: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "page",
    actionCategory: "page",
    actionName: actionName.LOAD,
    nonInteraction: true,
  },
  USP_SECTION: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "usp_section",
    actionCategory: "usp_section",
    actionName: actionName.LOAD,
    nonInteraction: false,
  },
  FEATURE_SECTION: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "feature_section",
    actionCategory: "feature_section",
    actionName: actionName.LOAD,
    nonInteraction: false,
  },
  ARTICLE_SECTION: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "article_section",
    actionCategory: "article_section",
    actionName: actionName.LOAD,
    nonInteraction: false,
  },
  VIDEO_SECTION: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "video_section",
    actionCategory: "video_section",
    actionName: actionName.LOAD,
    nonInteraction: false,
  },
  FOOTER_SECTION: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "footer_section",
    actionCategory: "footer_section",
    actionName: actionName.LOAD,
    nonInteraction: false,
  },
  TRAVELOKA_FOR_CORPORATE_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "traveloka_for_corporate_button",
    actionCategory: "traveloka_for_corporate_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  FEATURE_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "feature_button",
    actionCategory: "feature_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  PRODUCT_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "product_button",
    actionCategory: "product_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  ARTICLE_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "article_button",
    actionCategory: "article_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  LOG_IN_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "log_in_button",
    actionCategory: "log_in_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  SIGN_UP_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "sign_up_button",
    actionCategory: "sign_up_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  FEATURE_SELECT_BUTTON: (featureName: string) => ({
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "feature_select_button",
    actionCategory: "feature_select_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
    actionLabel: { featureName },
  }),
  LEARN_MORE_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "learn_more_button",
    actionCategory: "learn_more_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  SERVICE_OFFER_SIGN_UP_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "service_offer_sign_up_button",
    actionCategory: "service_offer_sign_up_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  FEATURE_COMPARISON_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "feature_comparison_button",
    actionCategory: "feature_comparison_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  READ_ARTICLE: (
    articleTitle: string,
    articleDate: number,
    articleOrder: number
  ) => ({
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "read_article",
    actionCategory: "read_article",
    actionName: actionName.CLICK,
    nonInteraction: false,
    actionLabel: { articleTitle, articleDate, articleOrder },
  }),
  BROWSE_MORE_ARTICLE: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "browse_more_article",
    actionCategory: "browse_more_article",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  MERCHANT_LENDING_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "merchant_lending_button",
    actionCategory: "merchant_lending_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  /**
   * Desktop only
   */
  IM_INTERESTED_BUTTON: (emailName: string) => ({
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "im_interested_button",
    actionCategory: "im_interested_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
    actionLabel: { emailName },
  }),
  ARRANGE_A_MEETING_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "arrange_a_meeting_button",
    actionCategory: "arrange_a_meeting_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  /**
   * Mobile only
   */
  LOG_IN_HERO_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "log_in_hero_button",
    actionCategory: "log_in_hero_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  /**
   * Mobile only
   */
  SIGN_UP_HERO_BUTTON: {
    pageName: pageName.CTV_LANDING_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "sign_up_hero_button",
    actionCategory: "sign_up_hero_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
};

export const loginPageTracking = {
  PAGE: {
    pageName: pageName.CTV_LOGIN_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "page",
    actionCategory: "page",
    actionName: actionName.LOAD,
    nonInteraction: true,
  },
  LOG_IN_BUTTON: (emailName: string) => ({
    pageName: pageName.CTV_LOGIN_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "log_in_button",
    actionCategory: "log_in_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
    actionlabel: { emailName },
  }),
  LOG_IN_BUTTON_ERROR: (emailName: string, errorMessage: string) => ({
    pageName: pageName.CTV_LOGIN_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "log_in_button",
    actionCategory: "log_in_button",
    actionName: actionName.ERROR,
    nonInteraction: true,
    actionLabel: { emailName, errorMessage },
  }),
  FORGOT_PASSWORD_BUTTON: (emailName: string) => ({
    pageName: pageName.CTV_LOGIN_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "forgot_password_button",
    actionCategory: "forgot_password_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
    actionlabel: { emailName },
  }),
};

export const registrationPageTracking = {
  PAGE: {
    pageName: pageName.CTV_REGISTRATION_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "page",
    actionCategory: "page",
    actionName: actionName.LOAD,
    nonInteraction: true,
  },
  REGISTER_BUTTON: (emailName: string) => ({
    pageName: pageName.CTV_REGISTRATION_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "register_button",
    actionCategory: "register_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
    actionlabel: { emailName },
  }),
  REGISTER_BUTTON_ERROR: (emailName: string, errorMessage: string) => ({
    pageName: pageName.CTV_REGISTRATION_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "register_button",
    actionCategory: "register_button",
    actionName: actionName.ERROR,
    nonInteraction: true,
    actionLabel: { emailName, errorMessage },
  }),
};

export const articleListPageTracking = {
  PAGE: {
    pageName: pageName.CTV_ARTICLE_LIST_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "page",
    actionCategory: "page",
    actionName: actionName.LOAD,
    nonInteraction: true,
  },
  ARTICLE: (
    articleTitle: string,
    articleDate: number,
    articleOrder: number
  ) => ({
    pageName: pageName.CTV_ARTICLE_LIST_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "article",
    actionCategory: "article",
    actionName: actionName.CLICK,
    nonInteraction: false,
    actionLabel: { articleTitle, articleDate, articleOrder },
  }),
  PAGE_NAVIGATION_BUTTON: (pageNumber: number) => ({
    pageName: pageName.CTV_ARTICLE_LIST_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "page_navigation_button",
    actionCategory: "page_navigation_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
    actionLabel: { pageNumber },
  }),
  TRAVELOKA_FOR_CORPORATE_BUTTON: {
    pageName: pageName.CTV_ARTICLE_LIST_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "traveloka_for_corporate_button",
    actionCategory: "traveloka_for_corporate_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  FEATURE_BUTTON: {
    pageName: pageName.CTV_ARTICLE_LIST_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "feature_button",
    actionCategory: "feature_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  PRODUCT_BUTTON: {
    pageName: pageName.CTV_ARTICLE_LIST_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "product_button",
    actionCategory: "product_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  ARTICLE_BUTTON: {
    pageName: pageName.CTV_ARTICLE_LIST_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "article_button",
    actionCategory: "article_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  LOG_IN_BUTTON: {
    pageName: pageName.CTV_ARTICLE_LIST_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "log_in_button",
    actionCategory: "log_in_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  SIGN_UP_BUTTON: {
    pageName: pageName.CTV_ARTICLE_LIST_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "sign_up_button",
    actionCategory: "sign_up_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
};

export const articlePageTracking = {
  PAGE: (articleTitle: string, articleDate: number) => ({
    pageName: pageName.CTV_ARTICLE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "page",
    actionCategory: "page",
    actionName: actionName.LOAD,
    nonInteraction: true,
    actionLabel: { articleTitle, articleDate },
  }),
  ARTICLE: (
    articleTitle: string,
    articleDate: number,
    articleOrder: number
  ) => ({
    pageName: pageName.CTV_ARTICLE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "article",
    actionCategory: "article",
    actionName: actionName.CLICK,
    nonInteraction: false,
    actionLabel: { articleTitle, articleDate, articleOrder },
  }),
  TRAVELOKA_FOR_CORPORATE_BUTTON: {
    pageName: pageName.CTV_ARTICLE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "traveloka_for_corporate_button",
    actionCategory: "traveloka_for_corporate_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  FEATURE_BUTTON: {
    pageName: pageName.CTV_ARTICLE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "feature_button",
    actionCategory: "feature_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  PRODUCT_BUTTON: {
    pageName: pageName.CTV_ARTICLE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "product_button",
    actionCategory: "product_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  ARTICLE_BUTTON: {
    pageName: pageName.CTV_ARTICLE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "article_button",
    actionCategory: "article_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  LOG_IN_BUTTON: {
    pageName: pageName.CTV_ARTICLE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "log_in_button",
    actionCategory: "log_in_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  SIGN_UP_BUTTON: {
    pageName: pageName.CTV_ARTICLE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "sign_up_button",
    actionCategory: "sign_up_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
};

export const featurePageTracking = {
  PAGE: {
    pageName: pageName.CTV_FEATURE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "page",
    actionCategory: "page",
    actionName: actionName.LOAD,
    nonInteraction: true,
  },
  SIGN_UP_COMPARISON_BUTTON: {
    pageName: pageName.CTV_FEATURE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "sign_up_comparison_button",
    actionCategory: "sign_up_comparison_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  BIZCREDIT_BUTTON: {
    pageName: pageName.CTV_FEATURE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "bizcredit_button",
    actionCategory: "bizcredit_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  TRAVELOKA_FOR_CORPORATE_BUTTON: {
    pageName: pageName.CTV_FEATURE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "traveloka_for_corporate_button",
    actionCategory: "traveloka_for_corporate_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  FEATURE_BUTTON: {
    pageName: pageName.CTV_FEATURE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "feature_button",
    actionCategory: "feature_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  PRODUCT_BUTTON: {
    pageName: pageName.CTV_FEATURE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "product_button",
    actionCategory: "product_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  ARTICLE_BUTTON: {
    pageName: pageName.CTV_FEATURE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "article_button",
    actionCategory: "article_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  LOG_IN_BUTTON: {
    pageName: pageName.CTV_FEATURE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "log_in_button",
    actionCategory: "log_in_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  SIGN_UP_BUTTON: {
    pageName: pageName.CTV_FEATURE_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "sign_up_button",
    actionCategory: "sign_up_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
};

export const productPageTracking = {
  PAGE: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "page",
    actionCategory: "page",
    actionName: actionName.LOAD,
    nonInteraction: true,
  },
  AIRLINE_PARTNER: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "airline_partner",
    actionCategory: "airline_partner",
    actionName: actionName.SCROLL,
    nonInteraction: false,
  },
  HOTEL_PARTNER: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "hotel_partner",
    actionCategory: "hotel_partner",
    actionName: actionName.SCROLL,
    nonInteraction: false,
  },
  SIGN_UP_BANNER: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "sign_up_banner",
    actionCategory: "sign_up_banner",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  TRAVELOKA_FOR_CORPORATE_BUTTON: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "traveloka_for_corporate_button",
    actionCategory: "traveloka_for_corporate_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  FEATURE_BUTTON: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "feature_button",
    actionCategory: "feature_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  PRODUCT_BUTTON: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "product_button",
    actionCategory: "product_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  ARTICLE_BUTTON: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "article_button",
    actionCategory: "article_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  LOG_IN_BUTTON: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "log_in_button",
    actionCategory: "log_in_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
  SIGN_UP_BUTTON: {
    pageName: pageName.CTV_PRODUCT_PAGE,
    eventName: event.CORPORATE_TRAVEL,
    pageCategory: "sign_up_button",
    actionCategory: "sign_up_button",
    actionName: actionName.CLICK,
    nonInteraction: false,
  },
};
