import { ReactElement, forwardRef } from "react";
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";

import { TextProps } from "../../Text/Text";
import Token from "../../Token/Token";
import View from "../../View/View";
import Input, { InputProps, InputRef } from "../Input/Input";
import InputError from "../Input/InputError";
import InputHelper from "../Input/InputHelper";
import InputLabel from "../Input/InputLabel";

type TrimmedInputProps = Omit<InputProps, "error" | "style">;

export type InputFieldProps = {
  endHelper?: string;
  error?: string | boolean;
  helperId?: string;
  helperStyle?: StyleProp<TextStyle>;
  helperText?: ReactElement;
  inputStyle?: InputProps["inputStyle"];
  label?: string;
  labelStyle?: StyleProp<TextStyle>;
  labelId?: string;
  labelVariant?: TextProps["variant"];
  required?: boolean;
  startHelper?: string;
  style?: StyleProp<ViewStyle>;
  rootRef?: React.Ref<InputRef>;
} & TrimmedInputProps;

export default forwardRef<InputRef, InputFieldProps>(function InputField(
  props,
  ref
) {
  const {
    endHelper,
    error,
    helperId,
    helperStyle,
    helperText,
    inputStyle,
    label,
    labelStyle,
    labelVariant,
    labelId,
    required,
    startHelper,
    style,
    rootRef,
    ...inputProps
  } = props;

  const { disabled, nativeID, size } = inputProps;

  return (
    <View ref={rootRef} spacing="xxs" style={[styles.root, style]}>
      {label && (
        <InputLabel
          style={labelStyle}
          disabled={disabled}
          labelVariant={labelVariant}
          inputId={nativeID}
          required={required}
          size={size}
          text={label}
        />
      )}
      <Input
        {...inputProps}
        ref={ref}
        error={isValidError(error) || Boolean(error)}
        helperId={helperId}
        labelId={labelId}
        style={inputStyle}
      />
      {isValidError(error) ? (
        <InputError nativeID={helperId} text={error} />
      ) : (
        helperText
      )}
      {(typeof endHelper === "string" || typeof startHelper === "string") && (
        <InputHelper
          style={helperStyle}
          disabled={disabled}
          endText={endHelper}
          nativeID={helperId}
          startText={startHelper}
        />
      )}
    </View>
  );
});

function isValidError(error: string | boolean | undefined): error is string {
  return typeof error === "string" && error !== "";
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: Token.spacing.xs,
  },
});
