"use client";
import { useEffect } from "react";
import { StyleSheet } from "react-native";

import { useForceRender } from "../../../hooks";
import Token from "../../Token/Token";
import View from "../../View/View";
import AZScrollBarItem from "./AZScrollBarItem";
import { AZScrollBarProps } from "./types";

export default function AZScrollBar(props: AZScrollBarProps) {
  const { activeLetters, ...restProps } = props;
  const forceRender = useForceRender();

  useEffect(() => {
    // To forcefully re-render the component
    // Because the disabled is tightly coupled with useRef, which naturally doesn't re-render the component
    forceRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  return (
    <View style={styles.azContainer}>
      {azList.map((value, index) => (
        <AZScrollBarItem
          disabled={!activeLetters?.includes(value.toUpperCase())}
          key={value}
          {...restProps}
          value={value}
          index={index}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  azContainer: {
    paddingVertical: Token.spacing.s,
    borderRightColor: Token.color.borderSubtle,
    borderRightWidth: Token.borderWidth.thin,
  },
  azGroup: {
    paddingHorizontal: Token.spacing.s,
    paddingTop: Token.spacing.s,
    paddingBottom: Token.spacing.xs,
    borderBottomColor: Token.color.borderSubtle,
    borderBottomWidth: Token.borderWidth.thin,
  },
  odd: {
    backgroundColor: Token.color.lightStain,
  },
});

export const azList = [...Array(26).keys()].map((i) =>
  String.fromCharCode(i + 65)
);
