import { StyleSheet } from "react-native";

import useHover from "../../hooks/useHover";
import Text from "../../Text/Text";
import Token from "../../Token/Token";
import { AZScrollBarProps } from "./types";

type Props = {
  index: number;
  value: string;
  disabled?: boolean;
};
export default function AZScrollBarItem(props: AZScrollBarProps & Props) {
  const { azRef, disabled, value, index, onScroll } = props;

  const [hovered, handlers] = useHover();

  function scrollToAZ(index: number) {
    onScroll?.(azRef.current[index] ?? 0);
  }

  return (
    <Text
      key={value}
      style={[styles.text, !disabled && hovered ? styles.hover : undefined]}
      variant="ui-tiny"
      ink={disabled ? "black-muted" : "black-primary"}
      onPress={disabled ? undefined : () => scrollToAZ(index)}
      disabled={disabled}
      {...handlers}
    >
      {value}
    </Text>
  );
}

const styles = StyleSheet.create({
  text: {
    flex: 1,
    paddingVertical: Token.spacing.xxs,
    paddingHorizontal: Token.spacing.s,
    textAlign: "center",
  },
  hover: {
    transform: [{ scale: 1.3 }],
    transition: "transform 0.2s",
  },
});
