import { StyleSheet, View } from "react-native";

import Token from "../Token";

export type RadioDiscProps = {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  hovered?: boolean;
  /**
   * Defines size of the element
   * @default 'medium'
   */
  size?: "small" | "medium" | "large";
};

export default function RadioDisc(props: RadioDiscProps) {
  const { checked, disabled, error, hovered, size = "medium" } = props;

  const rootStyle = [
    styles.root,
    styles[size],
    !checked && [
      hovered && styles.hovered,
      error && styles.error,
      disabled && styles.disabled,
    ],
    checked && [
      styles.checked,
      hovered && styles.checkedHovered,
      error && styles.checkedError,
      hovered && error && styles.checkedErrorHovered,
      disabled && styles.checkedDisabled,
    ],
  ];

  const innerStyle = [
    innerStyles.root,
    !disabled && checked && innerStyles.checked,
    innerStyles[size],
  ];

  return (
    <View style={rootStyle}>
      <View style={innerStyle} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    backgroundColor: Token.color.lightPrimary,
    borderColor: Token.color.darkSecondary,
    borderRadius: Token.borderRadius.rounded,
    cursor: "pointer",
    justifyContent: "center",
    userSelect: "none",
  },
  disabled: {
    backgroundColor: Token.color.lightPrimary,
    borderColor: Token.color.lightSecondary,
    cursor: "default",
  },
  error: {
    borderColor: Token.color.redPrimary,
  },
  hovered: {
    backgroundColor: Token.color.lightNeutral,
  },

  checked: {
    backgroundColor: Token.color.bluePrimary,
    borderWidth: 0,
  },
  checkedError: {
    backgroundColor: Token.color.redPrimary,
  },
  checkedDisabled: {
    backgroundColor: Token.color.lightNeutral,
    cursor: "default",
  },
  checkedHovered: {
    backgroundColor: Token.color.blueSecondary,
  },
  checkedErrorHovered: {
    backgroundColor: Token.color.redSecondary,
  },

  // Size
  small: {
    borderWidth: Token.borderWidth.thick,
    height: 16,
    width: 16,
  },
  medium: {
    borderWidth: 1.5,
    height: 20,
    width: 20,
  },
  large: {
    borderWidth: Token.borderWidth.bold,
    height: 28,
    width: 28,
  },
});

const innerStyles = StyleSheet.create({
  root: {
    borderColor: Token.color.lightSecondary,
    borderRadius: Token.borderRadius.rounded,
  },
  checked: {
    borderColor: Token.color.lightPrimary,
  },

  // Size
  small: {
    borderWidth: 1.5,
    width: 8,
    height: 8,
  },
  medium: {
    borderWidth: Token.borderWidth.bold,
    width: 10,
    height: 10,
  },
  large: {
    borderWidth: Token.borderWidth.bold,
    width: 14,
    height: 14,
  },
});
