import { ReactElement } from "react";

import IntlMessageformat from "intl-messageformat";
import { CurrencyValue } from "./currency";

export function formatMessage(
  entry: string,
  params?: Record<string, string | number | ReactElement>
) {
  try {
    return new IntlMessageformat(entry, undefined, undefined, {
      ignoreTag: true,
    }).format(params) as string;
  } catch (err) {
    console.error("Missing content resource variable passed", { err });
    return entry;
  }
}

const DECIMAL_NUMBER_MAP: Record<string, number> = {
  IDR: 0,
  VND: 0,
};

export function formatCurrency(currencyValue: CurrencyValue) {
  const { amount, currency, decimalPoints } = currencyValue;
  const overrideDecimal = DECIMAL_NUMBER_MAP[currency] ?? decimalPoints;

  const formatter = new Intl.NumberFormat("id-ID", {
    currency,
    minimumFractionDigits: overrideDecimal,
    maximumFractionDigits: overrideDecimal,
  });
  // convert &nbsp; into space
  const formattedAmount = formatter.format(amount).replace("\u00a0", " ");

  if (currency === "THB") {
    return `${currency} ${formattedAmount}`;
  }

  if (currency === "VND") {
    return `${formattedAmount} ${currency}`;
  }

  // Fallback Format
  const defaultFormatter = new Intl.NumberFormat("id-ID", {
    currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: overrideDecimal,
    maximumFractionDigits: overrideDecimal,
    style: "currency",
  });
  // convert &nbsp; into space
  return defaultFormatter.format(amount).replace("\u00a0", " ");
}
