import { Pressable, StyleSheet } from "react-native";

import Text, { TextProps } from "../Text";
import Token from "../Token";
import { CELL_HEIGHT } from "./constants";

export type CalendarCellProps = {
  accessibilityLabel?: string;
  date: Date;
  decoration?: "today" | "selection";
  disabled?: boolean;
  onChange?(date: Date): void;
};

export default function CalendarCell(props: CalendarCellProps) {
  const { accessibilityLabel, date, decoration, disabled, onChange } = props;

  const selected = decoration === "selection";

  let mainTextInk: TextProps["ink"] = "black-primary";
  if (disabled) {
    mainTextInk = "black-muted";
  }

  const dateStyle = [
    styles.date,
    decoration === "today" && styles.dateToday,
    selected && styles.dateSelected,
  ];

  function handleChange() {
    if (typeof onChange === "function") onChange(date);
  }

  return (
    <Pressable
      accessibilityLabel={accessibilityLabel}
      disabled={disabled}
      onPress={handleChange}
      style={styles.cell}
    >
      <Text ink={mainTextInk} style={dateStyle} variant="ui-small">
        {date.getDate()}
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  cell: {
    alignItems: "center",
    justifyContent: "center",
    minHeight: CELL_HEIGHT,
  },
  date: {
    marginVertical: 3,
    minWidth: 15,
    textAlign: "center",
    zIndex: 1,
  },
  dateToday: {
    borderBottomColor: Token.color.blueSecondary,
    borderBottomWidth: Token.borderWidth.bold,
    lineHeight: 18,
  },
  dateSelected: {
    backgroundColor: Token.color.blueLight,
    borderRadius: Token.borderRadius.rounded,
    boxShadow: `${Token.shadow.raised}, 0 0 0 2px rgba(1, 148, 243, 0.5)`,
    lineHeight: 24,
    marginVertical: 1,
    minWidth: 32,
  },
});
