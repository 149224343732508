import { StyleProp, StyleSheet, TextStyle } from "react-native";

import isReactText from "../../../utils/isReactText";
import Text from "../../Text";
import View from "../../View";

export type InputHelperProps = {
  disabled?: boolean;
  endText?: string;
  nativeID?: string;
  startText?: string;
  style?: StyleProp<TextStyle>;
};

export default function InputHelper(props: InputHelperProps) {
  const { disabled, endText, nativeID, startText, style } = props;
  const ink = disabled ? "black-muted" : "black-secondary";

  return (
    <View nativeID={nativeID} align="baseline" spacing="xs">
      {isReactText(startText) && (
        <Text
          ellipsizeMode="tail"
          ink={ink}
          numberOfLines={3}
          style={[styles.startText, style]}
          variant="ui-tiny"
        >
          {startText}
        </Text>
      )}
      {isReactText(endText) && (
        <Text
          ellipsizeMode="tail"
          ink={ink}
          numberOfLines={3}
          style={[styles.endText, style]}
          variant="ui-tiny"
        >
          {endText}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  startText: {
    flex: 1,
  },
  endText: {
    marginStart: "auto",
    textAlign: "right",
  },
});
