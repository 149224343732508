import { useController, useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";

import { FieldProps } from "../form/types";
import Switch from "./Switch";

type SwitchRHFProps = {
  onChange?: (value: boolean) => void;
  /**
   * @default false
   */
  disabled?: boolean;
  /**
   * @default false
   */
  testID?: string;
  style?: StyleProp<ViewStyle>;
};

type Props = FieldProps<boolean> & SwitchRHFProps;

export default function SwitchRHF(props: Props) {
  const { name, validate, onChange, style, defaultValue, ...switchFieldProps } =
    props;

  const { control } = useFormContext();
  const {
    field: { value, onChange: onChangeField },
  } = useController({
    control,
    name,
    rules: {
      validate,
    },
    defaultValue,
  });

  function handleChange(value: boolean) {
    onChangeField(value);
    onChange && onChange(value);
  }

  return (
    <View style={style}>
      <Switch
        {...switchFieldProps}
        onChange={handleChange}
        checked={value ?? false}
      />
    </View>
  );
}
