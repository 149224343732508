// ===== BASIC
// This will later used as SBF validation.
// Do not change the params
export function required(value: unknown) {
  if (typeof value === "string" || Array.isArray(value)) {
    return value.length > 0;
  } else if (typeof value === "boolean") {
    return value;
  }

  return false;
}

export function minLength(value: unknown, min: number) {
  if (typeof value === "string" || Array.isArray(value)) {
    return value.length >= min;
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).length >= min;
  }

  return false;
}

export function maxLength(value: unknown, max: number) {
  if (typeof value === "string" || Array.isArray(value)) {
    return value.length <= max;
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).length <= max;
  }

  return false;
}

export function regex(value: unknown, regex: RegExp) {
  return regex.test(String(value));
}

export function minValue(value: unknown, min: number) {
  const num = parseInt(String(value), 10);

  return !isNaN(num) && num >= min;
}

export function maxValue(value: unknown, max: number) {
  const num = parseInt(String(value), 10);

  return !isNaN(num) && num <= max;
}

// ===== NAMED
export function isCorporateName(value: unknown, ignoreLastSpace = false) {
  if (ignoreLastSpace) {
    return regex(value, /^[a-zA-Z0-9.,\-() ]*[a-zA-Z0-9.,\-()]+\s*$/);
  }

  return regex(value, /^[a-zA-Z0-9.,\-() ]*[a-zA-Z0-9.,\-()]+$/);
}

export function isName(value: unknown, ignoreLastSpace = false) {
  if (ignoreLastSpace) {
    return regex(value, /^[a-zA-Z ]*[a-zA-Z]+\s*$/);
  }

  return regex(value, /^[a-zA-Z ]*[a-zA-Z]+$/);
}

export function isDescription(value: unknown) {
  return regex(value, /^[a-zA-Z0-9 \-,.+/&_@#()'\n]*$/);
}

export function isAlphaNumericSpace(value: unknown) {
  return regex(value, /^[a-zA-Z0-9 ]*$/);
}

export function isNumeric(value: unknown, ignoreLastSpace = false) {
  if (ignoreLastSpace) {
    return regex(value, /^[0-9]+\s*$/);
  }

  return regex(value, /^[0-9]+$/);
}

export function isAlphaNumeric(value: unknown) {
  return regex(value, /^[a-zA-Z0-9]+$/);
}

export function isPhone(value: unknown, ignoreLastSpace = false) {
  if (ignoreLastSpace) {
    return regex(value, /^[0-9]+\s*$/);
  }

  return regex(value, /^[0-9]+$/);
}

export function isEmail(value: unknown, ignoreLastSpace = false) {
  if (ignoreLastSpace) {
    return regex(
      value,
      /^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z0-9-]{2,63})\s*$/
    );
  }

  return regex(
    value,
    /^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z0-9-]{2,63})$/
  );
}

export function isAddress(value: unknown) {
  return regex(value, /^[A-Za-z0-9'.\-\s,/]*$/);
}

export function isUrl(value: unknown, ignoreLastSpace = false) {
  if (ignoreLastSpace) {
    return regex(
      value,
      /^(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)\s*$/
    );
  }

  return regex(
    value,
    /^(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$/
  );
}
