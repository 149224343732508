import { DetailedHTMLProps, HTMLAttributes, TdHTMLAttributes } from "react";

import styles from "./tableSeo.module.css";

export function TableSeo(props: HTMLAttributes<HTMLTableElement>) {
  return (
    <table {...props} className={`${styles.table} ${props.className}`}>
      {props.children}
    </table>
  );
}

export function ThSeo(props: HTMLAttributes<HTMLTableCellElement>) {
  return (
    <th {...props} className={`${styles.th} ${props.className}`}>
      {props.children}
    </th>
  );
}

export function TrSeo(props: HTMLAttributes<HTMLTableRowElement>) {
  return (
    <tr {...props} className={`${styles.tr} ${props.className}`}>
      {props.children}
    </tr>
  );
}

export function TdSeo(
  props: DetailedHTMLProps<
    TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  >
) {
  return (
    <td {...props} className={`${styles.td} ${props.className}`}>
      {props.children}
    </td>
  );
}
