import { Locale } from "../constants";

export function getLocaleByUrl(): Locale {
  try {
    // window.location.pathname -> /en-id/login
    const rawUrlLocale = window.location.pathname.split("/")[1];
    return isLocale(rawUrlLocale) ? rawUrlLocale : Locale.ENID;
  } catch (_e) {
    return Locale.ENID;
  }
}

export function isLocale(str: string): str is Locale {
  return /^[a-z]{2}-[a-z]{2}$/.test(str);
}

export function hasSupportedLocales(path?: string) {
  if (!path) {
    return false;
  }

  return (Object.keys(Locale) as Array<keyof typeof Locale>)
    .map((locale) => Locale[locale])
    .some((locale) => path.includes(locale));
}
