import { StyleSheet } from "react-native";

import Token from "../Token/Token";
import View from "../View/View";

type Props = {
  active: boolean;
  dimension?: {
    topSpace: number;
    leftSpace: number;
    height: number;
    width: number;
  };
};

export default function HighlightElement(props: Props) {
  const { active, dimension } = props;

  if (!dimension) return null;

  return (
    <View
      style={[
        styles.highlightBorder,
        active && styles.active,
        {
          top: dimension.topSpace,
          left: dimension.leftSpace,
          height: dimension.height,
          width: dimension.width,
        },
      ]}
    />
  );
}

const styles = StyleSheet.create({
  highlightBorder: {
    position: "absolute",
    zIndex: 1001,
    borderRadius: Token.borderRadius.normal,
    transition: "opacity 0.3s ease-out",
    opacity: 0,

    // ref: keyframes animation on rnw: https://github.com/necolas/react-native-web/issues/2387
    animationKeyframes: [
      {
        "0%": {
          backgroundPosition: "left top, right bottom, left bottom, right top",
        },
        "100%": {
          backgroundPosition:
            "left 15px top, right 15px bottom , left bottom 15px , right top 15px",
        },
      },
    ],
    animationDuration: "1s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
    animationPlayState: "running",

    // ref: border animation: https://stackoverflow.com/a/52963366/7552329
    backgroundImage: `linear-gradient(90deg, ${Token.color.highAltitude} 50%, transparent 50%), linear-gradient(90deg, ${Token.color.highAltitude} 50%, transparent 50%), linear-gradient(0deg, ${Token.color.highAltitude} 50%, transparent 50%), linear-gradient(0deg, ${Token.color.highAltitude} 50%, transparent 50%)`,
    backgroundRepeat: "repeat-x, repeat-x, repeat-y, repeat-y",
    backgroundSize: "15px 3px, 15px 3px, 3px 15px, 3px 15px",
    backgroundPosition: "left top, right bottom, left bottom, right top",
  },
  active: {
    opacity: 1,
  },
});
