"use client";
import { useCallback, useEffect, useRef } from "react";

import useLatest from "./useLatest";

export default function useDebounceFn<T extends (...args: any[]) => any>(
  callback: T,
  delay: number
) {
  const cb = useLatest(callback);
  const handler = useRef<number>();

  useEffect(() => {
    window.clearTimeout(handler.current);
  }, [delay]);

  return useCallback(
    (...args: Parameters<T>) => {
      window.clearTimeout(handler.current);

      handler.current = window.setTimeout(() => {
        cb.current(...args);
      }, delay);
    },
    [delay, cb]
  );
}
// function useCallback<T extends (...args: any[]) => any>(callback: T, deps: DependencyList): T;
