/**
 * Because of it's dependency to `document`, this function needs to be called
 * on client/browser environment or it will throw an error
 */
export default function getScrollbarWidth() {
  const div = document.createElement("div");
  div.style.width = "99px";
  div.style.height = "99px";
  div.style.position = "absolute";
  div.style.top = "-9999px";
  div.style.overflow = "scroll";

  document.body.appendChild(div);
  const width = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);

  return width;
}
