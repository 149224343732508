"use client";
import { FocusEventHandler, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import {
  NumberFormatValues,
  NumericFormat,
  useNumericFormat,
} from "react-number-format";

import { Locale } from "../../../core/locale/constants";
import {
  getDisplayCurrencyByLocale,
  getLocale,
} from "../../../core/locale/utils/country";
import { resolveThousandSeparatorDelimiter } from "../../../utils/numeric";
import InputField, { InputFieldProps } from "../InputField/InputField";

type Props = {
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  inputStyle?: InputFieldProps["inputStyle"];
  onChange?(value: string): void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: string;
  error?: string;
  locale?: Locale;
  testID?: string;
  placeholder?: string;
  leftHelper?: string;
  prefix?: string;
  suffix?: string;
  disabled?: boolean;
};

export default function ThousandSeparatorInput(props: Props) {
  const {
    onChange,
    onBlur,
    error,
    value: propsValue = "0",
    locale = getLocale() || Locale.ENID,
    prefix: propsPrefix,
    suffix: propsSuffix,
    ...rest
  } = props;

  const separator = resolveThousandSeparatorDelimiter(locale);
  const thousandFormat = useNumericFormat({
    thousandSeparator: separator.thousand,
    decimalSeparator: separator.decimal,
  });

  const { prefix, suffix } = checkPrefixSuffix(
    locale,
    propsPrefix,
    propsSuffix
  );

  const defaultValue = {
    floatValue: Number(propsValue),
    formattedValue: addPrefixSuffix(
      thousandFormat.format!(propsValue),
      prefix,
      suffix
    ),
    value: propsValue,
  };
  const [value, setValue] = useState<NumberFormatValues>(defaultValue);

  function onValueChange(val: NumberFormatValues) {
    const { floatValue, formattedValue, value } = val;
    if (value.length === 1) {
      setValue(val);
      onChange?.(val.value);
      return;
    }

    const zeroRegex = /^0+/;
    const usedValue = value.replace(zeroRegex, "");
    let usedFormattedValue = "";

    if (usedValue) {
      let number = formattedValue;

      if (prefix) {
        number = number.split(prefix)[1]?.trim().replace(zeroRegex, "") || "0";
      }
      if (suffix) {
        number = number.split(suffix)[0]?.trim().replace(zeroRegex, "") || "0";
      }

      usedFormattedValue = addPrefixSuffix(number, prefix, suffix);
    } else {
      usedFormattedValue = formattedValue.replace(zeroRegex, "");
    }

    setValue({
      floatValue,
      formattedValue: usedFormattedValue,
      value: usedValue,
    });
    onChange?.(usedValue);
  }

  return (
    <NumericFormat
      customInput={InputField}
      value={value?.formattedValue || "0"}
      defaultValue={propsValue}
      onValueChange={onValueChange}
      onBlur={onBlur}
      error={error}
      thousandSeparator={separator.thousand}
      decimalSeparator={separator.decimal}
      prefix={prefix}
      suffix={suffix}
      {...rest}
    />
  );
}

function addPrefixSuffix(value: string, prefix?: string, suffix?: string) {
  let formattedValue = value;

  if (prefix) {
    formattedValue = prefix + formattedValue;
  }

  if (suffix) {
    formattedValue = formattedValue + suffix;
  }

  return formattedValue;
}

function checkPrefixSuffix(
  locale: Locale,
  propsPrefix?: string,
  propsSuffix?: string
) {
  const displayCurrency = getDisplayCurrencyByLocale(locale);

  let obj: Record<string, string>;
  if (displayCurrency.position === "left") {
    obj = {
      prefix: `${displayCurrency.symbol} `,
    };
  } else {
    obj = {
      suffix: ` ${displayCurrency.symbol}`,
    };
  }

  if (propsPrefix !== undefined) {
    obj.prefix = propsPrefix;
  }

  if (propsSuffix !== undefined) {
    obj.suffix = propsSuffix;
  }

  return obj;
}
