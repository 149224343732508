// https://stackoverflow.com/a/69607240/7552329
export function resolveThousandSeparatorDelimiter(locale: string) {
  const mapperRE = /(.{1})\d{3}(\D)\d$/,
    formattedValue = new Intl.NumberFormat(locale).format(1000.1);

  const [_, thousand, decimal] = mapperRE.exec(formattedValue)!;
  return { thousand, decimal };
}

export function isNumeric(value: string) {
  return /^[0-9]+$/.test(value);
}
