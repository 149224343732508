import { Country, Locale } from "../constants";
import { hasSupportedLocales } from "./url";

export function getCompanyCountry() {
  // get locale from url, e.g: en-id
  const currentLocale = window && window.location.pathname.split("/")[1];

  switch (currentLocale) {
    case Locale.ENID:
    case Locale.IDID:
      return Country.ID;
    case Locale.ENTH:
    case Locale.THTH:
      return Country.TH;
    case Locale.ENVN:
    case Locale.VNVN:
      return Country.VN;
    default:
      return undefined;
  }
}

export function getLocale(): Locale | undefined {
  const currentLocale = window
    ? (window.location.pathname.split("/")[1] as Locale)
    : undefined;

  return currentLocale && hasSupportedLocales(currentLocale)
    ? currentLocale
    : undefined;
}

export function getLocaleByCountry(country: Country) {
  return `en-${country.toLowerCase()}` as Locale;
}

export function getLocaleByCurrency(currency: "IDR" | "THB" | "VND") {
  const country = getCountryByCurrency(currency);
  return `en-${country.toLowerCase()}` as Locale;
}

export function getCountryByCurrency(currency: "IDR" | "THB" | "VND") {
  switch (currency) {
    case "IDR":
      return Country.ID;
    case "THB":
      return Country.TH;
    case "VND":
      return Country.VN;
  }
}

export function getCurrencyByCountry(country: Country | undefined) {
  switch (country) {
    case Country.ID:
      return "IDR";
    case Country.TH:
      return "THB";
    case Country.VN:
      return "VND";
    default:
      return "IDR";
  }
}

export function getDisplayCurrencyByCountry(country?: Country): {
  symbol: string;
  position: "left" | "right";
} {
  const companyCountry = country ?? getCompanyCountry();

  switch (companyCountry) {
    case Country.ID:
      return { symbol: "Rp", position: "left" };
    case Country.TH:
      return { symbol: "THB", position: "left" };
    case Country.VN:
      return { symbol: "VND", position: "right" };
    default:
      return { symbol: "Rp", position: "left" };
  }
}

export function getDisplayCurrencyByLocale(locale?: Locale): {
  symbol: string;
  position: "left" | "right";
} {
  const usedLocale = locale ?? getLocale();

  switch (usedLocale) {
    case Locale.ENID:
    case Locale.IDID:
      return { symbol: "Rp", position: "left" };
    case Locale.ENTH:
    case Locale.THTH:
      return { symbol: "THB", position: "left" };
    case Locale.ENVN:
    case Locale.VNVN:
      return { symbol: "VND", position: "right" };
    case undefined:
      return { symbol: "Rp", position: "left" };
  }
}
