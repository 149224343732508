import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import Token from "../Token";
import View from "../View";

export type DotLoaderProps = {
  /**
   * Color of the dots
   * @default '#0194f3'
   */
  color?: string;
  /**
   * Custom style applied to the root element
   */
  style?: StyleProp<ViewStyle>;
};

export default function DotLoader(props: DotLoaderProps) {
  const { color = Token.color.bluePrimary, style } = props;

  return (
    <View
      accessibilityRole="none"
      align="center"
      row
      justify="center"
      spacing="xs"
      style={[styles.root, style]}
    >
      {Array.from({ length: 3 }, (_, index) => {
        const dotStyle = [
          styles.dot,
          {
            animationDelay: `${Token.timing.instant * index}ms`,
            backgroundColor: color,
          },
        ];

        return <View key={index} style={dotStyle} />;
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    height: 12,
    width: 36,
  },
  dot: {
    animationDuration: `1s`,
    animationIterationCount: "infinite",
    animationKeyframes: {
      "30%": {
        transform: [{ scale: 3 }],
        opacity: 1,
      },
      "60%, 100%": {
        transform: [{ scale: 1 }],
        opacity: Token.opacity.washedOut(),
      },
    },
    animationTimingFunction: "ease-in-out",
    borderRadius: Token.borderRadius.rounded,
    height: 4,
    width: 4,
  },
});
