import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import Token from "../Token";

export type KnobProps = {
  disabled?: boolean;
  error?: boolean;
  hovered?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function Knob(props: KnobProps) {
  const { disabled, error, hovered, style } = props;

  const rootStyles = [
    styles.root,
    hovered && styles.hovered,
    error && styles.error,
    disabled && styles.disabled,
    style,
  ];
  const ringStyles = [styles.ring, !disabled && error && styles.ringError];

  return (
    <View style={rootStyles}>
      <View style={ringStyles} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    backgroundColor: Token.color.lightPrimary,
    borderColor: Token.color.lightSecondary,
    borderRadius: Token.borderRadius.rounded,
    borderWidth: Token.borderWidth.thick,
    boxShadow: Token.shadow.raised,
    height: 28,
    justifyContent: "center",
    transitionDuration: `${Token.timing.instant}ms`,
    width: 28,
  },
  disabled: {
    borderColor: Token.color.lightNeutral,
  },
  error: {
    borderColor: Token.color.redPrimary,
  },
  hovered: {
    borderColor: Token.color.darkSecondary,
  },
  ring: {
    borderColor: Token.color.lightNeutral,
    borderRadius: Token.borderRadius.rounded,
    borderWidth: Token.borderWidth.bold,
    height: 14,
    transitionDuration: `${Token.timing.instant}ms`,
    width: 14,
  },
  ringError: {
    borderColor: Token.color.redLight,
  },
});
