import { StyleSheet } from "react-native";

import MinusIcon from "@traveloka/icon-kit-web/react/IcSymbolAddMinus16";
import CheckIcon from "@traveloka/icon-kit-web/react/IcSystemCheckmark16";

import Token from "../Token";
import View from "../View/View";

export type CheckmarkProps = {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  hovered?: boolean;
  indeterminate?: boolean;
  /**
   * Defines size of the element
   * @default 'medium'
   */
  size?: "small" | "medium" | "large";
};

const iconSizeMap = {
  small: 12,
  medium: 16,
  large: 24,
};

export default function Checkmark(props: CheckmarkProps) {
  const {
    checked,
    disabled,
    error,
    indeterminate = false,
    size = "medium",
    hovered,
  } = props;

  const Icon = indeterminate ? MinusIcon : CheckIcon;
  const iconColor = checked
    ? Token.color.lightPrimary
    : Token.color.lightSecondary;

  const rootStyle = [
    styles.root,
    styles[size],
    !checked && [
      hovered && styles.hovered,
      error && styles.error,
      disabled && styles.disabled,
    ],
    checked && [
      styles.checked,
      hovered && styles.checkedHovered,
      error && styles.checkedError,
      hovered && error && styles.checkedErrorHovered,
      disabled && styles.checkedDisabled,
    ],
  ];

  return (
    <View style={rootStyle}>
      <Icon
        color={iconColor}
        height={iconSizeMap[size]}
        width={iconSizeMap[size]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: "center",
    backgroundColor: Token.color.lightPrimary,
    borderColor: Token.color.darkSecondary,
    borderRadius: Token.borderRadius.normal,
    cursor: "pointer",
    justifyContent: "center",
    userSelect: "none",
  },
  disabled: {
    backgroundColor: Token.color.lightPrimary,
    borderColor: Token.color.lightSecondary,
    cursor: "default",
  },
  error: {
    borderColor: Token.color.redPrimary,
  },
  hovered: {
    backgroundColor: Token.color.lightNeutral,
  },

  checked: {
    backgroundColor: Token.color.bluePrimary,
    borderColor: Token.color.bluePrimary,
  },
  checkedError: {
    backgroundColor: Token.color.redPrimary,
    borderColor: Token.color.redPrimary,
  },
  checkedDisabled: {
    backgroundColor: Token.color.lightNeutral,
    borderColor: Token.color.lightNeutral,
    cursor: "default",
  },
  checkedHovered: {
    backgroundColor: Token.color.blueSecondary,
    borderColor: Token.color.blueSecondary,
  },
  checkedErrorHovered: {
    backgroundColor: Token.color.redSecondary,
    borderColor: Token.color.redSecondary,
  },

  // Size
  small: {
    borderWidth: Token.borderWidth.thick,
    padding: 1,
  },
  medium: {
    borderWidth: 1.5,
    padding: 1.5,
  },
  large: {
    borderWidth: Token.borderWidth.bold,
  },
});
