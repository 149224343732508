export function getDates(year: number, month: number): Array<null | Date> {
  const startOffset = new Date(year, month, 1).getDay();
  const endOfMonth = new Date(year, month + 1, 0);
  const lastRowLength = (startOffset + endOfMonth.getDate()) % 7;

  return [
    ...Array(startOffset).fill(null),
    ...Array.from({ length: endOfMonth.getDate() }, (_v, i) => {
      return new Date(year, month, i + 1);
    }),
    ...Array(lastRowLength === 0 ? 0 : 7 - lastRowLength).fill(null),
  ];
}

export function getWeekdayNames(locale = "default") {
  const dateFormater = new Intl.DateTimeFormat(locale, {
    weekday: "short",
  });

  return Array.from({ length: 7 }, (_v, i) => {
    // 2000-01-02 is known sunday
    return dateFormater.format(new Date(2000, 0, 2 + i));
  });
}

export function getYears(
  start: number,
  end: number,
  inclusive: boolean = false
) {
  const endYear = inclusive ? end + 1 : end;
  return Array.from({ length: endYear - start }, (_, i) => start + i);
}
