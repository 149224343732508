export * from './Tabs';
export { default } from './Tabs';

export * from './Tab';
export { default as Tab } from './Tab';

export * from "./TabSeo";
export { default as TabSeo } from "./TabSeo";
export * from './TabsSeo';
export { default as TabsSeo } from './TabsSeo';

