import { StyleSheet, View } from "react-native";

import Text from "../../Text/Text";
import Token from "../../Token/Token";

type Props = {
  text: string;
};

export default function InputCheckboxDropdownItemGroup(props: Props) {
  return (
    <View style={styles.container}>
      <Text variant="title-3" ink="black-secondary">
        {props.text}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Token.color.lightNeutral,
    paddingVertical: Token.spacing.xxs,
    paddingHorizontal: Token.spacing.s,
  },
});
