"use client";
import { createContext, PropsWithChildren, useContext } from "react";

import { Locale } from "../constants";

const LocaleContext = createContext<Locale>(null!);

type Props = {
  locale: Locale;
};
export default function LocaleProvider(props: PropsWithChildren<Props>) {
  const { children, locale } = props;

  return (
    <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
  );
}

export function useLocale() {
  return useContext(LocaleContext);
}
