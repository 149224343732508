"use client";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { StyleSheet, View } from "react-native";

import IcSystemStatusFailFill from "@traveloka/icon-kit-web/react/IcSystemStatusFailFill12";
import IcSystemStatusOkDoneFill from "@traveloka/icon-kit-web/react/IcSystemStatusOkDone";
import IcSystemTrash from "@traveloka/icon-kit-web/react/IcSystemTrash16";

import { formatMessage } from "../../../utils/intl";
import Button from "../../Button/Button";
import Text, { TextProps } from "../../Text/Text";
import Token from "../../Token/Token";
import InputField from "../InputField/InputField";
import { Document, DocumentFieldValue } from "./types";

export type DocumentFieldProps = {
  name: string;
  document: Document;
  index?: number;
  helper?: string;
  label?: string;
  disabled?: boolean;
  onChange(documentType: string, file: File, index?: number): Promise<any>;
  saveFileChange?(file: DocumentFieldValue | undefined): void;

  // content
  uploadButtonCr?: string;
  retryButtonCr?: string;
  documentExtensionCr: string;
  documentFileSizeCr: string;
  documentRequiredCr: string;
};

export default function DocumentFieldSingle(props: DocumentFieldProps) {
  const {
    name,
    document,
    index,
    helper,
    label,
    disabled,
    onChange,
    saveFileChange,

    uploadButtonCr = "Upload",
    retryButtonCr = "Retry",
    documentExtensionCr,
    documentFileSizeCr,
    documentRequiredCr,
  } = props;

  const [uploading, setUploading] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { control } = useFormContext();
  const {
    field: input,
    fieldState: { error },
    formState: { isSubmitSuccessful },
  } = useController({
    control,
    name,
    rules: {
      validate(v) {
        if (v && !isAllowedExtension(v.file, document)) {
          return formatMessage(documentExtensionCr, {
            val: document.extensions.join(", "),
          });
        } else if (v && !isBelowMaxSize(v.file, document)) {
          return formatMessage(documentFileSizeCr, {
            val: document.maxSizeInMB,
          });
        } else if (document.mandatory && !v?.url && !index) {
          return documentRequiredCr;
        }

        return;
      },
    },
  });
  const inputValue = input.value as DocumentFieldValue | undefined;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.[0]) {
      const file = {
        url: "",
        file: e.target.files[0],
        label: document.label,
        uploaded: false,
      };
      input.onChange(file);
      input.onBlur();
    }
  }

  useEffect(() => {
    if (!inputValue || inputValue.uploaded) {
      setHasUploaded(false);
      return;
    }

    const file = inputValue.file;

    if (!isBelowMaxSize(file, document) || !isAllowedExtension(file, document))
      return;

    setUploading(true);
    setHasUploaded(false);
    setUploadError(false);

    if (document.docType) {
      onChange(document.docType, file, index)
        .then((res) => {
          if (res.isSuccess) {
            const file = {
              ...inputValue,
              url: res.url,
              uploaded: true,
            } as DocumentFieldValue;
            input.onChange(file);
            saveFileChange?.(file);
            setHasUploaded(true);
            return;
          }

          throw new Error();
        })
        .catch((e) => {
          input.onChange(undefined);
          saveFileChange?.(undefined);
          setUploadError(true);
        })
        .finally(() => setUploading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when file changes
  }, [inputValue?.file]);

  if (document.docType === null) {
    return null;
  }

  const isFormatOrSizeError =
    inputValue &&
    (!isAllowedExtension(inputValue.file, document) ||
      !isBelowMaxSize(inputValue.file, document));
  const errorMessage =
    (!isSubmitSuccessful || inputValue?.file) && !uploading && error?.message;
  const hasIcon = hasUploaded;

  let helperInk: TextProps["ink"] = "black-secondary";
  let labelInk: TextProps["ink"] = "black-secondary";
  let uploadButtonText = uploadButtonCr;

  if (uploadError) {
    uploadButtonText = retryButtonCr;
  }

  if (disabled) {
    helperInk = "black-muted";
    labelInk = "black-muted";
  }

  const iconComponent = uploadError ? (
    <IcSystemStatusFailFill
      color={Token.color.redPrimary}
      width={12}
      height={12}
    />
  ) : (
    <IcSystemStatusOkDoneFill
      color={Token.color.greenPrimary}
      width={12}
      height={12}
    />
  );

  return (
    <View style={styles.container}>
      {Boolean(label) && (
        <Text style={styles.label} variant="ui-tiny" ink={labelInk}>
          {label}
        </Text>
      )}
      <View style={[styles.inputGroup, styles.row]}>
        <input
          onChange={handleChange}
          ref={inputRef}
          style={{ display: "none" }}
          type="file"
        />
        <InputField
          style={[styles.input, error && styles.error]}
          value={inputValue?.file?.name || ""}
          placeholder={document.placeholder}
          endIcon={hasIcon ? iconComponent : undefined}
          disabled={disabled}
        />
        {Boolean(inputValue) && (
          <Button
            size="small"
            style={styles.buttonDelete}
            onPress={() => {
              if (inputRef.current) {
                inputRef.current.value = "";
              }

              input.onChange(undefined);
              saveFileChange?.(undefined);
            }}
            startIcon={
              <IcSystemTrash
                color={Token.color.lightPrimary}
                accentColor={Token.color.lightPrimary}
                width={16}
                height={16}
              />
            }
            variant="destructive-primary"
          />
        )}
        <Button
          text={uploadButtonText}
          onPress={() => inputRef.current?.click()}
          onBlur={input.onBlur}
          disabled={uploading || disabled}
        />
      </View>
      {Boolean(errorMessage) && (
        <Text style={styles.helper} variant="ui-tiny" ink="alert">
          {errorMessage}
        </Text>
      )}
      {Boolean(helper) && !isFormatOrSizeError && (
        <Text style={styles.helper} variant="ui-tiny" ink={helperInk}>
          {helper}
        </Text>
      )}
    </View>
  );
}

function isBelowMaxSize(file: File, document: Document) {
  if (!file) return false;
  return file.size < Number(document.maxSizeInMB) * 1e6;
}

function isAllowedExtension(file: File | undefined, document: Document) {
  const extension = file?.name.split(".").pop();

  return (
    typeof extension === "string" &&
    document.extensions.includes("." + extension)
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    marginBottom: Token.spacing.xxs,
  },
  helper: {
    marginTop: Token.spacing.xxs,
  },
  inputGroup: {
    flex: 1,
  },
  input: {
    flex: 1,
    marginRight: Token.spacing.xxs,
    paddingVertical: 0,
  },
  result: {
    flex: 1,
    backgroundColor: Token.color.lightStain,
    borderRadius: Token.borderRadius.normal,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.m,
  },
  marginStart: {
    marginStart: Token.spacing.m,
  },
  buttonDelete: {
    marginRight: Token.spacing.xxs,
    paddingVertical: Token.spacing.s,
  },
  uploading: {
    flex: 1,
    height: 48,
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    marginTop: Token.spacing.xs,
  },
  error: {
    borderColor: Token.color.redPrimary,
    borderWidth: Token.borderWidth.thick,
    borderRadius: Token.borderRadius.normal,
  },
  lineProgress: {
    marginVertical: Token.spacing.xxs,
    height: 4,
    flex: 1,
    backgroundColor: Token.color.lightNeutral,
    borderRadius: Token.borderRadius.rounded,
  },
  lineValue: {
    textAlign: "right",
  },
  multipleActionIcon: {
    marginLeft: Token.spacing.ml,
  },
});
