"use client";
import { useEffect, useRef } from "react";

export default function useInitialRenderRef() {
  const ref = useRef(true);

  useEffect(() => {
    ref.current = false;
  }, []);

  return ref;
}
