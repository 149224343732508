"use client";
import { RefCallback, useCallback, useRef, useState } from "react";

import useLatest from "../useLatest";
import observe from "./observer";
import { ObserverCallback } from "./types";

type Options = IntersectionObserverInit & {
  onChange?: ObserverCallback;
};

type ResponseArray = [ref: RefCallback<Element>, intersect: boolean];
type ResponseObject = { ref: RefCallback<Element>; intersect: boolean };
type IntersectionResponse = ResponseArray & ResponseObject;

export default function useIntersection(
  options: Options = {}
): IntersectionResponse {
  const unobserve = useRef<() => void>();
  const latestOptions = useLatest(options);

  const [intersect, setIntersect] = useState(false);

  const setRef = useCallback<RefCallback<Element>>(
    (element) => {
      if (unobserve.current !== undefined) {
        unobserve.current();
        unobserve.current = undefined;
      }

      const { onChange, ...options } = latestOptions.current;
      const thresholds = Array.isArray(options.threshold)
        ? options.threshold
        : [options.threshold || 0];

      if (element) {
        unobserve.current = observe(
          element,
          (entry) => {
            const intersect =
              entry.isIntersecting &&
              thresholds.some(
                (threshold) => entry.intersectionRatio >= threshold
              );

            setIntersect(intersect);
            onChange?.(entry);
          },
          options
        );
      }
    },
    [latestOptions]
  );

  const result = [setRef, intersect] as IntersectionResponse;

  // Support object destructuring, by adding the specific values.
  result.ref = result[0];
  result.intersect = result[1];

  return result;
}
