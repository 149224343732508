"use client";
import {
  Children,
  cloneElement,
  ComponentType,
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import {
  findNodeHandle,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewProps,
} from "react-native";

import DefaultButtonComponent, { ButtonComponentProps } from "./RadioButton";

type RadioContextValue = {
  currentValue: string | number;
  currentFocusIndex: number;
  setFocusIndex: Dispatch<SetStateAction<number>>;
  focusNext: () => void;
  focusPrev: () => void;
  onChange: RadioGroupProps["onChange"];
};

const RadioContext = createContext<RadioContextValue | null>(null);
const batch = ReactDOM.unstable_batchedUpdates;

export type RadioGroupProps = ViewProps & {
  children: ReactNode;
  labelID: string;
  value: string | number;
  onChange?: (nextValue: string | number) => void;
};

function RadioGroup({
  children,
  labelID,
  value,
  onChange,
  ...props
}: RadioGroupProps) {
  const [focusIndex, setFocusIndex] = useState(-1);

  const buttons: any[] = [];
  const newChildren = Children.map(children as any, (child: ReactElement) => {
    if (child.type === RadioButton) {
      buttons.push(child);
      return cloneElement(child, { index: buttons.length - 1 });
    }
    return child;
  });

  const childCount = buttons.length;

  function focusNext() {
    const nextFocusIndex = (focusIndex + 1) % childCount;
    setFocusIndex(nextFocusIndex);
    if (typeof onChange === "function") {
      onChange(buttons[nextFocusIndex].props.value);
    }
  }

  function focusPrev() {
    const prevFocusIndex = focusIndex === 0 ? childCount - 1 : focusIndex - 1;
    setFocusIndex(prevFocusIndex);
    if (typeof onChange === "function") {
      onChange(buttons[prevFocusIndex].props.value);
    }
  }

  if (typeof labelID !== "string") {
    throw new Error(
      `RadioGroup should be given ID to its label in order to be accessible.`
    );
  }

  return (
    <RadioContext.Provider
      value={{
        currentValue: value,
        currentFocusIndex: focusIndex,
        setFocusIndex,
        focusNext,
        focusPrev,
        onChange,
      }}
    >
      <View
        // @ts-ignore - ignoring because there's no role="radiogroup" in RN libdef
        accessibilityRole="radiogroup"
        aria-labelledby={labelID}
        // @ts-ignore - ignoring because there's no onBlur in RN libdef
        onBlur={() => setFocusIndex(-1)}
        {...props}
      >
        {newChildren}
      </View>
    </RadioContext.Provider>
  );
}

const KeyCode = {
  RETURN: 13,
  SPACE: 32,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

export type RadioButtonProps = {
  value: string | number;
  label: string;
  buttonComponentSize?: "small" | "medium";
  disabled?: boolean;
  index?: number;
  containerStyle?: TouchableOpacityProps["style"];
  ButtonComponent?: ComponentType<ButtonComponentProps>;
  testID?: string;
};

function RadioButton({
  value,
  label,
  buttonComponentSize,
  disabled = false,
  index,
  containerStyle,
  ButtonComponent = DefaultButtonComponent,
  testID,
}: RadioButtonProps) {
  const containerRef = useRef<any>(null);
  const ctx = useContext(RadioContext);
  if (ctx === null) {
    throw new Error(
      `Rendering a RadioButton without wrapping it in a RadioGroup is not supported ` +
        `as it leads to inaccessible user experience.`
    );
  }
  if (typeof index !== "number") {
    throw new Error(
      `RadioButton should be a direct descendant of RadioGroup, otherwise ` +
        `please ensure you're giving the correct "index" prop from where it's originally rendered.`
    );
  }
  const {
    currentFocusIndex,
    currentValue,
    focusNext,
    focusPrev,
    onChange,
    setFocusIndex,
  } = ctx;
  const isFocused = currentFocusIndex === index;

  useEffect(() => {
    function handleKeydown(e: KeyboardEvent) {
      batch(() => {
        let handled = false;
        switch (e.keyCode) {
          case KeyCode.SPACE:
          case KeyCode.RETURN:
            if (typeof onChange === "function") {
              onChange(value);
            }
            handled = true;
            break;
          case KeyCode.UP:
            focusPrev();
            handled = true;
            break;
          case KeyCode.DOWN:
            focusNext();
            handled = true;
            break;
          case KeyCode.LEFT:
            focusPrev();
            handled = true;
            break;
          case KeyCode.RIGHT:
            focusNext();
            handled = true;
            break;
          default:
            break;
        }
        if (handled) {
          e.stopPropagation();
          e.preventDefault();
        }
      });
    }

    const node = containerRef.current as HTMLDivElement;
    node.addEventListener("keydown", handleKeydown);

    return () => {
      node.removeEventListener("keydown", handleKeydown);
    };
  }, [focusNext, focusPrev, value, onChange]);

  useEffect(() => {
    const rafId = requestAnimationFrame(() => {
      if (isFocused === true) {
        const node = containerRef.current;
        node.focus();
      }
    });
    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [isFocused]);

  const isSelected = currentValue === value;
  return (
    <TouchableOpacity
      ref={(ref) => {
        containerRef.current = findNodeHandle(ref);
      }}
      onPress={() => {
        setFocusIndex(index);
        if (typeof onChange === "function") {
          onChange(value);
        }
      }}
      disabled={disabled}
      onFocus={() => setFocusIndex(index)}
      // @ts-ignore - ignoring because there's no role="radio" in RN libdef
      accessibilityRole="radio"
      aria-checked={isSelected}
      accessible={isFocused || isSelected}
      style={[radioStyles.optionContainer, containerStyle]}
      testID={testID}
    >
      <ButtonComponent
        label={label}
        isFocused={isFocused}
        isSelected={isSelected}
        disabled={disabled}
        size={buttonComponentSize}
      />
    </TouchableOpacity>
  );
}

const radio = {
  Group: RadioGroup,
  Button: RadioButton,
};

export default radio;

const radioStyles = StyleSheet.create({
  optionContainer: {
    flexDirection: "row",
    alignItems: "center",
    outlineWidth: 0,
  },
});
