import htmr from "htmr";
import { useMemo, ReactHTML } from "react";
import {
  // @ts-expect-error unstable_createElement does not exist in react-native typing, but exist in react-native-web
  unstable_createElement,
  ImageStyle,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from "react-native";

import Token from "../Token";

export type HtmlTextProps = {
  text: string;
  style?: Record<string, ViewStyle | TextStyle | ImageStyle>;
};

export default function HtmlText(props: HtmlTextProps) {
  const { text: html, style = {} } = props;

  const children = useMemo(
    () =>
      htmr(html, {
        transform: {
          _(node, props, children) {
            // Text Node
            if (props === undefined || props === null) return node;

            // Element
            return unstable_createElement(
              node as keyof ReactHTML,
              Object.assign(props, {
                style: [
                  // @ts-expect-error style is not defined in P
                  props.style,
                  styles[node as keyof typeof styles],
                  style[node],
                ],
              }),
              children
            );
          },
        },
      }),
    [html, style]
  );

  return <>{children}</>;
}

const styles = StyleSheet.create({
  a: { color: Token.color.bluePrimary },
  b: { fontWeight: "700" },
  em: { fontStyle: "italic" },
  h1: Token.typography.headline,
  h2: Token.typography.title1,
  h3: Token.typography.title2,
  h4: Token.typography.title3,
  hr: { marginVertical: Token.spacing.m },
  i: { fontStyle: "italic" },
  p: { marginVertical: Token.spacing.m },
  strong: { fontWeight: "700" },
  ul: { marginVertical: Token.spacing.m },
});
