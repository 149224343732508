import storage from "../utils/storage";

const LOCAL_STORAGE_KEY = "lh";
function isServerSide() {
  return typeof window === "undefined";
}

type Location = {
  path: string;
  count: number;
};

class LocationHistory {
  history: Location[] = [];
  MAX_HISTORY = 10;

  constructor(history: Location[]) {
    this.history = history;
  }

  get current() {
    if (this.empty) {
      return null;
    }

    return this.history[0];
  }

  get empty() {
    return this.history.length === 0;
  }

  get previous() {
    if (this.history.length < 2) {
      return null;
    }

    return this.history[1];
  }

  add(path: string) {
    if (isServerSide()) return;

    if (this.current?.path === path) {
      this.current.count++;
    } else {
      this.history.unshift({ path, count: 0 });
    }

    if (this.history.length > this.MAX_HISTORY) {
      this.history.pop();
    }

    this.save();
  }

  remove() {
    if (isServerSide()) return;

    if (this.empty) {
      return;
    }

    this.history.pop();
    this.save();
  }

  clear() {
    if (isServerSide()) return;

    this.history = [];
    this.save();
  }

  save() {
    storage.set(LOCAL_STORAGE_KEY, this.history);
  }
}

const locationHistory = new LocationHistory(
  storage.get<Location[]>(LOCAL_STORAGE_KEY) || []
);

export default locationHistory;
