import { ViewSeo } from "../View";
import cssStyle from "./divider.module.css";

export type DividerSeoProps = {
  className?: string;
  subtle?: boolean;
};

export default function DividerSeo(props: DividerSeoProps) {
  const { className, subtle } = props;

  const rootStyle = [cssStyle.root, subtle && cssStyle.subtle, className].join(
    " "
  );

  return <ViewSeo role="separator" className={rootStyle} />;
}
