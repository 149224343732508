"use client";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

type ControlledConfig<T> = {
  defaultValue: T;
  name: string;
  prop: string;
  value: T;
};

export default function useControlled<T>(
  config: ControlledConfig<T>
): [T, Dispatch<SetStateAction<T>>] {
  const { value: valueProp, defaultValue, name, prop } = config;

  const controlled = useRef(valueProp !== undefined).current;
  const [state, setState] = useState(defaultValue);
  const value = controlled ? valueProp : state;

  const setValue = useCallback(
    (state: SetStateAction<T>) => {
      if (!controlled) setState(state);
    },
    [controlled]
  );

  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- the condition above wont change
    useEffect(() => {
      if (xor(controlled, valueProp !== undefined)) {
        console.error(
          `A component is changing ${controlled ? "" : "un"}controlled ` +
            `${prop} state of ${name} to be ` +
            `${controlled ? "un" : ""}controlled.\n` +
            `Decide between using controlled or uncontrolled ${name} ` +
            "element for the lifetime of the component.\n" +
            "More info: https://fb.me/react-controlled-components"
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps -- only update when valueProp, prop, name changes
    }, [valueProp, prop, name]);

    // eslint-disable-next-line react-hooks/rules-of-hooks -- the condition above wont change
    const prevDefaultValue = useRef(defaultValue).current;

    // eslint-disable-next-line react-hooks/rules-of-hooks -- the condition above wont change
    useEffect(() => {
      if (!controlled && prevDefaultValue !== defaultValue) {
        console.error(
          `A component is changing the default ${prop} state of an` +
            `uncontrolled ${name} after being initialized. ` +
            `To suppress this warning, opt to use a controlled ${name}.`
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps -- only update when defaultValue changes
    }, [JSON.stringify(defaultValue)]);
  }

  return [value, setValue];
}

function xor(a: boolean, b: boolean) {
  return a ? !b : b;
}
