import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from "react-native";

import ChevronLeft from "@traveloka/icon-kit-web/react/IcSystemChevronLeft";
import ChevronRight from "@traveloka/icon-kit-web/react/IcSystemChevronRight";

import Hoverable from "../../hooks/useHoverable";
import useLayout from "../../hooks/useLayout";
import Token from "../Token/Token";
import View from "../View/View";
import PhotoNavigation from "./PhotoNavigation";

type Props = {
  activeIndex: number;
  photos: Array<{
    imageUrl: string;
    /** Useful to input smaller sized image as thumbnail */
    thumbnailUrl?: string;
    likedCount?: number;
    isLiked?: boolean;
  }>;
  showNavigator?: boolean;
  onNextPressed?: TouchableOpacityProps["onPress"];
  onPrevPressed?: TouchableOpacityProps["onPress"];
  /** Will decrease the size of the gallery, to accomodate navbar at the top */
  topOffset?: number;
  onPhotoClick: (index: number) => void;
};

export default function PhotoGallery(props: Props) {
  const {
    photos,
    activeIndex,
    onPhotoClick,
    topOffset = 0,
    showNavigator,
    onPrevPressed,
    onNextPressed,
  } = props;

  const [layout, events] = useLayout();
  const [navLayout, navEvents] = useLayout();

  return (
    <View
      // @ts-ignore height calc
      style={{ width: "100%", height: `calc(100% - ${topOffset}px)` }}
      {...events}
    >
      <View
        style={[
          styles.previewContainer,
          {
            height: layout.height - navLayout.height,
            marginBottom: navLayout.height,
          },
        ]}
      >
        {showNavigator && photos.length > 1 && (
          <Hoverable>
            {(isHovered) => {
              return (
                <View style={styles.navigatorContainer}>
                  {isHovered && (
                    <View style={styles.navigatorWrapper}>
                      <TouchableOpacity
                        style={styles.navigator}
                        activeOpacity={0.5}
                        onPress={onPrevPressed}
                      >
                        <ChevronLeft
                          color={Token.color.lightPrimary}
                          width={24}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={styles.navigator}
                        activeOpacity={0.5}
                        onPress={onNextPressed}
                      >
                        <ChevronRight
                          color={Token.color.lightPrimary}
                          width={24}
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              );
            }}
          </Hoverable>
        )}
        <img
          src={photos[activeIndex].imageUrl}
          width="100%"
          style={{
            maxHeight: "100%",
            objectFit: "contain",
            objectPosition: "50% 50%",
          }}
        />
      </View>
      {/**  Differentiate the behavior between mweb and desktop, by checking of the prev/next button are present or not.
            Normally its enough to check showNavigator, but in this case, showNavigator are also used on mweb but without
            onPress listener. TODO: split the component to mweb & desktop representative OR add another props to differentiate
            between mweb & desktop.
      */}
      <View
        {...navEvents}
        style={[
          styles.bottomWrapper,
          showNavigator && onNextPressed && onPrevPressed
            ? styles.bottomStatic
            : null,
        ]}
      >
        <PhotoNavigation
          items={photos}
          activeIndex={activeIndex}
          onItemClick={onPhotoClick}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bottomWrapper: {
    // @ts-ignore
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: Token.color.lightPrimary,
  },
  bottomStatic: {
    position: "absolute",
  },
  previewContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  navigatorContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    paddingLeft: Token.spacing.xs,
    paddingRight: Token.spacing.xs,
  },
  navigatorWrapper: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  navigator: {
    padding: Token.spacing.xs,
    borderRadius: 99,
    backgroundColor: Token.color.darkPrimary,
    opacity: 0.8,
    textAlign: "center",
  },
});
