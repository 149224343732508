// Taken from: https://stackoverflow.com/a/66569574/7552329
export default function convertCssUnit(
  cssValue: string | number,
  target: HTMLElement | null = null
) {
  if (typeof cssValue === "number") {
    return cssValue;
  }

  const usedTarget = target ?? document.body;

  const supportedUnits = {
    // Absolute sizes
    px: (value: number) => value,
    cm: (value: number) => value * 38,
    mm: (value: number) => value * 3.8,
    q: (value: number) => value * 0.95,
    in: (value: number) => value * 96,
    pc: (value: number) => value * 16,
    pt: (value: number) => value * 1.333333,

    // Relative sizes
    rem: (value: number) =>
      value * parseFloat(getComputedStyle(document.documentElement).fontSize),
    em: (value: number) =>
      // @ts-ignore Type error: Argument of type 'HTMLElement | null' is not assignable to parameter of type 'Element'. Type 'null' is not assignable to type 'Element'.
      value * parseFloat(getComputedStyle(usedTarget).fontSize),
    vw: (value: number) => (value / 100) * window.innerWidth,
    vh: (value: number) => (value / 100) * window.innerHeight,

    // Times
    ms: (value: number) => value,
    s: (value: number) => value * 1000,

    // Angles
    deg: (value: number) => value,
    rad: (value: number) => value * (180 / Math.PI),
    grad: (value: number) => value * (180 / 200),
    turn: (value: number) => value * 360,
  };

  // Match positive and negative numbers including decimals with following unit
  const pattern = new RegExp(
    `^([-+]?(?:\\d+(?:\\.\\d+)?))(${Object.keys(supportedUnits).join("|")})$`,
    "i"
  );

  // If is a match, return example: [ "-2.75rem", "-2.75", "rem" ]
  const matches = pattern.exec(
    String.prototype.toString.apply(cssValue).trim()
  );

  if (matches) {
    const value = Number(matches[1]);
    const unit = matches[2].toLocaleLowerCase();

    // Sanity check, make sure unit conversion function exists
    if (unit in supportedUnits) {
      return supportedUnits[unit as keyof typeof supportedUnits](value);
    }
  }

  return cssValue;
}
