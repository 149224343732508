import { FieldErrors } from "react-hook-form";

type RefTypeSupportedByRhf = {
  reportValidity: () => number;
  select: () => void;
};

/**
 *
 * Don't forget to add `shouldFocusError: false` on the useForm()
 *
 * e.g: const methods = useForm({ shouldFocusError: false });
 */
export function handleErrorScroll(error: FieldErrors): void {
  const refs = findRefs(error);
  let topMostComponent: { index: number; top: number } = {
    index: -1,
    top: Number.MAX_SAFE_INTEGER,
  };

  refs.forEach((ref, index) => {
    const currentTop = ref?.reportValidity?.() ?? Number.MAX_SAFE_INTEGER;
    if (!topMostComponent || currentTop < topMostComponent.top) {
      topMostComponent = { index, top: currentTop };
    }
  });

  if (topMostComponent && topMostComponent.index > -1) {
    refs[topMostComponent.index]?.select?.();
  }
}

function findRefs(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any type is needed here
  object: Record<string, any>,
  paramTarget?: RefTypeSupportedByRhf[] | null
) {
  let target: RefTypeSupportedByRhf[] = paramTarget ?? [];
  const keys = Object.keys(object);
  for (const value of keys) {
    if (value === "ref") {
      target = [...target, object[value]];
      continue;
    }

    if (typeof object[value] === "object" && object[value] !== null) {
      target = findRefs(object[value], target);
    }
  }
  return target;
}

/**
 *
 * How to use:
 * \<RhfComponent ref={createRhfRef(ref)} /\>
 *
 * the `ref` is taken from the useControler().field.ref
 *
 * e.g: const { field: { ref } } = useControler();
 */
export function createRhfRef(ref: (instance: unknown) => void) {
  return (element: HTMLElement | null) => {
    ref({
      ...element,
      reportValidity: () => {
        const rect = element?.getBoundingClientRect();
        return rect?.y;
      },
      focus: () => element?.focus(),
      select: () =>
        element?.scrollIntoView({ behavior: "smooth", block: "center" }),
    });
  };
}
