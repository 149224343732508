import { forwardRef, useMemo } from "react";
import {
  Text as RNText,
  TextProps as RNTextProps,
  StyleSheet,
  TextStyle,
} from "react-native";

import Token from "../Token";
import { BaseTextProps } from "./types";

export type TextRef = RNText;

export type TextProps = BaseTextProps & RNTextProps;

export default forwardRef<TextRef, TextProps>(function Text(props, ref) {
  const {
    align = "left",
    ink = "black-primary",
    style,
    variant = "ui-baseline",
    ddMask: ddMaskProps,
    ...rest
  } = props;

  const rootStyles = [
    styles.root,
    styles[variant],
    styles[ink],
    styles[align],
    style,
  ];

  const ddData = useMemo(
    () =>
      ddMaskProps
        ? { "dd-action-name": ddMaskProps, "dd-privacy": "mask" }
        : undefined,
    [ddMaskProps]
  );

  return (
    <RNText
      {...rest}
      ref={ref}
      style={rootStyles}
      // @ts-expect-error dataSet is not available in react-native
      dataSet={ddData}
    />
  );
});

const styles = StyleSheet.create({
  root: {
    wordBreak: "break-word",
  } as TextStyle,

  // Variant
  hero: Token.typography.hero,
  display: Token.typography.display,
  headline: Token.typography.headline,
  "title-1": Token.typography.title1,
  "title-2": Token.typography.title2,
  "title-3": Token.typography.title3,
  "ui-large": Token.typography.uiLarge,
  "ui-baseline": Token.typography.uiBaseline,
  "ui-small": Token.typography.uiSmall,
  "ui-tiny": Token.typography.uiTiny,
  "ui-large-bold": Token.typography.uiLargeBold,
  "ui-baseline-bold": Token.typography.uiBaselineBold,
  "ui-small-bold": Token.typography.uiSmallBold,
  "ui-tiny-bold": Token.typography.uiTinyBold,
  "caption-tiny": Token.typography.captionTiny,
  "caption-micro": Token.typography.captionMicro,
  "button-large": Token.typography.buttonLarge,
  "button-medium": Token.typography.buttonMedium,
  "button-small": Token.typography.buttonSmall,

  // Ink
  "black-primary": { color: Token.color.darkPrimary },
  "black-secondary": { color: Token.color.darkSecondary },
  "black-muted": { color: Token.color.lightSecondary },
  "black-neutral": { color: Token.color.darkNeutral },
  "white-primary": { color: Token.color.lightPrimary },
  "white-secondary": { color: Token.color.lightSecondary },
  "white-muted": { color: Token.color.darkSecondary },
  "white-neutral": { color: Token.color.lightNeutral },
  "high-altitude": { color: Token.color.highAltitude },
  "good-night": { color: Token.color.goodNight },
  interactive: { color: Token.color.bluePrimary },
  destructive: { color: Token.color.redPrimary },
  highlight: { color: Token.color.blueDark },
  price: { color: Token.color.orangePrimary },
  positive: { color: Token.color.greenDark },
  alert: { color: Token.color.redDark },
  warning: { color: Token.color.yellowDark },

  // Align
  left: { textAlign: "left" },
  center: { textAlign: "center" },
  right: { textAlign: "right" },
});
