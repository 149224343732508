"use client";
import { useEffect, useRef } from "react";
import { ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import Text from "../Text/Text";
import Token from "../Token/Token";
import View from "../View/View";
import { getYears } from "./utils";

const DEFAULT_DATE_RANGE = 120;
const date = new Date();
const currYear = date.getFullYear();

type Props = {
  year: number;
  onYearPress(year: number): void;
  minYear?: number;
  maxYear?: number;
};

export default function CalendarYear(props: Props) {
  const {
    year: selectedYear,
    onYearPress,
    minYear = currYear - DEFAULT_DATE_RANGE,
    maxYear = currYear + DEFAULT_DATE_RANGE,
  } = props;
  const currentYearRef = useRef<TouchableOpacity>(null);
  const scrollRef = useRef<ScrollView>(null);
  const years = getYears(minYear, maxYear, true);

  useEffect(
    () =>
      currentYearRef.current?.measure((_, y) =>
        scrollRef.current?.scrollTo({ y, animated: false })
      ),
    [selectedYear]
  );

  return (
    <View style={styles.container} spacing="xs">
      <ScrollView ref={scrollRef}>
        <View style={styles.wrapper}>
          {years.map((year) => (
            <TouchableOpacity
              key={year}
              ref={year === selectedYear ? currentYearRef : undefined}
              onPress={() => {
                onYearPress(year);
              }}
              style={{ alignItems: "center" }}
            >
              <Text
                style={[
                  styles.yearCell,
                  year === selectedYear && styles.yearActiveCell,
                ]}
                variant="ui-small"
                ink={year === selectedYear ? "white-primary" : "black-primary"}
              >
                {year}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    overflow: "scroll",
    maxHeight: 312,
    //@ts-expect-error -- no transition type in react-native
    transition: `${Token.timing.instant}ms all`,
  },
  wrapper: {
    //@ts-expect-error -- no grid type in react-native
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    overflow: "scroll",
  },
  yearActiveCell: { backgroundColor: Token.color.bluePrimary },
  yearCell: {
    flexWrap: "nowrap",
    padding: Token.spacing.xs,
    borderRadius: Token.borderRadius.normal,
    //@ts-expect-error -- no max-content value in react-native
    width: "max-content",
  },
});
