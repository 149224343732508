import { ReactElement } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import PrevIcon from "@traveloka/icon-kit-web/react/IcSystemChevronLeft16";
import NextIcon from "@traveloka/icon-kit-web/react/IcSystemChevronRight16";

import { appendTestId } from "../../utils";
import Button, { ButtonProps } from "../Button";
import Text from "../Text";
import Token from "../Token";
import View from "../View";

export type PaginationProps = {
  /**
   * If true, disable pagination buttons
   */
  disabled?: boolean;
  /**
   * Called when the button is pressed
   */
  onPageChange(page: number): void;
  /**
   * Custom color for chevron pagination.
   */
  iconColor?: string;
  /**
   * Active page number.
   */
  page: number;
  /**
   * Custom variant for unselected page button
   */
  buttonVariant?: "primary" | "secondary" | "text-black";
  /**
   * Custom variant for selected page button
   */
  selectedButtonVariant?: "primary" | "secondary" | "text-black";
  /**
   * Defines size of the button
   * @default 'medium'
   */
  size?: ButtonProps["size"];
  /**
   * Custom style applied to the root element
   */
  style?: StyleProp<ViewStyle>;
  /**
   * The total number of pages.
   */
  totalPage: number;
  /**
   * Container Test ID for testing purposes
   */
  testID?: string;
  /**
   * Children Test ID for testing purposes
   */
  testIDPrefix?: string;
};

export default function Pagination(props: PaginationProps) {
  const {
    disabled = false,
    onPageChange,
    iconColor = Token.color.bluePrimary,
    page,
    buttonVariant = "secondary",
    selectedButtonVariant = "primary",
    size = "medium",
    style,
    totalPage,
    testID,
    testIDPrefix,
  } = props;

  function handlePageChange(page: number) {
    if (page > 0 && page <= totalPage && typeof onPageChange === "function") {
      onPageChange(page);
    }
  }

  const items: ReactElement[] = [];
  const numberLocations = [1, page - 1, page, page + 1, totalPage];
  const breakLocations = [2, totalPage - 1];
  for (let i = 1; i <= totalPage; i++) {
    if (numberLocations.includes(i)) {
      const selected = page === i;

      items.push(
        <Button
          disabled={disabled}
          key={i}
          testID={appendTestId(testIDPrefix, i.toString())}
          aria-current={selected ? "page" : undefined}
          // accessibilityLabel={`Page ${index} is your current page`}
          onPress={() => handlePageChange(i)}
          size={size}
          style={styles[size]}
          // testID={`${testIDPrefix}-${index}`}
          text={String(i)}
          variant={selected ? selectedButtonVariant : buttonVariant}
        />
      );
    } else if (breakLocations.includes(i)) {
      items.push(
        <Text
          key={i}
          style={[styles.break, styles[size], disabled && styles.disabled]}
          testID={appendTestId(testIDPrefix, "break")}
        >
          …
        </Text>
      );
    }
  }

  const prevDisabled = page === 1;
  const nextDisabled = page === totalPage;

  const prevIcon =
    disabled || prevDisabled ? (
      <PrevIcon color={Token.color.lightSecondary} />
    ) : (
      <PrevIcon color={iconColor} />
    );
  const nextIcon =
    disabled || nextDisabled ? (
      <NextIcon color={Token.color.lightSecondary} />
    ) : (
      <NextIcon color={iconColor} />
    );

  return (
    <View align="center" row spacing="xxs" style={style} testID={testID}>
      <Button
        testID={appendTestId(testIDPrefix, "prev")}
        disabled={disabled || prevDisabled}
        onPress={() => handlePageChange(page - 1)}
        size={size}
        startIcon={prevIcon}
        style={styles[size]}
        variant="secondary"
      />
      {items}
      <Button
        testID={appendTestId(testIDPrefix, "next")}
        disabled={disabled || nextDisabled}
        onPress={() => handlePageChange(page + 1)}
        size={size}
        startIcon={nextIcon}
        style={styles[size]}
        variant="secondary"
      />
    </View>
  );
}

const padding = 2 * Token.spacing.xs;
const styles = StyleSheet.create({
  break: {
    alignItems: "center",
    borderRadius: Token.borderRadius.normal,
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: Token.spacing.s,
    paddingVertical: Token.spacing.xs,
    userSelect: "none",
    backgroundColor: Token.color.lightStain,
    color: Token.color.bluePrimary,
  },
  disabled: {
    color: Token.color.lightSecondary,
  },

  // Size
  small: {
    ...Token.typography.buttonSmall,
    minWidth: 16 + padding,
    minHeight: 16 + padding,
  },
  medium: {
    ...Token.typography.buttonMedium,
    minWidth: 24 + padding,
    minHeight: 24 + padding,
  },
  large: {
    ...Token.typography.buttonLarge,
    minWidth: 32 + padding,
    minHeight: 32 + padding,
  },
});
