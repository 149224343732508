import { forwardRef, ReactNode } from "react";
import { StyleSheet } from "react-native";

import Token from "../Token";
import View, { ViewProps, ViewRef } from "../View";

export type CardRef = ViewRef;

export type CardProps = {
  children?: ReactNode;
  /**
   * Controls the shadow of the component
   * @default 'container'
   */
  elevation?: "flat" | "container" | "raised" | "float" | "hover";
  square?: boolean;
  overflow?: "visible" | "hidden";
} & ViewProps;

export default forwardRef<CardRef, CardProps>(function Card(props, ref) {
  const {
    elevation = "container",
    style,
    square,
    overflow = "visible",
    ...rest
  } = props;

  const rootStyles = [
    styles.root,
    styles[overflow],
    styles[elevation],
    !square && styles.rounded,
    style,
  ];

  return <View {...rest} ref={ref} style={rootStyles} />;
});

const styles = StyleSheet.create({
  root: {
    backgroundColor: Token.color.lightPrimary,
  },
  visible: {
    overflow: "visible",
  },
  hidden: {
    overflow: "hidden",
  },
  rounded: {
    borderRadius: Token.borderRadius.normal,
  },

  // Elevation
  flat: {
    // @ts-expect-error boxShadow does not exist in typing
    boxShadow: Token.shadow.flat,
  },
  container: {
    // @ts-expect-error boxShadow does not exist in typing
    boxShadow: Token.shadow.container,
  },
  raised: {
    // @ts-expect-error boxShadow does not exist in typing
    boxShadow: Token.shadow.raised,
  },
  float: {
    // @ts-expect-error boxShadow does not exist in typing
    boxShadow: Token.shadow.float,
  },
  hover: {
    // @ts-expect-error boxShadow does not exist in typing
    boxShadow: Token.shadow.hover,
  },
});
