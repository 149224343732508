import { ComponentType } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import ChevronDown from "@traveloka/icon-kit-web/react/IcSystemChevronDown16";
import ChevronLeft from "@traveloka/icon-kit-web/react/IcSystemChevronLeft16";
import ChevronRight from "@traveloka/icon-kit-web/react/IcSystemChevronRight16";

import Text from "../Text";
import Token from "../Token";
import View from "../View";

export type CalendarHeaderProps = {
  nextDisabled?: boolean;
  onNextPress?(): void;
  onPrevPress?(): void;
  onDropdownPress?(): void;
  prevDisabled?: boolean;
  toggleYear?: boolean;
  showYearDropdown?: boolean;
  text: string;
};

export default function CalendarHeader(props: CalendarHeaderProps) {
  const {
    nextDisabled,
    onNextPress,
    onDropdownPress,
    onPrevPress,
    prevDisabled,
    text,
    toggleYear = false,
    showYearDropdown = false,
  } = props;

  return (
    <View align="center" row justify="between" spacing="m" style={styles.root}>
      <View align="center" spacing="xs" row>
        <Text variant="title-2" style={styles.text}>
          {text}
        </Text>
        {toggleYear && (
          <TouchableOpacity
            onPress={onDropdownPress}
            style={showYearDropdown ? styles.chevronUp : styles.chevronDown}
          >
            <ChevronDown />
          </TouchableOpacity>
        )}
      </View>
      {!showYearDropdown && (
        <View spacing="m" row>
          <IconTouchable
            disabled={prevDisabled}
            onPress={onPrevPress}
            Icon={ChevronLeft}
          />
          <IconTouchable
            disabled={nextDisabled}
            onPress={onNextPress}
            Icon={ChevronRight}
          />
        </View>
      )}
    </View>
  );
}

type IconTouchableProps = {
  disabled?: boolean;
  Icon: ComponentType<{ color?: string }>;
  onPress?(): void;
};

function IconTouchable(props: IconTouchableProps) {
  const { disabled, Icon, onPress } = props;

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={styles.button}
    >
      <Icon color={disabled ? Token.color.lightSecondary : undefined} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.s,
  },
  text: {
    marginEnd: "auto",
  },
  // improve hit box
  button: {
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  chevronDown: {
    transform: [{ rotate: "0deg" }],
    transition: `${Token.timing.instant}ms all`,
  },
  chevronUp: {
    transform: [{ rotate: "180deg" }],
    transition: `${Token.timing.instant}ms all`,
  },
});
