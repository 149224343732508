import { StyleSheet, View, ViewStyle } from "react-native";

import Token from "../../Token/Token";

export type RadioDiscProps = {
  isSelected?: boolean;
  isFocused?: boolean;
  disabled?: boolean;
  size?: "medium" | "small";
  containerStyle?: ViewStyle;
};

export default function RadioDisc({
  isSelected = false,
  isFocused = false,
  disabled = false,
  size = "medium",
  containerStyle,
}: RadioDiscProps) {
  const theme = Token;

  const sizeStyle = getSizeStyle(size);
  const backgroundColor = getBgColor();
  const outerBorderColor = getOuterBorderColor();
  const innerBorderColor = getInnerBorderColor();

  function getBgColor() {
    if (isSelected) {
      if (disabled) {
        return theme.color.lightSecondary;
      }
      return theme.color.godwitBlue;
    }
    return theme.color.lightPrimary;
  }

  function getOuterBorderColor() {
    if (isSelected) {
      if (disabled) {
        return theme.color.lightSecondary;
      }
      return theme.color.godwitBlue;
    }
    return theme.color.lightSecondary;
  }

  function getInnerBorderColor() {
    if (isSelected) {
      return theme.color.lightPrimary;
    }
    return theme.color.lightNeutral;
  }

  return (
    <View style={[styles.frame, sizeStyle.frame, containerStyle]}>
      <View
        style={[
          styles.outer,
          sizeStyle.outer,
          {
            backgroundColor,
            borderColor: outerBorderColor,
          },
        ]}
      >
        <View
          style={[
            styles.focusRing,
            sizeStyle.focusRing,
            {
              borderColor: theme.color.godwitBlue,
            },
            !isFocused && { opacity: 0 },
          ]}
        />
        <View
          style={[
            sizeStyle.inner,
            {
              borderColor: innerBorderColor,
            },
          ]}
        />
      </View>
    </View>
  );
}

function getSizeStyle(size: RadioDiscProps["size"]) {
  switch (size) {
    case "medium":
      return {
        outer: styles.disc_medium,
        inner: styles.disc_inner_medium,
        frame: styles.frame_medium,
        focusRing: styles.focusRing_medium,
      };
    case "small":
      return {
        outer: styles.disc_small,
        inner: styles.disc_inner_small,
        frame: styles.frame_small,
        focusRing: styles.focusRing_small,
      };
    default:
      throw new Error(`Unhandled size variant: ${size}`);
  }
}

const styles = StyleSheet.create({
  frame: {
    justifyContent: "center",
    alignItems: "center",
  },
  frame_medium: {
    width: 24,
    height: 24,
  },
  frame_small: {
    width: 16,
    height: 16,
  },
  outer: {
    justifyContent: "center",
    alignItems: "center",
  },
  focusRing: {
    position: "absolute",
    opacity: 0.5,
  },
  focusRing_medium: {
    width: 24,
    height: 24,
    borderRadius: 24,
    borderWidth: 2,
  },
  focusRing_small: {
    width: 18,
    height: 18,
    borderRadius: 18,
    borderWidth: 2,
  },
  disc_medium: {
    width: 22,
    height: 22,
    borderRadius: 22,
    borderWidth: 1,
  },
  disc_inner_medium: {
    width: 12,
    height: 12,
    borderRadius: 12,
    borderWidth: 2,
  },
  disc_small: {
    width: 16,
    height: 16,
    borderRadius: 16,
    borderWidth: 0.7,
  },
  disc_inner_small: {
    width: 7.5,
    height: 7.5,
    borderRadius: 7.5,
    borderWidth: 1.5,
  },
});
