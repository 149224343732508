"use client";
import { PropsWithChildren, ReactNode, useRef } from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { appendTestId } from "../../../utils";
import Dropdown, { DropdownHandler } from "../../Dropdown/Dropdown";
import FakeInput, { FakeInputProps } from "../../FakeInput";
import { ButtonComponentProps, Radio, RadioDisc } from "../../Radio/legacy";
import Text from "../../Text/Text";
import Token from "../../Token/Token";

type RadioItem = {
  value: string;
  label: ReactNode;
};

type InputDropdownRadioProps = PropsWithChildren<{
  alignment: "left" | "right";
  fadeAlignment?: "up" | "down";
  items: RadioItem[];
  // return false to prevent collapsing the dropdown
  onChange(value: string): boolean | void;
  style?: StyleProp<ViewStyle>;
  cardStyle?: StyleProp<ViewStyle>;
  title?: string;
  value: string;
}> &
  FakeInputProps;

export default function InputDropdownRadio(props: InputDropdownRadioProps) {
  const {
    children,
    alignment,
    fadeAlignment,
    title,
    items,
    onChange,
    style,
    cardStyle,
    value,
    ...inputProps
  } = props;

  const ref = useRef<DropdownHandler>(null);
  function handleChange(val: string | number) {
    const res = onChange(String(val));

    if (res !== false) {
      ref.current?.setIsCollapsed(true);
    }
  }

  return (
    <Dropdown
      ref={ref}
      alignment={alignment}
      fadeAlignment={fadeAlignment}
      style={style}
      cardStyle={cardStyle}
      trigger={<FakeInput {...inputProps} />}
    >
      {title && <Text style={Style.dropdownTitle}>{title}</Text>}
      <Radio.Group
        testID={appendTestId(props.testID, "radio-group")}
        labelID=""
        onChange={handleChange}
        style={Style.radioGroup}
        value={value}
      >
        {items.map((item, index) => (
          <Radio.Button
            testID={appendTestId(props.testID, `radio-button-${index}`)}
            key={item.value}
            value={item.value}
            label={item.label as string}
            containerStyle={Style.radio}
            ButtonComponent={FilterRadio}
          />
        ))}
      </Radio.Group>
      {children}
    </Dropdown>
  );
}

function FilterRadio(props: ButtonComponentProps) {
  const { isFocused, isSelected, label } = props;

  return (
    <>
      <RadioDisc isSelected={isSelected} isFocused={isFocused} />
      <Text variant="ui-small" style={Style.radioLabel}>
        {label}
      </Text>
    </>
  );
}

const Style = StyleSheet.create({
  dropdownTitle: {
    paddingHorizontal: Token.spacing.m,
    paddingTop: Token.spacing.s,
    paddingBottom: Token.spacing.xs,
  },
  radioGroup: {
    paddingBottom: Token.spacing.xs,
  },
  radio: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.xs,
  },
  radioLabel: {
    marginLeft: Token.spacing.m,
  },
});
