import DocumentFieldArray, {
  DocumentFieldArrayProps,
} from "./DocumentFieldArray";
import DocumentFieldSingle, { DocumentFieldProps } from "./DocumentFieldSingle";

export default function DocumentField(
  props: DocumentFieldProps | DocumentFieldArrayProps
) {
  if (!props.document.multiple) {
    return <DocumentFieldSingle {...props} />;
  }

  return <DocumentFieldArray {...props} />;
}
