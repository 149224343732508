"use client";
import { useEffect, useState } from "react";

export default function useInitialRenderState() {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  return rendered;
}
