import Link from "next/link";
import { ComponentPropsWithRef, PropsWithChildren } from "react";

import { useLocale } from "../contexts/LocaleContext";

type Props = {
  disabled?: boolean;
};

export type LocalizedLinkProps = ComponentPropsWithRef<typeof Link> & Props;

export default function LocalizedLink(
  props: PropsWithChildren<LocalizedLinkProps>
) {
  const { disabled } = props;

  const locale = useLocale();

  return (
    <Link
      {...props}
      style={{
        ...styles.link,
        ...props.style,
        ...(disabled ? styles.disabled : {}),
      }}
      href={`/${locale}/${props.href}`.replaceAll("//", "/")}
    />
  );
}

const styles = {
  link: {
    textDecoration: "none",
  },
  disabled: {
    pointerEvents: "none" as const,
  },
};
