"use client";
import { addMonths, getYear, isSameMonth, setYear } from "date-fns";
import { useState } from "react";
import { StyleSheet, View } from "react-native";

import Token from "../Token/Token";
import CalendarDate from "./CalendarDate";
import CalendarHeader from "./CalendarHeader";
import CalendarWeekday from "./CalendarWeekday";
import CalendarYear from "./CalendarYear";

export type CalendarProps = {
  locale?: string;
  minDate?: Date;
  maxDate?: Date;
  onNextPress?(): void;
  onPrevPress?(): void;
  value: Date;
  onChange?(value: Date): void;
  toggleYear?: boolean;
};

export default function Calendar(props: CalendarProps) {
  const {
    locale,
    maxDate,
    minDate,
    onChange,
    onNextPress,
    onPrevPress,
    value,
    toggleYear = false,
  } = props;

  const [currentMonth, setCurrentMonth] = useState(() => {
    return value;
  });

  const [showYearEdit, setShowYearEdit] = useState(false);

  const formatter = new Intl.DateTimeFormat(locale, {
    month: "long",
    year: "numeric",
  });

  function handleNextPress() {
    setCurrentMonth((v) => addMonths(v, 1));

    if (typeof onNextPress === "function") onNextPress();
  }

  function handlePrevPress() {
    setCurrentMonth((v) => addMonths(v, -1));

    if (typeof onPrevPress === "function") onPrevPress();
  }

  return (
    <View style={styles.container}>
      <CalendarHeader
        nextDisabled={!!maxDate && isSameMonth(currentMonth, maxDate)}
        onNextPress={handleNextPress}
        onPrevPress={handlePrevPress}
        onDropdownPress={() => setShowYearEdit((prev) => !prev)}
        prevDisabled={!!minDate && isSameMonth(currentMonth, minDate)}
        text={formatter.format(currentMonth)}
        toggleYear={toggleYear}
        showYearDropdown={showYearEdit}
      />
      {toggleYear && showYearEdit ? (
        <CalendarYear
          minYear={minDate?.getFullYear()}
          maxYear={maxDate?.getFullYear()}
          year={getYear(currentMonth)}
          onYearPress={(year) => {
            setCurrentMonth(setYear(value, year));
            setShowYearEdit(false);
          }}
        />
      ) : (
        <View>
          <CalendarWeekday />
          <CalendarDate
            maxDate={maxDate}
            minDate={minDate}
            month={currentMonth.getMonth()}
            onChange={onChange}
            value={value}
            year={currentMonth.getFullYear()}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Token.spacing.s,
    paddingVertical: Token.spacing.xxs,
    minWidth: 269,
  },
});
