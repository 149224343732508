"use client";
import { useEffect, useRef, useState } from "react";
import {
  I18nManager,
  PanResponder,
  PanResponderGestureState,
  PanResponderInstance,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";

import Knob from "../Knob";
import Token from "../Token";
import View from "../View";

export type SliderProps = {
  values: number[];
  onChangeStart?(): void;
  onChange?(values: number[]): void;
  onChangeFinish?(values: number[]): void;
  step?: number;
  min: number;
  max: number;
  disabled?: boolean;
  error?: boolean;
  // touchDimensions: {
  //   height: 50,
  //   width: 50,
  //   borderRadius: 15,
  //   slipDisplacement: 200,
  // },
  // customMarker: DefaultMarker,
  // customMarkerLeft: DefaultMarker,
  // customMarkerRight: DefaultMarker,
  // customLabel: DefaultLabel,
  // markerOffsetX: 0,
  // markerOffsetY: 0,
  // markerSize: 0,
  // sliderLength: 280,
  // onToggleOne: undefined,
  // onToggleTwo: undefined,
  // stepsAs: [],
  // showSteps: false,
  // showStepMarkers: true,
  // showStepLabels: true,
  // enabledOne: true,
  // enabledTwo: true,
  allowOverlap?: false;
  snapped?: false;
  // smoothSnapped: false;
  // vertical: false,
  // minMarkerOverlapDistance: 0,
  // minMarkerOverlapStepDistance: 0,
  // testID: '',
  style?: StyleProp<ViewStyle>;
};

const knobSize = 28;

function noop() {
  // does nothing
}

export default function Slider(props: SliderProps) {
  const {
    values,
    onChangeStart = noop,
    onChange = noop,
    onChangeFinish = noop,
    // step,
    // min,
    // max,
    disabled,
    error,
    style,
  } = props;
  const propsRef = useRef(props);

  const multiValues = values.length > 1;

  const options = useRef<number[]>([]);
  const step = useRef(0);

  const [width, setWidth] = useState(0);
  const widthRef = useRef(0);

  const pressed = useRef(false);
  // const twoPressed = useRef(false);
  const valueOne = useRef(values[0]); //[valueOne, setValueOne] = useState(values[0]);
  const valueTwo = useRef(values[1]); //[valueTwo, setValueTwo] = useState(values[1]);
  const prevPositionOne = useRef(0);
  const prevPositionTwo = useRef(0);

  const [positionOne, setPositionOne] = useState(0);
  const [positionTwo, setPositionTwo] = useState(0);

  useEffect(() => {
    if (pressed.current) return;

    const prev = propsRef.current;

    if (
      widthRef.current !== width ||
      prev.min !== props.min ||
      prev.max !== props.max ||
      prev.step !== props.step ||
      prev.values[0] !== props.values[0] ||
      prev.values[1] !== props.values[1]
    ) {
      step.current = createStepLength(props.min, props.max, props.step);
      options.current = createOptions(props.min, props.max, props.step ?? 1);

      valueOne.current = clamp(props.min, props.max, props.values[0]);
      prevPositionOne.current = valueToPosition(
        props.values[0],
        options.current,
        width
      );
      setPositionOne(prevPositionOne.current);

      if (props.values[1] !== undefined) {
        valueTwo.current = clamp(props.min, props.max, props.values[1]);
        prevPositionTwo.current = valueToPosition(
          props.values[1],
          options.current,
          width
        );
        setPositionTwo(prevPositionTwo.current);
      }
    }

    propsRef.current = props;
    widthRef.current = width;
  }, [props, width]);

  function onStart() {
    if (!disabled) {
      onChangeStart();
      pressed.current = true;
    }
  }

  function onEnd() {
    pressed.current = false;

    // prevPositionOne.current = valueToPosition(
    //   valueOne.current,
    //   options.current,
    //   widthRef.current
    // );
    // prevPositionTwo.current = valueToPosition(
    //   valueTwo.current,
    //   options.current,
    //   widthRef.current
    // );

    const values: number[] = [valueOne.current];
    if (multiValues) values.push(valueTwo.current);

    onChangeFinish(values);
  }

  function moveOne(state: PanResponderGestureState) {
    if (disabled) return;

    // const unconfined = I18nManager.isRTL
    //   ? prevPositionOne.current - state.dx
    //   : prevPositionOne.current + state.dx;

    const trackLength = multiValues
      ? widthRef.current - knobSize
      : widthRef.current;

    const stepWidth = trackLength / step.current;

    const dStep = Math.round(state.dx / stepWidth);
    // const position = ;
    setPositionOne(
      clamp(0, trackLength, prevPositionOne.current + dStep * stepWidth)
    );

    const value = clamp(
      props.min,
      props.max,
      valueOne.current + dStep * (props.step ?? 1)
    );

    if (!pressed.current) {
      valueOne.current = value;
    }

    if (value !== valueOne.current) {
      const values: number[] = [value];
      if (multiValues) values.push(valueTwo.current);

      console.log(values);

      onChange(values);
    }

    // console.log('prevPositionTwo.current', prevPositionTwo.current);
    //     const bottom = 0;
    //     const trueTop = prevPositionTwo.current - knobSize;
    //     const top = trueTop || widthRef.current;

    //     const value = positionToValue(
    //       clamp(bottom, top, unconfined),
    //       options.current,
    //       trueTop
    //     );
    //     setPositionOne(valueToPosition(value, options.current, trueTop));

    //     if (value !== valueOne.current) {
    //       valueOne.current = value;

    //       const changed: number[] = [value];
    //       if (multiValues) changed.push(valueTwo.current);

    //       onChange(changed);
    //     }
  }

  function moveTwo(state: PanResponderGestureState) {
    if (disabled) return;

    const unconfined = I18nManager.isRTL
      ? prevPositionTwo.current - state.dx
      : prevPositionTwo.current + state.dx;

    // const bottom = prevPositionOne.current + knobSize;
    // const trueTop = prevPositionOne.current + knobSize;
    const top = widthRef.current;

    const value = positionToValue(
      clamp(0, top, unconfined),
      options.current,
      widthRef.current
    );

    console.log(value);

    setPositionTwo(valueToPosition(value, options.current, widthRef.current));

    // if (value !== valueTwo.current) {
    //   valueTwo.current = value;

    //   onChange([valueOne.current, value]);
    // }
  }

  const panResponderOne = useResponder({ onStart, onMove: moveOne, onEnd });
  const panResponderTwo = useResponder({ onStart, onMove: moveTwo, onEnd });

  const highlightStyles: StyleProp<ViewStyle>[] = [
    styles.highlight,
    disabled && styles.highlightDisabled,
    error && styles.highlightError,
  ];
  if (multiValues) {
    highlightStyles.push({
      left: positionOne,
      width: positionTwo - positionOne,
    });
  } else {
    highlightStyles.push({
      left: 0,
      width: positionOne,
    });
  }

  return (
    <View style={[styles.root, style]}>
      <View
        style={styles.track}
        onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
      >
        <View style={highlightStyles} />
      </View>

      <View {...panResponderOne} style={[styles.knob, { left: positionOne }]}>
        <Knob disabled={disabled} />
      </View>

      {multiValues && (
        <View {...panResponderTwo} style={[styles.knob, { left: positionTwo }]}>
          <Knob disabled={disabled} />
        </View>
      )}
    </View>
  );
}

type ResponderConfig = {
  onStart(): void;
  onMove(gestureState: PanResponderGestureState): void;
  onEnd(): void;
};

function useResponder(config: ResponderConfig) {
  const ref = useRef<PanResponderInstance>();
  if (!ref.current) {
    ref.current = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => false,
      onPanResponderGrant: () => config.onStart(),
      onPanResponderMove: (_evt, gestureState) => config.onMove(gestureState),
      onPanResponderRelease: () => config.onEnd(),
      onPanResponderTerminationRequest: () => false,
      onPanResponderTerminate: () => config.onEnd(),
    });
  }

  return ref.current.panHandlers;
}

const styles = StyleSheet.create({
  root: {
    height: knobSize,
    justifyContent: "center",
    paddingVertical: Token.spacing.s,
    paddingHorizontal: knobSize / 2,
  },

  track: {
    overflow: "hidden",
    backgroundColor: Token.color.lightNeutral,
    borderRadius: Token.borderRadius.rounded,
    height: 4,
  },
  highlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    backgroundColor: Token.color.bluePrimary,
    transitionDuration: `${Token.timing.instant}ms`,
    transitionProperty: "background-color",
  },
  highlightDisabled: {
    backgroundColor: "transparent",
  },
  highlightError: {
    backgroundColor: Token.color.redPrimary,
  },
  knob: {
    position: "absolute",
    width: knobSize,
    height: knobSize,
    top: 0,
  },
});

function clamp(min: number, max: number, value: number) {
  return Math.min(Math.max(value, min), max);
}

// Find closest index for a given value in sorted array
function closest(array: number[], n: number) {
  let minI = 0;
  let maxI = array.length - 1;

  if (array[minI] > n) {
    return minI;
  } else if (array[maxI] < n) {
    return maxI;
  } else if (array[minI] <= n && n <= array[maxI]) {
    let closestIndex = null;

    while (closestIndex === null) {
      const midI = Math.round((minI + maxI) / 2);
      const midVal = array[midI];

      if (midVal === n) {
        closestIndex = midI;
      } else if (maxI === minI + 1) {
        const minValue = array[minI];
        const maxValue = array[maxI];
        const deltaMin = Math.abs(minValue - n);
        const deltaMax = Math.abs(maxValue - n);

        closestIndex = deltaMax <= deltaMin ? maxI : minI;
      } else if (midVal < n) {
        minI = midI;
      } else if (midVal > n) {
        maxI = midI;
      } else {
        closestIndex = -1;
      }
    }

    return closestIndex;
  }

  return -1;
}

// function usePositionState(value: number) {
//   const [value, setValue] = useState(value);
//   const [position, setPosition] = useState(0);
//   const prevPosition = useRef(0);

//   useState
// }

function valueToPosition(value: number, options: number[], width: number) {
  const index = closest(options, value);
  const arrLength = options.length - 1;
  return (width * index) / arrLength;
}

function positionToValue(position: number, options: number[], width: number) {
  const arrLength = options.length - 1;
  const offset = position / width;
  const index = (arrLength * (position + offset)) / width;

  return options[Math.round(index)];
}

function createOptions(min: number, max: number, step: number) {
  const direction = min - max > 0 ? -1 : 1;
  const result: number[] = [];

  const length = Math.abs((min - max) / step) + 1;
  for (let i = 0; i < length; i++) {
    result.push(min + i * Math.abs(step) * direction);
  }
  return result;
}

function createStepLength(min: number, max: number, step = 1) {
  return Math.abs((min - max) / step);
}
