import { forwardRef, ReactNode } from "react";

import { ViewSeo, ViewSeoProps, ViewSeoRef } from "../View";

import styles from "./Card.module.css";

export type CardSeoRef = ViewSeoRef;

export type CardSeoProps = {
  children?: ReactNode;
  /**
   * Controls the shadow of the component
   * @default 'container'
   */
  elevation?: "flat" | "container" | "raised" | "float" | "hover";
  square?: boolean;
  overflow?: "visible" | "hidden";
} & ViewSeoProps;

export default forwardRef<CardSeoRef, CardSeoProps>(function CardSeo(
  props,
  ref
) {
  const {
    elevation = "container",
    square,
    className,
    overflow = "visible",
    ...rest
  } = props;

  const rootClassNames = [
    styles.root,
    styles[overflow],
    styles[elevation],
    !square && styles.rounded,
    className,
  ].join(" ");

  return <ViewSeo {...rest} ref={ref} className={rootClassNames} />;
});
