import { cloneElement, forwardRef, ReactElement } from "react";
import {
  AccessibilityRole,
  Pressable,
  View as RNView,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from "react-native";

import DotLoader from "../DotLoader";
import Text, { TextProps } from "../Text";
import Token from "../Token";
import View from "../View";

type Variant =
  | "primary"
  | "secondary"
  | "main-cta"
  | "destructive-primary"
  | "destructive-secondary"
  | "outline-primary"
  | "outline-black"
  | "outline-white"
  | "floating-primary"
  | "floating-secondary"
  | "floating-main-cta"
  | "floating-outline-black"
  | "floating-outline-white"
  | "text-primary"
  | "text-destructive"
  | "text-black"
  | "text-white";

type Size = "small" | "medium" | "large";

export type ButtonProps = {
  /**
   * Overrides the text that's read by the screen reader when the user
   * interacts with the element
   */
  accessibilityLabel?: string;
  accessibilityRole?: AccessibilityRole;
  /**
   * Styling for the button
   */
  buttonStyle?: StyleProp<ViewStyle>;
  /**
   * Controls whether the button is disabled or not
   * @default false
   */
  disabled?: boolean;
  /**
   * Element placed after the children.
   */
  endIcon?: ReactElement;
  /**
   * Display DotLoader as replacement of the content
   * @default false
   */
  loading?: boolean;
  /**
   * Called when the button is pressed
   */
  onPress?(): void;
  /**
   * Called after the button is pressed (mouse up)
   */
  onBlur?(): void;
  /**
   * Defines size of the button
   * @default 'medium'
   */
  size?: Size;
  /**
   * Element placed before the children.
   */
  startIcon?: ReactElement;
  /**
   * Custom style applied to the root element
   */
  style?: StyleProp<ViewStyle>;
  /**
   * The content of the button
   */
  text?: string;
  /**
   * Custom text ink color
   */
  textInk?: {
    normal: TextProps["ink"];
    disabled: TextProps["ink"];
    hovered: TextProps["ink"];
  };
  /**
   * Defines variant of the button
   * @default 'primary'
   */
  variant?: Variant;
  /**
   * Used to locate this view in end-to-end tests.
   */
  testID?: string;
};

const iconSizeMap = {
  small: 12,
  medium: 16,
  large: 24,
};

export type ButtonRef = RNView;

type PressableState = {
  readonly hovered: boolean;
  readonly focused: boolean;
  readonly pressed: boolean;
};

export default forwardRef<ButtonRef, ButtonProps>(function Button(props, ref) {
  const {
    accessibilityLabel,
    accessibilityRole = "button",
    buttonStyle,
    disabled = false,
    endIcon,
    loading = false,
    onPress,
    onBlur,
    size = "medium",
    startIcon,
    style,
    testID,
    text,
    textInk: textInkProps,
    variant = "primary",
    ...rest
  } = props;

  function handlePress() {
    if (typeof onPress === "function") onPress();
  }

  return (
    <Pressable
      {...rest}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={accessibilityRole}
      disabled={loading || disabled}
      onPress={handlePress}
      onBlur={onBlur}
      ref={ref}
      testID={testID}
      // @ts-expect-error hovered and focused state does not exist in typing
      style={(state: PressableState) => [
        containerStyles.root,
        containerStyles[variant],
        state.hovered && hoveredContainerStyles[variant],
        (loading || disabled) && disabledContainerStyles[variant],
        style,
      ]}
    >
      {/* @ts-expect-error hovered and focused state does not exist in typing */}
      {(state: PressableState) => {
        let textInk = textInkMap[variant];
        if (textInkProps) {
          textInk = textInkProps.normal;
          if (disabled) {
            textInk = textInkProps.disabled;
          } else if (state.hovered) {
            textInk = textInkProps.hovered;
          }
        } else {
          if (disabled) {
            textInk = disabledTextInkMap[variant];
          } else if (state.hovered) {
            textInk = hoveredTextInkMap[variant];
          }
        }

        const innerStyle: StyleProp<ViewStyle> = [
          styles.inner,
          state.pressed && styles.innerFade,
          loading && styles.hidden,
          buttonStyle,
        ];

        return (
          <>
            <View align="center" row spacing="xxs" style={innerStyle}>
              {resizeIcon(startIcon, size)}
              {typeof text === "string" && (
                <Text style={[styles.buttonText, styles[size]]} ink={textInk}>
                  {text}
                </Text>
              )}
              {resizeIcon(endIcon, size)}
            </View>
            {loading && (
              <DotLoader
                color={loadingColorMap[variant] ?? Token.color.darkSecondary}
                style={styles.loading}
              />
            )}
          </>
        );
      }}
    </Pressable>
  );
});

function resizeIcon(icon: ReactElement | undefined, size: Size) {
  if (!icon) return null;

  const iconSize = iconSizeMap[size];
  return cloneElement(icon, { height: iconSize, width: iconSize });
}

const textButton = {
  paddingVertical: Token.spacing.xs,
};
const outlineButton = {
  borderRadius: Token.borderRadius.normal,
  borderWidth: Token.borderWidth.thick,
  paddingHorizontal: Token.spacing.s - 1,
  paddingVertical: Token.spacing.xs - 1,
};
const floatingButton = {
  borderRadius: Token.borderRadius.rounded,
  boxShadow: Token.shadow.float,
};
const regularButton = {
  borderRadius: Token.borderRadius.normal,
  paddingHorizontal: Token.spacing.s,
  paddingVertical: Token.spacing.xs,
};

const styles = StyleSheet.create({
  inner: {
    opacity: 1,
    transitionProperty: "opacity",
  },
  innerFade: {
    opacity: Token.opacity.translucent(),
  },
  hidden: {
    visibility: "hidden",
  } as ViewStyle,
  buttonText: {
    textAlign: "center",
  },
  loading: {
    position: "absolute",
    zIndex: 1,
  },

  // Size
  small: Token.typography.buttonSmall,
  medium: Token.typography.buttonMedium,
  large: Token.typography.buttonLarge,
});

const containerStyles = StyleSheet.create({
  root: {
    alignItems: "center",
    justifyContent: "center",
    outlineWidth: 0,
    userSelect: "none",
  },

  primary: {
    ...regularButton,
    backgroundColor: Token.color.bluePrimary,
  },
  secondary: {
    ...regularButton,
    backgroundColor: Token.color.lightStain,
  },
  "main-cta": {
    ...regularButton,
    backgroundColor: Token.color.orangePrimary,
  },
  "destructive-primary": {
    ...regularButton,
    backgroundColor: Token.color.redPrimary,
  },
  "destructive-secondary": {
    ...regularButton,
    backgroundColor: Token.color.lightStain,
  },
  "outline-primary": {
    ...outlineButton,
    borderColor: Token.color.bluePrimary,
  },
  "outline-black": {
    ...outlineButton,
    borderColor: Token.color.darkNeutral,
  },
  "outline-white": {
    ...outlineButton,
    borderColor: Token.color.lightNeutral,
  },
  "floating-primary": {
    ...regularButton,
    ...floatingButton,
    backgroundColor: Token.color.bluePrimary,
  },
  "floating-secondary": {
    ...regularButton,
    ...floatingButton,
    backgroundColor: Token.color.lightStain,
  },
  "floating-main-cta": {
    ...regularButton,
    ...floatingButton,
    backgroundColor: Token.color.orangePrimary,
  },
  "floating-outline-black": {
    ...outlineButton,
    ...floatingButton,
    borderColor: Token.color.darkNeutral,
  },
  "floating-outline-white": {
    ...outlineButton,
    ...floatingButton,
    borderColor: Token.color.lightNeutral,
  },
  "text-primary": textButton,
  "text-destructive": textButton,
  "text-black": textButton,
  "text-white": textButton,
});

const hoveredContainerStyles = StyleSheet.create({
  primary: { backgroundColor: Token.color.blueSecondary },
  secondary: { backgroundColor: Token.color.lightNeutral },
  "main-cta": { backgroundColor: Token.color.orangeSecondary },
  "destructive-primary": { backgroundColor: Token.color.redSecondary },
  "destructive-secondary": { backgroundColor: Token.color.lightNeutral },
  "outline-primary": { borderColor: Token.color.bluePrimary },
  "outline-black": { borderColor: Token.color.darkPrimary },
  "outline-white": { borderColor: Token.color.lightPrimary },
  "floating-primary": { backgroundColor: Token.color.blueSecondary },
  "floating-secondary": { backgroundColor: Token.color.lightNeutral },
  "floating-main-cta": { backgroundColor: Token.color.orangeSecondary },
  "floating-outline-black": { borderColor: Token.color.darkPrimary },
  "floating-outline-white": { borderColor: Token.color.lightPrimary },
  "text-primary": {},
  "text-destructive": {},
  "text-black": {},
  "text-white": {},
});

const disabledContainerStyles = StyleSheet.create({
  primary: { backgroundColor: Token.color.lightNeutral },
  secondary: { backgroundColor: Token.color.lightNeutral },
  "main-cta": { backgroundColor: Token.color.lightNeutral },
  "destructive-primary": { backgroundColor: Token.color.lightNeutral },
  "destructive-secondary": { backgroundColor: Token.color.lightNeutral },
  "outline-primary": { borderColor: Token.color.lightSecondary },
  "outline-black": { borderColor: Token.color.lightSecondary },
  "outline-white": { borderColor: Token.color.lightSecondary },
  "floating-primary": { backgroundColor: Token.color.lightNeutral },
  "floating-secondary": { backgroundColor: Token.color.lightNeutral },
  "floating-main-cta": { backgroundColor: Token.color.lightNeutral },
  "floating-outline-black": { borderColor: Token.color.lightSecondary },
  "floating-outline-white": { borderColor: Token.color.lightSecondary },
  "text-primary": {},
  "text-destructive": {},
  "text-black": {},
  "text-white": {},
});

const loadingColorMap: { [variant in Variant]?: string } = {
  "outline-white": Token.color.lightSecondary,
};

const textInkMap: Record<Variant, TextProps["ink"]> = {
  primary: "white-primary",
  secondary: "interactive",
  "main-cta": "white-primary",
  "destructive-primary": "white-primary",
  "destructive-secondary": "destructive",
  "outline-primary": "interactive",
  "outline-black": "black-neutral",
  "outline-white": "white-neutral",
  "floating-primary": "white-primary",
  "floating-secondary": "interactive",
  "floating-main-cta": "white-primary",
  "floating-outline-black": "black-neutral",
  "floating-outline-white": "white-neutral",
  "text-primary": "interactive",
  "text-destructive": "destructive",
  "text-black": "black-neutral",
  "text-white": "white-neutral",
};

const hoveredTextInkMap: Record<Variant, TextProps["ink"]> = {
  primary: "white-primary",
  secondary: "interactive",
  "main-cta": "white-primary",
  "destructive-primary": "white-primary",
  "destructive-secondary": "destructive",
  "outline-primary": "highlight",
  "outline-black": "black-neutral",
  "outline-white": "white-neutral",
  "floating-primary": "white-primary",
  "floating-secondary": "interactive",
  "floating-main-cta": "white-primary",
  "floating-outline-black": "black-neutral",
  "floating-outline-white": "white-neutral",
  "text-primary": "highlight",
  "text-destructive": "alert",
  "text-black": "black-primary",
  "text-white": "white-primary",
};

const disabledTextInkMap: Record<Variant, TextProps["ink"]> = {
  primary: "black-muted",
  secondary: "black-muted",
  "main-cta": "black-muted",
  "destructive-primary": "black-muted",
  "destructive-secondary": "black-muted",
  "outline-primary": "black-muted",
  "outline-black": "black-muted",
  "outline-white": "black-secondary",
  "floating-primary": "black-muted",
  "floating-secondary": "black-muted",
  "floating-main-cta": "black-muted",
  "floating-outline-black": "black-muted",
  "floating-outline-white": "black-secondary",
  "text-primary": "black-muted",
  "text-destructive": "black-muted",
  "text-black": "black-muted",
  "text-white": "black-secondary",
};
