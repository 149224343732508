export type JsonValue = string | number | object | [] | boolean | null;

type Cached<T> = T extends Date
  ? string
  : T extends Function
  ? never
  : T extends Object | any[]
  ? { [P in keyof T]: Cached<T[P]> }
  : T;

export function getRaw(key: string): string | null {
  return window.localStorage.getItem(key);
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint -- legacy code: don't want to change the logic/typing
export function get<T extends any>(key: string): Nullable<Cached<T>> {
  try {
    const raw = window.localStorage.getItem(key);

    if (raw !== null) {
      try {
        return JSON.parse(raw) as Cached<T>;
      } catch (e) {
        if (raw.startsWith("()") || raw.startsWith("function")) {
          throw new SyntaxError("Function is not allowed");
        }
        return raw as Cached<T>;
      }
    }

    return null;
  } catch (e) {
    if (e instanceof SyntaxError) {
      remove(key);
    }

    return null;
  }
}

export function set(key: string, data: JsonValue) {
  if (data !== null && typeof data === "object") {
    window.localStorage.setItem(key, JSON.stringify(data));
  } else {
    window.localStorage.setItem(key, String(data));
  }
}

export function remove(key: string) {
  window.localStorage.removeItem(key);
}

const storage = { getRaw, get, set, remove };

export default storage;
