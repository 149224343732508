"use client";
import { NavigateOptions } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter } from "next/navigation";
import { useMemo } from "react";

import { useLocale } from "../contexts/LocaleContext";

export default function useLocalizedRouterServer() {
  const locale = useLocale();
  const router = useRouter();

  return useMemo(
    () => ({
      push(path: string, options?: NavigateOptions) {
        router.push(`/${locale}/${path}`.replaceAll("//", "/"), options);
      },
      replace(path: string, options?: NavigateOptions) {
        router.replace(`/${locale}/${path}`.replaceAll("//", "/"), options);
      },
    }),
    [locale, router]
  );
}
