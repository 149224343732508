import { StyleProp, TextStyle } from "react-native";
import Text, { TextProps } from "../../Text";
import { InputProps } from "./Input";

export type InputLabelProps = {
  disabled?: boolean;
  inputId?: string;
  labelVariant?: TextProps["variant"];
  required?: boolean;
  size?: InputProps["size"];
  text: string;
  style?: StyleProp<TextStyle>;
};

const variantMap: Record<string, TextProps["variant"]> = {
  small: "ui-tiny",
  medium: "ui-small",
};

export default function InputLabel(props: InputLabelProps) {
  const {
    disabled,
    inputId,
    labelVariant,
    required,
    size = "medium",
    style,
    text,
  } = props;

  return (
    <Text
      // @ts-expect-error label does not exist in typing
      accessibilityRole="label"
      ellipsizeMode="tail"
      ink={disabled ? "black-muted" : "black-secondary"}
      numberOfLines={2}
      variant={labelVariant ? labelVariant : variantMap[size]}
      htmlFor={inputId}
      style={style}
    >
      {text}
      {required && (
        <Text aria-hidden ink="alert" variant={variantMap[size]}>
          &thinsp;*
        </Text>
      )}
    </Text>
  );
}
