import { CSSProperties, ReactNode, useMemo, useState } from "react";
import { Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

import useHover from "../../hooks/useHover";
import Token from "../../Token/Token";

type Props = {
  children: ReactNode | ((isHovered: boolean) => ReactNode);
  style?: StyleProp<ViewStyle>;
  hoverStyle?: StyleProp<ViewStyle>;
  pressedStyle?: StyleProp<ViewStyle>;
  columnLengths: (string | number)[];
  disableHover?: boolean;
  hasAction?: boolean;
  onPress?(): void;
};

export default function PaginationTableRow(props: Props) {
  const {
    children,
    disableHover = false,
    columnLengths,
    hasAction,
    onPress,
    style,
    hoverStyle,
    pressedStyle,
  } = props;
  const [hover, handlers] = useHover();
  const [isPressed, setIsPressed] = useState(false);

  const gridStyles = useMemo<CSSProperties>(() => {
    const templateColumns = columnLengths
      .map((val) => {
        const parsedVal = typeof val === "number" ? `${val}px` : val;
        return !val ? "1fr" : parsedVal;
      })
      .join(" ");

    return {
      display: "grid",
      gridTemplateColumns: `${templateColumns} ${hasAction ? "64px" : ""}`,
    };
  }, [columnLengths, hasAction]);

  return (
    <Pressable
      activeOpacity={0.5}
      disabled={disableHover}
      onPress={onPress}
      style={[
        gridStyles as StyleProp<ViewStyle>,
        styles.rows,
        style,
        !disableHover && hover && (hoverStyle ?? styles.rowHover),
        isPressed && pressedStyle,
      ]}
      {...handlers}
      // @ts-expect-error -- onMouseDown is not available in react-native
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
    >
      {typeof children === "function" ? children(hover) : children}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  rows: {
    paddingHorizontal: Token.spacing.m,
    paddingVertical: Token.spacing.s,
    backgroundColor: Token.color.lightPrimary,
    columnGap: Token.spacing.l,
    // @ts-ignore
    transition: `${Token.timing.instant}ms all !important`,
  },
  rowHover: {
    zIndex: 2,
    // @ts-ignore
    boxShadow: Token.shadow.raised,
    cursor: "pointer",
  },
  rowsEven: {
    backgroundColor: Token.color.lightStain,
  },
});
