import { Children, ReactNode } from "react";
import { StyleSheet, View, ViewProps } from "react-native";

import { intersperse, isReactText } from "../../utils";
import Text from "../Text";
import Token from "../Token";

export type BreadcrumbProps = {
  children?: ReactNode;
  /**
   * Custom separator node
   * @default '/'
   */
  separator?: ReactNode;
} & ViewProps;

export default function Breadcrumb(props: BreadcrumbProps) {
  const { separator = "/", style, ...rest } = props;

  const children = intersperse(Children.toArray(props.children), (index) => {
    if (isReactText(separator)) {
      return (
        <Text
          key={"s." + index}
          aria-hidden
          ink="black-neutral"
          style={styles.separator}
        >
          {separator}
        </Text>
      );
    }

    return (
      <View key={"s." + index} aria-hidden style={styles.separator}>
        {separator}
      </View>
    );
  });

  return (
    <View
      // @ts-expect-error navigation does not exist in typing
      accessibilityRole="navigation"
      {...rest}
      style={[styles.root, style]}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    alignItems: "center",
  },
  separator: {
    marginHorizontal: Token.spacing.s,
    userSelect: "none",
  },
});
